.main-contacts {
	margin-top: 100px;
	@include medium {
		margin-bottom: 90px; }
	@include small {
		margin-bottom: 80px; }
	@include phone {
		margin-bottom: 60px; } }
.main__map.active {
	margin-bottom: 260px;
	box-shadow: 2px 8px 18px 6px rgba(0, 0, 0, 0.21);
	@include medium {
		margin-bottom: 200px; }
	@include small {
		margin-bottom: 100px; }
	@include phone {
		margin-bottom: 80px; } }
// 	height: 30vw
