.price {
	@include phone {
 }		// overflow: hidden
	.content {
		padding-top: 0 !important; }

	.flex-row {
		@include phone {
			flex-direction: column; } }


	.flex-row.top {
		align-items: flex-start;
		@include small {
			flex-wrap: wrap; }
		.header-caption, .garant-caption {
			opacity: 0.8;
			color: $color__4;
			font-size: 16px;
			font-weight: 300;
			line-height: 30px;
			text-transform: uppercase;
			letter-spacing: 4px;
			margin-top: $header_decor-margin;
			@include medium {
				margin-top: $header_decor-margin-medium; }

			@include max(#{$firstbreckpoint}) {
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 3.5px; }
			@include small {
				font-size: 12px;
				line-height: 24px;
				text-transform: uppercase;
				letter-spacing: 3px;
				margin-top: $header_decor-margin-small; }
			@include phone {
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 3.01px;
				margin-top: $header_decor-margin-phone; } }

		.header_1 {
			@include small {
				margin-bottom: 0; }
			@include phone {
				padding-left: #{$percentphoneVector / 2}vw; } }
		.header-caption {
			div {
				width: #{$percentVector * 1.5}vw; }
			@include max($secondbreckpoint) {
				div {
					width: 100%;
					padding-right: 20px; } }

			@include small {
				width: #{$percentsmallVector * 3}vw;
				div {
					width: unset; } }
			@include phone {
				margin-left: #{$percentphoneVector / 2}vw;
				margin-bottom: 45px;
				width: unset;
				margin-top: 40px; } }
		.garant-caption {
			&:after {
				height: 90%;
				top: 5%; }
			@include small {
				width: #{$percentsmallVector * 2}vw;
				margin-left: #{$percentsmallVector * 3}vw;
				margin-bottom: 55px;
				margin-top: 0 !important; }
			@include phone {
				margin-bottom: 50px;
				width: #{$percentphoneVector * 2}vw;
				margin-left: #{$percentphoneVector}vw; } } }

	.button-wrapper {
		&:after {
 }			// background-image: linear-gradient(-216deg, rgba(7, 26, 30, 0.09) 0%, rgba(100, 240, 254, 0.23) 50%, rgba(13, 35, 39, 0) 100%)

		@include phone {
			align-self: flex-end; } }


	.right {
		>.text-default {
			@include offset-color(yellow); }
		.flex-row {
			justify-content: space-between;
			.text-default {
				margin-bottom: 0;
				@include max($secondbreckpoint) {
					width: #{$percentsecondVector * 1.5}vw; }
				@include small {
					width: #{$percentsmallVector * 1.5}vw; }

				@include phone {
					margin-bottom: 10px;
					width: #{$percentphoneVector * 2.5}vw; } } } }
	.image-wrapper {
		position: relative;
		box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
		width: #{$percentVector * 2.5}vw;
		@include max(#{$firstbreckpoint}) {
			width: #{$percent1360Vector * 2.5}vw; }
		@include max($secondbreckpoint) {
			width: #{$percentsecondVector * 2.5}vw; }
		@include small {
			width: #{$percentsmallVector * 2.5}vw; }
		@include phone {
			width: 100%;
			margin-bottom: 48px; }

		img {
			width: 100%; } }


	.svg-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.4s;
		z-index: 5;
		animation: opacity 4s linear infinite; }

	.payment {
		opacity: 0.8;
		color: $color__4;
		font-size: 25px;
		font-weight: 300;
		line-height: 29.91px;
		text-transform: uppercase;
		letter-spacing: 6.27px;
		margin-bottom: 90px;
		.number {
			font-size: 50px;
			font-weight: 400;
			line-height: 29.91px;
			text-transform: uppercase;
			letter-spacing: 5.02px; }

		@include max($secondbreckpoint) {
			font-size: 24px;
			line-height: 29.91px;
			letter-spacing: 6.02px;
			margin-bottom: 65px;
			.number {
				font-size: 40px;
				line-height: 75px;
				letter-spacing: 4.01px; } }
		@include small {
			line-height: 29.91px;
			letter-spacing: 4.01px;
			margin-bottom: 60px;
			.number {
				font-size: 30px;
				line-height: 55px;
				letter-spacing: 3.01px; } }
		@include phone {
			margin-bottom: 40px;
			font-size: 16px;
			line-height: 29.91px;
			line-height: 29.91px;

			.number {
				font-size: 35px;
				font-weight: 400;
				line-height: 29.91px;
				letter-spacing: 3.51px; } } } }
