.portfolio {
	border-top: 1px solid transparent;
	.content {
		padding-top: 0 !important; }
	.flex-row {
		justify-content: space-between; }

	.see-all {
		margin-top: 80px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		a {
			opacity: 0.8;
			color: #d4eaf7;
			font-size: 16px;
			font-weight: 300;
			line-height: 30px;
			text-transform: uppercase;
			letter-spacing: 4px;
			text-decoration: none;
			display: flex;
			align-items: center;
			&:hover {
				opacity: 0.4; }
			span {
				display: flex;
				width: 100%;
				width: 49px;
				height: 9px;
				opacity: 0.4;
				margin-left: 10px;
				svg {
					fill: #d4eaf7; } } } }

	.portfolio__list {
		padding-left: 0;
		margin: 0;
		list-style: none;
		padding-top:  80px;

		.portfolio-gallery-wrapper {
			font-size: 0;
			box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
			width: 100%;
			width: #{$percentVector * 3.5}vw;
			flex-shrink: 0;
			@include max(#{$firstbreckpoint}) {
				width: #{$percent1360Vector * 3.5}vw; }
			@include max($secondbreckpoint) {
				width: #{$percentsecondVector * 3.5}vw; }
			@include small {
				width: #{$percentsmallVector * 3.5}vw; }
			@include phone {
				width: 100%; } }
		.slick-slide {
			img {
				width: 100%; } }


		.portfolio__item {
			margin-bottom: 240px;
			&:last-child {
				margin-bottom: 0; }
			.bottom.arrow-wrapper {
				transform: translateX(-16px); }
			@include phone {
				margin-bottom: 170px;
				position: relative;
				padding-bottom: 100px;
				.bottom.arrow-wrapper {
					position: absolute;
					width: 100%;
					bottom: 0;
					left: 0; }

				>.flex-row {
					flex-direction: column;
					.portfolio-description {
						padding-left: #{$percentphoneVector}vw;
						width: 100%;
						order: -1;
						margin-bottom: 42px; } }


				&:nth-child(2n) {
					.bottom.arrow-wrapper {
						transform: translateX(0); }
					>.flex-row {
						.portfolio-description {
							padding-right: #{$percentphoneVector}vw;
							padding-left: 0; } } } } }



		.portfolio__item:nth-child(2n) {
			.bottom.arrow-wrapper {
				transform: translateX(16px); }
			>.flex-row {
				.portfolio-description {
					text-align: right;
					order: -1; } }
			.bottom.arrow-wrapper {
				justify-content: flex-end; }
			.caption-left-line {
				padding-right: 20px;
				&:after {
					right: 0;
					left: unset; } } }

		.caption {
			@include offset-color(blue); }
		.portfolio-description {
			display: flex;
			flex-direction: column;
			.text-default {
				// opacity: 0.4
				margin-bottom: 40px; }
			.arrow-wrapper {
				margin-top: auto; } } } }

