@mixin form-desc_2 {
	$global__bg: none;
	$global__box-shadow: none;

	$container__bg: none;
	$container__box-shadow: none;

	$content__padding-top: 110;							//	верхний и нижний отступ контента
	$content__padding-bottom: 120;						//	указывать без 'px'

	$blocks-width: 400px;								//	ширина блоков
	$blocks__align-vetical: center;						//	вертикальное выравнивание блоков (top | center)
	$blocks__align-horizontal: left; 					//	горизонтальное выравнивание текста в блоках (left | center)

	//	настройки для блока с текстом
	$text__header_margin-bottom: none;					//	если под заголовком нет текста - указать 'none'
	$text__text-color: default;							//	цвет текста в текстовом блоке, 'default' - цвет обычного текста

	//	настройки для блока с формой
	$form__header_margin-bottom: 40px;					//	отступ от заголовка формы до первого инпута
	$form__inputs-space-between: 30px;					//	расстояние между инпутами, без учета подписей к ним
	$form__button_margin-top: 30px;						//	отступ от чекбокса до кнопки


	.form-desc {
		@if $global__bg != none {
			background: $global__bg; }
		@if $global__box-shadow != none {
			box-shadow: $global__box-shadow; }
		.container {
			@if $container__bg != none {
				background: $container__bg; }
			@if $container__box-shadow != none {
				box-shadow: $container__box-shadow; } }
		.content {
			padding-top: 0 !important; }
		.flex-row {
			@include phone {
				flex-direction: column; } }
		.description {
			padding-top: 10px;
			margin-top: $header_decor-margin;
			@include medium {
				margin-top: $header_decor-margin-medium; }
			@include small {
				margin-top: $header_decor-margin-small; }
			@include phone {
				margin-top: $header_decor-margin-phone; }
			>.caption {
				@include offset-color(blue);
				@include phone {
					letter-spacing: 2px;
					padding-left: #{$percentphoneVector / 2}vw; } }
			>.text-default {
				@include offset-color(yellow);
				@include phone {
					padding-left: #{$percentphoneVector / 2}vw; } }
			.flex-row {
				justify-content: space-between;
				align-items: flex-start;
				@include small {
					flex-direction: column; } } }
		.form-item {
			margin-bottom: $form__inputs-space-between;
			@include max($secondbreckpoint) {
				width: 231px; }
			@include small {
				width: $input-d__width; }
			@include phone {
				width: 100%; } }

		.form-group {
			@include phone {
				width: 100%; } }
		.form-controll {
			@include small {
				display: flex;

				flex-direction: column;
				.checkbox {
					order: -1; }
				.button {
					margin-bottom:  0; } } }
		.button-wrapper {}

		.checkbox {
			width: #{$percentVector}vw;
			flex-shrink: 0;
			@include max(#{$firstbreckpoint}) {
				width: #{$percent1360Vector}vw; }
			@include max($secondbreckpoint) {
				width: #{$percentsecondVector}vw; }
			@include small {
				width: 100%; }
			@include phone {
				width: 100%; } }
		.button {
			@include offset-color(green); } } }

@include form-desc_2;
