.type-lights {
	.content {
		padding-top: 0 !important; }
	.type-lights__list {
		padding-left: 0;
		margin: 0;
		list-style: none;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-left: calc(#{$percentVector}vw);
		@include max(#{$firstbreckpoint}) {
			padding-left: #{$percent1360Vector}vw; }
		@include max($secondbreckpoint) {
			padding-left: #{$percentsecondVector}vw; }
		@include small {
			padding-left: #{$percentsmallVector}vw; }
		@include phone {
			padding-left: 0; } }



	.svg-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.4s;
		animation: opacity 4s linear infinite;
		z-index: 5; }

	.image-wrapper {
		position: relative;
		box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25); }

	.type-lights__item {
		margin-top: 200px;


		img {
			width: 100%; }
		@include phone {
			margin-left: #{$percentphoneVector}vw;
			width: #{$percentphoneVector * 3}vw;
			margin-top: 0;
			margin-bottom: 80px;
			&:nth-child(2n) {
				margin-left: 0px; }
			&:last-child {
				margin-bottom: 0; } }

		&:nth-child(2n) {
			margin-top: 0px; } }

	.caption {
		padding-left: 25px;
		position: relative;
		@include offset-color(blue);
		&:after {
			content: '';
			position: absolute;
			width: 1px;
			box-shadow: 2px 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 1px 1px rgba(0, 189, 197, 0.35);
			background-color: $color__5;
			height: 80%;
			left: 0;
			top: 10%; } }

	.text-default {
		// opacity: 0.4
		@include offset-color(green); } }



.up_down {
	opacity: 0;
	transform: translate3d(0,0,0) translateY(40px);
	transition: all 1200ms;
	transition-delay: 0.5s; }


.type-lights__list.scrolled {
	.up_down {
		transform: translateY(0%);
		opacity: 1; } }
