@mixin success {
	//showOverlay('success-form', 'Infinity');
	$width: 500px;
	$padding-top-bottom: 60px;
	$padding-left-right: 60px;
	$bg: $color__3;
	$border: none;
	$border-radius: none;
	$shadow: 0 20px 80px rgba(0, 0, 0, .4);

	$image__margin-bottom: 50px;

	.success {
		display: none;
		width: $width;
		padding: $padding-top-bottom $padding-left-right;
		background: $bg;
		@if $border != none {
			border: $border; }
		@if $border-radius != none {
			border-radius: $border-radius; }
		@if $shadow != none {
			box-shadow: $shadow; }
		img {
			display: block;
			margin-right: auto;
			margin-bottom: $image__margin-bottom;
			margin-left: auto; }
		.header_1,
		.header_1-small,
		.text-default {
			text-align: center; }
		&.active {
			display: block; } } }

@include success;
