@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Condensed Light Italic"), local("RobotoCondensed-LightItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEoYNNZQyQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Condensed Light Italic"), local("RobotoCondensed-LightItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEoadNZQyQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Condensed Light Italic"), local("RobotoCondensed-LightItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEoY9NZQyQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Condensed Light Italic"), local("RobotoCondensed-LightItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEobdNZ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed Italic"), local("RobotoCondensed-Italic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVj2ZhZI2eCN5jzbjEETS9weq8-19eLAgM9UvI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed Italic"), local("RobotoCondensed-Italic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVj2ZhZI2eCN5jzbjEETS9weq8-19eLCwM9UvI.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed Italic"), local("RobotoCondensed-Italic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVj2ZhZI2eCN5jzbjEETS9weq8-19eLAQM9UvI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed Italic"), local("RobotoCondensed-Italic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVj2ZhZI2eCN5jzbjEETS9weq8-19eLDwM9.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold Italic"), local("RobotoCondensed-BoldItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYoYNNZQyQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold Italic"), local("RobotoCondensed-BoldItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYoadNZQyQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold Italic"), local("RobotoCondensed-BoldItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYoY9NZQyQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold Italic"), local("RobotoCondensed-BoldItalic"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYobdNZ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZGCkYb8td.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZGCAYb8td.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZGCoYb8td.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZGCQYbw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19-7DRs5.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19a7DRs5.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DRs5.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCkYb8td.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCAYb8td.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin-ext */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCoYb8td.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYbw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/*   Lines    */
/**
 *	Медиа запросы
 */
/**
 * Миксин, который добавляет стандартные отступы
 * Первым передается отступ справа и слева, потому что он используется чаще
 * Аргументы передаются без 'px'
 *
 * $padding-left-right: отступ слева и справа, если отступ н нужен - указать '0'
 * $padding-top: отступ сверху
 * $padding-bottom: отступ снизу
 */
/**
 *	Clearfix
 *	Используется не в контейнере, если в блоке есть элементы с float
 */
/**
 *	Задает элементу, в котором вызывается, ширину в $width колонок (1-12)
 *
 *	$width: кол-во колонок, которое займет блок
 *	Больше информации можно найти в документации
 */
/**
 *	миксин placeholder
 *	находится на стадии тестирования
 *	будет удобно - оставим, не будет необходимости - уберем
 */
/**
 * Миксин заголовка <h1> с большим отступом ($h1__margin)
 */
/**
 * Миксин заголовка <h1> с малым отступом ($h1__margin-small)
 */
/**
 * Миксин заголовка <h2>
 */
/**
 * Миксин заголовка <h3>
 */
/**
 * Миксин заголовка <h4>
 */
/**
 * Миксин обычной кнопки, применяется для тега <button>
 */
/**
 * Миксин второго варианта кнопки, применяется для тега <button>
 */
/**
 * Миксин обычной кнопки, применяется для тега <a>
 * $width: ширина кнопки, если не указывать - будет использована минимальная
 * ширина обычной кнопки
 */
/**
 * Миксин второго варианта  кнопки, применяется для тега <a>
 * $width: ширина кнопки, если не указывать - будет использована минимальная
 * ширина обычной кнопки
 */
/**
 * Миксин для стандартного input
 */
/**
 * Миксины для фапки и футера
 */
/**
 * Контакты
 * (!)	$color: цвет ссылок
 * (!)	$space-between: вертикальное расстояние между ссылками
 * $color-hover: цвет ссылок при наведении, по умолчанию - цвет указанный выше
 * $text-decoration: подчеркивание ссылок
 * $text-decoration-hover: подчеркивание ссылок при наведении
 * $font-size: размер шрифта ссылок, по умолчанию берется размер обычного текста
 * $font-family: семейство шрифта, по умолчанию берется семейство обычного текста
 * $font-weight: жирность жрифта(начертание), по умолчанию берется жирность обычного текста
 *
 * Пример вызова с цветом '$color__1' и расстоянием между элементами в 10px:
 * +hf__contacts($color__1, 10px)
 *
 * Пример вызова с цветом '$color__1', расстоянием между элементами в 10px, цветом при наведении '$color__2' и размером шрифта '$font-size__3':
 * +hf__contacts($color__1, 10px, $color__2, $font-size: $font-size__3)
 *
 * Пример вызова с цветом '$color__1', расстоянием между элементами в 10px, всегда подчеркнутыми ссылками и цветом при наведении '$color__2':
 * +hf__contacts($color__1, 10px, $color__2, underline, underline)
 */
/**
 * Иконки для контактов
 * (!)	$class: класс, для которого будет добавлена иконка, указывать без точки
 * (!)	$width: ширина иконки
 * (!)	$height: высота иконки
 * (!)	$left: расстояние от левого края иконки до текста
 * (!)	$position: позиция иконки в спрайте
 *
 *	Пример вызова
 *	+hf__icon('.phone', 30px, 30px, 25px, -55px -60px)
 *
 */
/**
 * Соц сети
 * (!)	$width: ширина иконок
 * (!)	$height: высота иконок
 * (!)	$space-between: горизонтальное расстояние между иконками
 * (!)	$hover (opacity | images | box-shadow):	тип ховер-эффекта, выбирается один из перечисленных:
 * 			opacity - добавляет +30% прозрачности иконки.
 * 			images - меняет картинки при ховере, нужно указать картинки в вызове миксина
 * 			box-shadow - добавляет тень иконкам (0 3px 5px rgba(0, 0, 0, .5))
 * 			move - поднимает картинку на 3px
 * 	$vk: позиция иконки vk
 * 	$in: позиция иконки in
 * 	$ok: позиция иконки ok
 * 	$fb: позиция иконки fb
 * 	$vk-hover: позиция иконки vk при наведении
 * 	$in-hover: позиция иконки in при наведении
 * 	$ok-hover: позиция иконки ok при наведении
 * 	$fb-hover: позиция иконки fb при наведении
 *
 * 	Пример вызова с изменением прозрачности при ховере:
 * 	+hf__social(30px, 30px, 10px, $hover: opacity, $vk: 0px 0px)
 *
 * 	Пример вызова с изменением картинок при ховере:
 * 	+hf__social(30px, 30px, 10px, $hover: images, $vk: 0px 0px, $vk-hover: 10px 10px)
 *
 * 	Пример вызова с добавлением тени при ховере:
 * 	+hf__social(30px, 30px, 10px, $hover: box-shadow, $vk: 0px 0px)
 */
/**
 * Разделитель (вертикальная линия между элементами)
 * (!)	$width: ширина
 * (!)	$height: высота
 * (!)	$color: цвет заливки
 *
 * Пример вызова с шириной 1px, высотой 100px и цветом '$color__2'
 * +hf__separator(1px, 100px, $color__2)
 */
/**
 * (!)	parent: класс родителя, к которому добавляется элемнт, без точки
 * (!)	name: переменная, которая соответствует картинке в спрайте
 * (!)	align: выравнивание(привязка) (left | center | right)
 * также можно указать смещение
 * top: от верхнего края, если указать 0 - элемент будет привязан к верху
 * right: от правого края, работает только если выравнивание указано по правой стороне
 * bottom: от нижнего края, если указать 0 - элемент будет привязан к низу
 * left: от левого края, работает только если выравнивание указано по левой стороне
 * Пример вызова:
 * +decor((parent: 'main', name: $decor-1, align: right, top: 20px))
 */
html {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

* {
  box-sizing: border-box; }
  *:focus {
    outline: none; }

html {
  transition: all 0s;
  margin: 0;
  padding: 0; }
  html.overlay-active, html.menu-open {
    overflow-y: hidden !important; }

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
  font-family: "Roboto Condensed", sans-serif;
  transition: all 0s, opacity .3s;
  opacity: 0;
  overflow-x: hidden;
  text-size-adjust: none;
  background: #031c21;
  background-image: url("/app/images/pattern.jpg");
  -webkit-overflow-scrolling: touch;
  scrollbar-face-color: #039ea4;
  scrollbar-track-color: rgba(3, 158, 164, 0.3); }
  body::-webkit-scrollbar {
    width: 8px; }
  body::-webkit-scrollbar-thumb {
    background: #039ea4; }
  body::-webkit-scrollbar-track {
    background: rgba(3, 158, 164, 0.3); }
  body .telnayshka {
    width: 1px;
    background-color: #5f6d94;
    opacity: 0.15;
    position: absolute;
    height: 100%;
    z-index: -1; }
    body .telnayshka:nth-child(1) {
      left: calc(8vw + 10.5vw * 0); }
    body .telnayshka:nth-child(2) {
      left: calc(8vw + 10.5vw * 1); }
    body .telnayshka:nth-child(3) {
      left: calc(8vw + 10.5vw * 2); }
    body .telnayshka:nth-child(4) {
      left: calc(8vw + 10.5vw * 3); }
    body .telnayshka:nth-child(5) {
      left: calc(8vw + 10.5vw * 4); }
    body .telnayshka:nth-child(6) {
      left: calc(8vw + 10.5vw * 5); }
    body .telnayshka:nth-child(7) {
      left: calc(8vw + 10.5vw * 6); }
    body .telnayshka:nth-child(8) {
      left: calc(8vw + 10.5vw * 7); }
    body .telnayshka:nth-child(9) {
      left: calc(8vw + 10.5vw * 8); }
    @media only screen and (max-width: 1680px) {
      body .telnayshka:nth-child(1) {
        left: calc(5.5vw + 14.8vw * 0); }
      body .telnayshka:nth-child(2) {
        left: calc(5.5vw + 14.8vw * 1); }
      body .telnayshka:nth-child(3) {
        left: calc(5.5vw + 14.8vw * 2); }
      body .telnayshka:nth-child(4) {
        left: calc(5.5vw + 14.8vw * 3); }
      body .telnayshka:nth-child(5) {
        left: calc(5.5vw + 14.8vw * 4); }
      body .telnayshka:nth-child(6) {
        left: calc(5.5vw + 14.8vw * 5); }
      body .telnayshka:nth-child(7) {
        left: calc(5.5vw + 14.8vw * 6); }
      body .telnayshka:nth-child(8) {
        left: calc(5.5vw + 14.8vw * 7); }
      body .telnayshka:nth-child(9) {
        left: calc(5.5vw + 14.8vw * 8); } }
    @media only screen and (max-width: 1300px) {
      body .telnayshka:nth-child(1) {
        left: calc(2vw + (12vw * 0)); }
      body .telnayshka:nth-child(2) {
        left: calc(2vw + (12vw * 1)); }
      body .telnayshka:nth-child(3) {
        left: calc(2vw + (12vw * 2)); }
      body .telnayshka:nth-child(4) {
        left: calc(2vw + (12vw * 3)); }
      body .telnayshka:nth-child(5) {
        left: calc(2vw + (12vw * 4)); }
      body .telnayshka:nth-child(6) {
        left: calc(2vw + (12vw * 5)); }
      body .telnayshka:nth-child(7) {
        left: calc(2vw + (12vw * 6)); }
      body .telnayshka:nth-child(8) {
        left: calc(2vw + (12vw * 7)); }
      body .telnayshka:nth-child(9) {
        left: calc(2vw + (12vw * 8)); } }
    @media only screen and (max-width: 991px) {
      body .telnayshka:nth-child(1) {
        left: calc(2vw + (16vw * 0)); }
      body .telnayshka:nth-child(2) {
        left: calc(2vw + (16vw * 1)); }
      body .telnayshka:nth-child(3) {
        left: calc(2vw + (16vw * 2)); }
      body .telnayshka:nth-child(4) {
        left: calc(2vw + (16vw * 3)); }
      body .telnayshka:nth-child(5) {
        left: calc(2vw + (16vw * 4)); }
      body .telnayshka:nth-child(6) {
        left: calc(2vw + (16vw * 5)); }
      body .telnayshka:nth-child(7) {
        left: calc(2vw + (16vw * 6)); }
      body .telnayshka:nth-child(8) {
        left: calc(2vw + (16vw * 7)); }
      body .telnayshka:nth-child(9) {
        left: calc(2vw + (16vw * 8)); } }
    @media only screen and (max-width: 767px) {
      body .telnayshka:nth-child(1) {
        left: calc(2vw + 23.9vw * 0); }
      body .telnayshka:nth-child(2) {
        left: calc(2vw + 23.9vw * 1); }
      body .telnayshka:nth-child(3) {
        left: calc(2vw + 23.9vw * 2); }
      body .telnayshka:nth-child(4) {
        left: calc(2vw + 23.9vw * 3); }
      body .telnayshka:nth-child(5) {
        left: calc(2vw + 23.9vw * 4); }
      body .telnayshka:nth-child(6) {
        display: none;
        left: calc(2vw + 23.9vw * 5); }
      body .telnayshka:nth-child(7) {
        display: none;
        left: calc(2vw + 23.9vw * 6); }
      body .telnayshka:nth-child(8) {
        display: none;
        left: calc(2vw + 23.9vw * 7); }
      body .telnayshka:nth-child(9) {
        left: calc(2vw + 23.9vw * 8);
        display: none; } }
  body.loaded, body#tinymce, body#mceTemplatePreview {
    opacity: 1; }
  body#tinymce, body#mceTemplatePreview {
    background: #FFF; }
  body.overlay-active, body.menu-open {
    overflow-y: hidden !important; }
  body > div {
    transition: all 0s; }

.chunk-word {
  overflow: hidden; }
  .chunk-word p, .chunk-word span {
    display: inline-block;
    margin: 0; }
    .chunk-word p span, .chunk-word span span {
      transition: all 1.2s cubic-bezier(0.19, 1, 0.22, 1); }
  .chunk-word:hover span {
    will-change: transform;
    transform: rotateX(360deg); }

.container {
  margin-right: auto;
  margin-left: auto;
  width: 84%; }
  @media only screen and (max-width: 1680px) {
    .container {
      width: 89%; } }
  @media only screen and (max-width: 1300px) {
    .container {
      width: 96%; } }

.home-page .header_1 .header-chunk {
  overflow: hidden; }
  .home-page .header_1 .header-chunk p, .home-page .header_1 .header-chunk span {
    display: inline-block;
    margin: 0;
    transition: all 0.4s; }
  .home-page .header_1 .header-chunk span {
    transform: translateY(100%);
    will-change: transform; }

.home-page .scrolled .header-chunk {
  overflow: hidden; }
  .home-page .scrolled .header-chunk span {
    transform: translateY(0%); }

.home-page .done .header-chunk {
  overflow: hidden; }
  .home-page .done .header-chunk span {
    will-change: unset; }

.header_1 {
  position: relative;
  margin-top: 0;
  margin-bottom: 70px;
  color: #d4eaf7;
  font-size: 50px;
  font-weight: 400;
  text-align: left;
  line-height: 80px;
  letter-spacing: 3.54px;
  text-transform: uppercase; }
  @media only screen and (max-width: 1299px) {
    .header_1 {
      font-size: 40px;
      line-height: 70px;
      letter-spacing: 2.83px; } }
  @media only screen and (max-width: 991px) {
    .header_1 {
      margin-bottom: 52.5px;
      font-size: 30px;
      line-height: 55px;
      letter-spacing: 2.12px; } }
  @media only screen and (max-width: 767px) {
    .header_1 {
      margin-bottom: 35px;
      font-size: 20px;
      letter-spacing: 1.42px;
      line-height: 42px; } }

.header_1-small {
  position: relative;
  margin-top: 0;
  margin-bottom: 70px;
  color: #d4eaf7;
  font-size: 50px;
  font-weight: 400;
  text-align: left;
  line-height: 36px;
  text-transform: uppercase; }
  @media only screen and (max-width: 1299px) {
    .header_1-small {
      font-size: 40px; } }
  @media only screen and (max-width: 991px) {
    .header_1-small {
      margin-bottom: 52.5px;
      font-size: 30px; } }
  @media only screen and (max-width: 767px) {
    .header_1-small {
      margin-bottom: 35px;
      font-size: 20px; } }

.header_2 {
  position: relative;
  margin-top: 1.8em;
  margin-bottom: 1.2em;
  color: #d4eaf7;
  font-size: 25px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase; }
  @media only screen and (max-width: 1299px) {
    .header_2 {
      font-size: 24px; } }
  @media only screen and (max-width: 991px) {
    .header_2 {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .header_2 {
      font-size: 16px; } }

.header_3 {
  position: relative;
  margin-top: 1.8em;
  margin-bottom: 1.2em;
  color: #d4eaf7;
  font-size: 22px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase; }
  @media only screen and (max-width: 1299px) {
    .header_3 {
      font-size: 20px; } }
  @media only screen and (max-width: 991px) {
    .header_3 {
      font-size: 18px; } }
  @media only screen and (max-width: 767px) {
    .header_3 {
      font-size: 14px; } }

.header_4 {
  position: relative;
  margin-top: 1.8em;
  margin-bottom: 1.2em;
  color: #d4eaf7;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase; }
  @media only screen and (max-width: 1299px) {
    .header_4 {
      font-size: 16px; } }
  @media only screen and (max-width: 991px) {
    .header_4 {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    .header_4 {
      font-size: 14px; } }

.content.red {
  padding: 240px 10.5vw; }
  @media only screen and (max-width: 1680px) {
    .content.red {
      padding: 216px 0; } }
  @media only screen and (max-width: 1300px) {
    .content.red {
      padding: 216px 12vw; } }
  @media only screen and (max-width: 991px) {
    .content.red {
      padding: 192px 0; } }
  @media only screen and (max-width: 767px) {
    .content.red {
      padding: 168px 0; } }

.content.orange {
  padding: 140px 10.5vw; }
  @media only screen and (max-width: 1680px) {
    .content.orange {
      padding: 126px 0; } }
  @media only screen and (max-width: 1300px) {
    .content.orange {
      padding: 126px 12vw; } }
  @media only screen and (max-width: 991px) {
    .content.orange {
      padding: 112px 0; } }
  @media only screen and (max-width: 767px) {
    .content.orange {
      padding: 98px 0; } }

.content.yellow {
  padding: 70px 10.5vw; }
  @media only screen and (max-width: 1680px) {
    .content.yellow {
      padding: 63px 0; } }
  @media only screen and (max-width: 1300px) {
    .content.yellow {
      padding: 63px 12vw; } }
  @media only screen and (max-width: 991px) {
    .content.yellow {
      padding: 56px 0; } }
  @media only screen and (max-width: 767px) {
    .content.yellow {
      padding: 49px 0; } }

.content.green {
  padding: 55px 10.5vw; }
  @media only screen and (max-width: 1680px) {
    .content.green {
      padding: 49.5px 0; } }
  @media only screen and (max-width: 1300px) {
    .content.green {
      padding: 49.5px 12vw; } }
  @media only screen and (max-width: 991px) {
    .content.green {
      padding: 44px 0; } }
  @media only screen and (max-width: 767px) {
    .content.green {
      padding: 38.5px 0; } }

.content.blue {
  padding: 30px 10.5vw; }
  @media only screen and (max-width: 1680px) {
    .content.blue {
      padding: 27px 0; } }
  @media only screen and (max-width: 1300px) {
    .content.blue {
      padding: 27px 12vw; } }
  @media only screen and (max-width: 991px) {
    .content.blue {
      padding: 24px 0; } }
  @media only screen and (max-width: 767px) {
    .content.blue {
      padding: 21px 0; } }

.flex-row {
  display: flex; }

.image-wrapper {
  font-size: 0;
  user-select: none; }
  .image-wrapper img {
    max-width: 100%; }

.one-col {
  width: 10.5vw;
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .one-col {
      width: 14.8vw; } }
  @media only screen and (max-width: 1300px) {
    .one-col {
      width: 12vw; } }
  @media only screen and (max-width: 991px) {
    .one-col {
      width: 16vw; } }
  @media only screen and (max-width: 767px) {
    .one-col {
      width: 23.9vw; } }

.two-col {
  width: 21vw;
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .two-col {
      width: 29.6vw; } }
  @media only screen and (max-width: 1300px) {
    .two-col {
      width: 24vw; } }
  @media only screen and (max-width: 991px) {
    .two-col {
      width: 32vw; } }
  @media only screen and (max-width: 767px) {
    .two-col {
      width: 47.8vw; } }

.three-col {
  width: 31.5vw;
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .three-col {
      width: 44.4vw; } }
  @media only screen and (max-width: 1300px) {
    .three-col {
      width: 36vw; } }
  @media only screen and (max-width: 991px) {
    .three-col {
      width: 48vw; } }
  @media only screen and (max-width: 767px) {
    .three-col {
      width: 100%; } }

.four-col {
  width: calc(42vw);
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .four-col {
      width: calc(59.2vw); } }
  @media only screen and (max-width: 1300px) {
    .four-col {
      width: calc(48vw); } }
  @media only screen and (max-width: 991px) {
    .four-col {
      width: calc(64vw); } }
  @media only screen and (max-width: 767px) {
    .four-col {
      width: 100%; } }

.arrow-wrapper {
  display: flex; }

.c-small-circle {
  display: flex;
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 0.4;
  transition: stroke-dashoffset 0.5s linear, stroke 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
  stroke-dasharray: 90;
  stroke-dashoffset: 90;
  stroke: #039ea4;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4; }

.c-small-outline {
  display: flex;
  fill: none;
  stroke-miterlimit: 5;
  stroke-width: 0.3;
  transition-delay: 0s;
  stroke-dasharray: 90;
  stroke-dashoffset: 0;
  stroke: #039ea4;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 5;
  opacity: 0;
  left: 0; }

@keyframes arrow {
  0% {
    stroke-dashoffset: 90; }
  100% {
    stroke-dashoffset: 0; } }

.arrow-next .c-small-outline {
  opacity: 0.3; }

.arrow-prev .c-small-circle {
  stroke-dashoffset: -90;
  transition: all 0.4s; }

.arrow-prev:hover .c-small-circle {
  stroke-dashoffset: 0; }

.arrow-next:not(.slick-disabled) .c-small-circle {
  animation: arrow 4.3s linear infinite; }

.arrow-next.clear:not(.slick-disabled) .c-small-circle {
  stroke-dashoffset: 90 !important;
  animation: none;
  transition: stroke-dashoffset 0.3s linear, stroke 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) !important; }

.caption-left-line {
  position: relative;
  padding-left: 25px; }
  @media only screen and (max-width: 1300px) {
    .caption-left-line {
      padding-left: 15px; } }
  @media only screen and (max-width: 991px) {
    .caption-left-line {
      padding-left: 12px; } }
  .caption-left-line:after {
    content: "";
    position: absolute;
    width: 1px;
    box-shadow: rgba(0, 0, 0, 0.21) 2px 8px 18px 6px, rgba(0, 189, 197, 0.35) 0px 0px 1px 1px;
    background-color: #039ea4;
    height: 80%;
    left: 0px;
    top: 10%; }

.header_1.half-col-padding {
  margin-top: 99px; }
  @media only screen and (max-width: 1299px) {
    .header_1.half-col-padding {
      margin-top: 80px; } }
  @media only screen and (max-width: 991px) {
    .header_1.half-col-padding {
      margin-top: 70px; } }
  @media only screen and (max-width: 767px) {
    .header_1.half-col-padding {
      margin-top: 60px; } }

.header_decor {
  position: absolute;
  width: 10.5vw;
  display: block;
  left: 0;
  flex-shrink: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  transform: translateY(-40%);
  z-index: -1;
  font-size: 0; }
  .header_decor img {
    width: 100%;
    opacity: 0; }
  @media only screen and (max-width: 1680px) {
    .header_decor {
      width: 14.8vw; } }
  @media only screen and (max-width: 1300px) {
    .header_decor {
      width: 12vw; } }
  @media only screen and (max-width: 991px) {
    .header_decor {
      width: 16vw; } }
  @media only screen and (max-width: 767px) {
    .header_decor {
      width: 23.9vw; } }

.half-col-padding {
  padding-left: 5.25vw;
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .half-col-padding {
      padding-left: 7.4vw; } }
  @media only screen and (max-width: 1300px) {
    .half-col-padding {
      padding-left: 6vw; } }
  @media only screen and (max-width: 991px) {
    .half-col-padding {
      padding-left: 8vw; } }
  @media only screen and (max-width: 767px) {
    .half-col-padding {
      padding-left: 11.95vw; } }

@keyframes opacity {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes light {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

.text-default {
  color: rgba(212, 234, 247, 0.4);
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  line-height: 1.8em;
  letter-spacing: 1.28px; }
  @media only screen and (max-width: 1299px) {
    .text-default {
      font-size: 16px;
      line-height: 30px; } }
  @media only screen and (max-width: 991px) {
    .text-default {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    .text-default {
      font-size: 14px;
      line-height: 25px; } }
  .text-default a {
    color: rgba(212, 234, 247, 0.4);
    font-weight: 300;
    text-decoration: none; }
    .text-default a:hover {
      box-shadow: none; }
    .text-default a:hover {
      color: rgba(212, 234, 247, 0.2); }

.caption {
  opacity: 0.8;
  color: #d4eaf7;
  font-size: 25px;
  font-weight: 300;
  line-height: 35px;
  text-transform: uppercase;
  letter-spacing: 6.25px; }
  @media only screen and (max-width: 1300px) {
    .caption {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 5.25px; } }
  @media only screen and (max-width: 991px) {
    .caption {
      font-size: 20px;
      letter-spacing: 4.25px; } }
  @media only screen and (max-width: 767px) {
    .caption {
      font-size: 16px;
      letter-spacing: 3.25px; } }

.button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  text-shadow: 0 0 35px rgba(0, 0, 0, 0.36);
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 6px;
  position: relative;
  cursor: pointer;
  height: calc(10.5vw * 0.7);
  min-width: 10.5vw;
  text-align: center;
  box-shadow: 0 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 1px 1px rgba(0, 189, 197, 0.35);
  border: 1px solid #039ea4;
  position: relative;
  z-index: 5;
  overflow: hidden; }
  .button:hover:after {
    transform: translateX(0); }
  .button:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #039ea4;
    left: 0;
    top: 0;
    z-index: -1;
    transform: translateX(-100%);
    transition: all 0.4s; }
  @media only screen and (max-width: 1680px) {
    .button {
      min-width: 14.8vw;
      height: calc(14.8vw * 0.7); } }
  @media only screen and (max-width: 1300px) {
    .button {
      font-size: calc(1vw + 2px);
      height: calc(12vw * 0.7);
      min-width: 12vw;
      letter-spacing: 2px; } }
  @media only screen and (max-width: 991px) {
    .button {
      font-size: 14px;
      min-width: 16vw;
      height: calc(16vw * 0.7);
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 767px) {
    .button {
      min-width: calc(23.9vw * 2);
      height: calc(23.9vw * 1.4); } }

.button-wrapper {
  position: relative; }
  .button-wrapper:after {
    content: '';
    width: 16vw;
    height: 16vw;
    width: 15.75vw;
    height: 15.75vw;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate3d(-150px, -140px, 0);
    z-index: -1;
    background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, rgba(9, 30, 35, 0.21) 54%, rgba(7, 26, 30, 0.21) 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%);
    opacity: 0.5; }
  @media only screen and (max-width: 1680px) {
    .button-wrapper:after {
      width: 17.76vw;
      height: 17.76vw;
      transform: translate3d(-8.88vw, -10.36vw, 0); } }
  @media only screen and (max-width: 1300px) {
    .button-wrapper:after {
      width: 14.4vw;
      height: 14.4vw;
      transform: translate3d(-7.2vw, -8.4vw, 0); } }
  @media only screen and (max-width: 991px) {
    .button-wrapper:after {
      width: 19.2vw;
      height: 19.2vw;
      transform: translate3d(-9.6vw, -11.2vw, 0); } }
  @media only screen and (max-width: 767px) {
    .button-wrapper:after {
      width: 50.19vw;
      height: 50.19vw;
      transform: translate3d(-28.68vw, -23.9vw, 0); } }

.button-invert {
  display: block;
  min-width: 220px;
  max-width: 100%;
  padding: 12.5px 20px;
  color: #d4eaf7 !important;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 26px;
  border: 2px solid #d4eaf7;
  background: transparent;
  user-select: none; }
  .button-invert:hover, .button-invert:focus {
    background: #d4eaf7;
    cursor: pointer;
    outline: none; }
  @media only screen and (max-width: 767px) {
    .button-invert {
      min-width: 0; } }
  @media only screen and (max-width: 340px) {
    .button-invert {
      width: 100%; } }
  .button-invert.left {
    margin-right: auto;
    margin-left: 0; }
  .button-invert.center {
    margin-right: auto;
    margin-left: auto; }
  .button-invert.right {
    margin-right: 0;
    margin-left: auto; }

a.button {
  width: 220px;
  display: block;
  min-width: 220px;
  max-width: 100%;
  padding: 14.5px 20px;
  color: #d4eaf7 !important;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 26px;
  border: none;
  background: #d4eaf7;
  user-select: none;
  text-decoration: none; }
  a.button:hover, a.button:focus {
    background: #5f5;
    cursor: pointer;
    outline: none; }
  @media only screen and (max-width: 767px) {
    a.button {
      min-width: 0;
      font-size: 16px; } }
  @media only screen and (max-width: 340px) {
    a.button {
      width: 100%; } }

a.button-i {
  width: 220px;
  display: block;
  min-width: 220px;
  max-width: 100%;
  padding: 12.5px 20px;
  color: #d4eaf7 !important;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 26px;
  border: 2px solid #d4eaf7;
  background: transparent;
  user-select: none;
  text-decoration: none; }
  a.button-i:hover, a.button-i:focus {
    background: #d4eaf7;
    cursor: pointer;
    outline: none; }
  @media only screen and (max-width: 767px) {
    a.button-i {
      min-width: 0; } }
  @media only screen and (max-width: 340px) {
    a.button-i {
      width: 100%; } }

.form-item {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .form-item:not(.checkbox):after {
    position: absolute;
    content: '';
    height: 100%;
    width: 2px;
    left: 0;
    top: 0;
    transition: all 0.4s;
    background: #039ea4;
    transform: translateY(-100%); }
  .form-item input {
    display: block;
    width: 309px;
    max-width: 100%;
    letter-spacing: 2.4px;
    padding: 9.5px 10px;
    color: rgba(212, 234, 247, 0.4);
    font-size: 16px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    line-height: 24px;
    border: 1px solid rgba(212, 234, 247, 0.2);
    border-radius: 0;
    background-color: transparent;
    appearance: none; }
    @media only screen and (max-width: 767px) {
      .form-item input {
        width: 100%;
        font-size: 14px; } }
    .form-item input:focus {
      background-color: transparent;
      outline: none; }
  .form-item textarea {
    display: block;
    width: 309px;
    max-width: 100%;
    letter-spacing: 2.4px;
    padding: 9.5px 10px;
    color: rgba(212, 234, 247, 0.4);
    font-size: 16px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    line-height: 24px;
    border: 1px solid rgba(212, 234, 247, 0.2);
    border-radius: 0;
    background-color: transparent;
    appearance: none;
    resize: vertical; }
    @media only screen and (max-width: 767px) {
      .form-item textarea {
        width: 100%;
        font-size: 14px; } }
    .form-item textarea:focus {
      background-color: transparent;
      outline: none; }
  .form-item label {
    position: absolute;
    top: 0;
    right: 0;
    left: 11px;
    height: 45px;
    color: rgba(212, 234, 247, 0.4);
    font-size: 16px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    line-height: 45px;
    text-align: left;
    cursor: text;
    transition: opacity .2s, left -1s 1s ease-out;
    user-select: none; }
    @media only screen and (max-width: 767px) {
      .form-item label {
        font-size: 14px; } }
  .form-item.focus:not(.checkbox):after {
    opacity: 1;
    transform: translateY(0); }
  .form-item.focus label {
    opacity: 0;
    left: 100%;
    transition: opacity .2s, left 1s 1s ease-out;
    color: rgba(212, 234, 247, 0.4);
    font-size: 16px;
    font-weight: 300; }
  .form-item.checkbox {
    display: flex;
    padding-top: 0;
    width: unset; }
    .form-item.checkbox label {
      font-size: 14px; }
      @media only screen and (max-width: 767px) {
        .form-item.checkbox label {
          font-size: 12px; } }
    .form-item.checkbox .wrapper {
      position: relative;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin: 0;
      border: 1px solid rgba(212, 234, 247, 0.4);
      border-radius: 0; }
      .form-item.checkbox .wrapper input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        opacity: 0;
        appearance: none; }
        @media only screen and (max-width: 767px) {
          .form-item.checkbox .wrapper input {
            margin-top: 1px; } }
        .form-item.checkbox .wrapper input:hover {
          cursor: pointer; }
        .form-item.checkbox .wrapper input:checked + span:after {
          opacity: 1; }
        .form-item.checkbox .wrapper input:hover {
          cursor: pointer; }
      .form-item.checkbox .wrapper span {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
        border: none;
        padding: 2px;
        display: flex;
        background-color: transparent;
        box-shadow: none; }
        .form-item.checkbox .wrapper span:after {
          display: block;
          content: '';
          width: 90%;
          height: 90%;
          transform: translate3d(5%, 5%, 0);
          box-sizing: border-box;
          background-color: #039ea4;
          opacity: 0;
          transition: all 0.2s; }
    .form-item.checkbox label {
      position: relative;
      top: 0;
      height: auto;
      line-height: 1.3em;
      cursor: pointer;
      user-select: none; }

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: none;
  background: transparent;
  appearance: none;
  cursor: pointer;
  transition: all .2s; }
  .arrow .arrow-button {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20%; }
  .arrow:hover:not(.slick-disabled) {
    background: transparent; }
    .arrow:hover:not(.slick-disabled) .stick {
      fill: rgba(212, 234, 247, 0.3); }
  @media only screen and (max-width: 767px) {
    .arrow {
      width: 82px;
      height: 82px; } }
  .arrow .stick {
    transform-origin: 50% 50%;
    fill: rgba(212, 234, 247, 0.4); }
  .arrow-prev .stick {
    transform: rotate(180deg); }
  .arrow-next .stick {
    transform: rotate(0deg); }
  .arrow.slick-disabled, .arrow.disabled {
    cursor: default;
    opacity: .3; }

.pagination {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow-x: auto; }
  .pagination div {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 82px;
    margin: 0 5px; }
    .pagination div .number {
      display: block;
      width: 32px;
      height: 32px;
      color: #d4eaf7;
      text-align: center;
      text-decoration: none;
      line-height: 32px;
      user-select: none; }
      .pagination div .number:hover {
        color: #5f5;
        text-decoration: none;
        border-color: #5f5; }
      .pagination div .number.active {
        color: #5f5;
        font-weight: 400;
        cursor: default; }

svg {
  width: 100%;
  height: 100%; }

.instashow-popup-media-picture {
  min-height: auto !important; }

@media screen and (max-width: 500px) {
  div.instashow-popup-container {
    position: absolute !important;
    width: 100% !important;
    height: auto !important;
    left: 50% !important;
    top: 50% !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important; } }

.main {
  margin-top: 140px;
  border-top: 1px solid transparent;
  margin-bottom: 240px; }
  @media only screen and (max-width: 1680px) {
    .main {
      margin-bottom: 216px; } }
  @media only screen and (max-width: 1300px) {
    .main {
      margin-bottom: 216px; } }
  @media only screen and (max-width: 991px) {
    .main {
      margin-bottom: 192px; } }
  @media only screen and (max-width: 767px) {
    .main {
      margin-bottom: 168px; } }
  .main .aside {
    flex-shrink: 0;
    width: 320px;
    background-color: pink; }
    @media only screen and (max-width: 991px) {
      .main .aside {
        order: 2; } }
  .main__wrapper {
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 991px) {
      .main__wrapper {
        flex-wrap: wrap;
        justify-content: center; } }
    .main__wrapper .main__content {
      flex-grow: 1;
      padding: 50px 0px 90px; }
      @media only screen and (max-width: 1299px) {
        .main__wrapper .main__content {
          padding: 40px 0px 72px; } }
      @media only screen and (max-width: 991px) {
        .main__wrapper .main__content {
          padding: 30px 0px 54px; } }
      @media only screen and (max-width: 767px) {
        .main__wrapper .main__content {
          padding: 20px 0px 36px; } }
    .main__wrapper > *:first-child {
      margin-right: 30px; }
      @media only screen and (max-width: 991px) {
        .main__wrapper > *:first-child {
          margin-right: 0; } }
  .main__content {
    padding: 240px 10.5vw;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 40px; }
    @media only screen and (max-width: 1680px) {
      .main__content {
        padding: 216px 0; } }
    @media only screen and (max-width: 1300px) {
      .main__content {
        padding: 216px 12vw; } }
    @media only screen and (max-width: 991px) {
      .main__content {
        padding: 192px 0; } }
    @media only screen and (max-width: 767px) {
      .main__content {
        padding: 168px 0; } }
    .main__content > .text-default > * {
      padding-left: 5.25vw;
      padding-right: 21vw; }
      @media only screen and (max-width: 1680px) {
        .main__content > .text-default > * {
          padding-left: 7.4vw;
          padding-right: 29.6vw; } }
      @media only screen and (max-width: 1300px) {
        .main__content > .text-default > * {
          padding-left: 6vw;
          padding-right: 12vw; } }
      @media only screen and (max-width: 991px) {
        .main__content > .text-default > * {
          padding-left: 8vw;
          padding-right: 32vw; } }
      @media only screen and (max-width: 767px) {
        .main__content > .text-default > * {
          padding-left: 11.95vw;
          padding-right: 11.95vw; } }
    .main__content > .text-default .gallery-blocks {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 240px; }
      @media only screen and (max-width: 1680px) {
        .main__content > .text-default .gallery-blocks {
          margin-top: 200px; } }
      @media only screen and (max-width: 1300px) {
        .main__content > .text-default .gallery-blocks {
          margin-top: 150px; } }
      @media only screen and (max-width: 991px) {
        .main__content > .text-default .gallery-blocks {
          margin-top: 100px; } }
      @media only screen and (max-width: 767px) {
        .main__content > .text-default .gallery-blocks {
          margin-top: 60px; } }
    @media only screen and (max-width: 991px) {
      .main__content {
        order: 1;
        width: 100%; } }
    .main__content > *:last-child {
      margin-bottom: 0; }
    .main__content h1 {
      margin-top: 0;
      margin-bottom: 70px;
      color: #d4eaf7;
      font-size: 50px;
      font-weight: 400;
      text-align: left;
      line-height: 80px;
      letter-spacing: 3.54px;
      text-transform: uppercase;
      padding-left: 5.25vw;
      margin-top: 99px; }
      @media only screen and (max-width: 1299px) {
        .main__content h1 {
          font-size: 40px;
          line-height: 70px;
          letter-spacing: 2.83px; } }
      @media only screen and (max-width: 991px) {
        .main__content h1 {
          margin-bottom: 52.5px;
          font-size: 30px;
          line-height: 55px;
          letter-spacing: 2.12px; } }
      @media only screen and (max-width: 767px) {
        .main__content h1 {
          margin-bottom: 35px;
          font-size: 20px;
          letter-spacing: 1.42px;
          line-height: 42px; } }
      @media only screen and (max-width: 1680px) {
        .main__content h1 {
          padding-left: 7.4vw; } }
      @media only screen and (max-width: 1300px) {
        .main__content h1 {
          padding-left: 6vw; } }
      @media only screen and (max-width: 1299px) {
        .main__content h1 {
          margin-top: 80px; } }
      @media only screen and (max-width: 991px) {
        .main__content h1 {
          padding-left: 8vw;
          margin-top: 70px; } }
      @media only screen and (max-width: 767px) {
        .main__content h1 {
          padding-left: 11.95vw;
          margin-top: 60px; } }
    .main__content h2 {
      margin-top: 1.8em;
      margin-bottom: 1.2em;
      color: #d4eaf7;
      font-size: 25px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase; }
      @media only screen and (max-width: 1299px) {
        .main__content h2 {
          font-size: 24px; } }
      @media only screen and (max-width: 991px) {
        .main__content h2 {
          font-size: 20px; } }
      @media only screen and (max-width: 767px) {
        .main__content h2 {
          font-size: 16px; } }
    .main__content h3 {
      margin-top: 1.8em;
      margin-bottom: 1.2em;
      color: #d4eaf7;
      font-size: 22px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase; }
      @media only screen and (max-width: 1299px) {
        .main__content h3 {
          font-size: 20px; } }
      @media only screen and (max-width: 991px) {
        .main__content h3 {
          font-size: 18px; } }
      @media only screen and (max-width: 767px) {
        .main__content h3 {
          font-size: 14px; } }
    .main__content h4 {
      margin-top: 1.8em;
      margin-bottom: 1.2em;
      color: #d4eaf7;
      font-size: 18px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase; }
      @media only screen and (max-width: 1299px) {
        .main__content h4 {
          font-size: 16px; } }
      @media only screen and (max-width: 991px) {
        .main__content h4 {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .main__content h4 {
          font-size: 14px; } }
    .main__content a {
      color: rgba(212, 234, 247, 0.4);
      font-weight: 300;
      text-decoration: none; }
      .main__content a:hover {
        box-shadow: none; }
      .main__content a:hover {
        color: rgba(212, 234, 247, 0.2); }
    .main__content ul {
      list-style: none;
      padding-left: 0; }
      .main__content ul li {
        position: relative; }
        .main__content ul li:after {
          position: absolute;
          content: '';
          background: #039ea4;
          border-radius: 50%;
          width: 5px;
          height: 5px;
          left: -20px;
          transform: translateX(50%);
          top: 15px;
          box-shadow: 0px 0px 12px 1px rgba(0, 189, 197, 0.35); }
    .main__content img {
      display: block;
      max-width: 100%; }
      @media only screen and (max-width: 600px) {
        .main__content img {
          margin-right: 0;
          margin-left: 0; } }
      @media only screen and (max-width: 767px) {
        .main__content img {
          margin: 10px auto !important; } }
  .main__map {
    min-height: 0;
    visibility: hidden; }
    .main__map.active {
      min-height: 600px;
      visibility: visible; }
      @media only screen and (max-width: 767px) {
        .main__map.active {
          min-height: 350px; }
          .main__map.active img {
            margin: 0 !important; } }
      .main__map.active * {
        transition: all 0s; }

.about-home .image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0; }
  .about-home .image-wrapper img {
    width: 100%; }
  .about-home .image-wrapper:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 81%;
    box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
    z-index: 6; }
  .about-home .image-wrapper .svg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s;
    z-index: 5;
    animation: opacity 4s linear infinite; }

.about-home .caption {
  position: absolute;
  left: 100%;
  margin-left: -10.5vw;
  top: 0;
  width: 10.5vw;
  opacity: 0.8;
  color: #d4eaf7;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 4px; }
  @media only screen and (max-width: 1680px) {
    .about-home .caption {
      margin-left: -14.8vw;
      width: 14.8vw; } }
  @media only screen and (max-width: 1300px) {
    .about-home .caption {
      width: 18vw;
      margin-left: -12vw; } }
  @media only screen and (max-width: 991px) {
    .about-home .caption {
      position: relative;
      margin-left: 24vw;
      left: 0;
      width: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 3px; } }
  @media only screen and (max-width: 767px) {
    .about-home .caption {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 3px;
      margin-left: 35.85vw; } }

.about-home .flex-row {
  position: relative;
  padding-bottom: 240px; }
  .about-home .flex-row p:first-child {
    margin-top: 0; }
  @media only screen and (max-width: 767px) {
    .about-home .flex-row {
      flex-direction: column;
      padding-bottom: 0; }
      .about-home .flex-row .text-default, .about-home .flex-row .image-wrapper {
        width: 100%; }
      .about-home .flex-row .text-default {
        margin-bottom: 55px; }
      .about-home .flex-row .image-wrapper {
        position: relative; } }

.contacts-form .content {
  padding-top: 0 !important; }

.contacts-form .flex-row {
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .contacts-form .flex-row {
      flex-direction: column; } }

.contacts-form .description {
  padding-top: 10px; }
  .contacts-form .description > .caption {
    margin-bottom: 30px; }
    @media only screen and (max-width: 1680px) {
      .contacts-form .description > .caption {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 1300px) {
      .contacts-form .description > .caption {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 991px) {
      .contacts-form .description > .caption {
        margin-bottom: 24px; } }
    @media only screen and (max-width: 767px) {
      .contacts-form .description > .caption {
        margin-bottom: 21px; } }
  .contacts-form .description > .text-default {
    margin-bottom: 70px; }
    @media only screen and (max-width: 1680px) {
      .contacts-form .description > .text-default {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 1300px) {
      .contacts-form .description > .text-default {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 991px) {
      .contacts-form .description > .text-default {
        margin-bottom: 56px; } }
    @media only screen and (max-width: 767px) {
      .contacts-form .description > .text-default {
        margin-bottom: 49px; } }
  .contacts-form .description .flex-row {
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 991px) {
      .contacts-form .description .flex-row {
        flex-direction: column; } }

.contacts-form .form-item {
  margin-bottom: 30px; }
  @media only screen and (max-width: 1300px) {
    .contacts-form .form-item {
      width: 231px; } }
  @media only screen and (max-width: 991px) {
    .contacts-form .form-item {
      width: 309px; } }
  @media only screen and (max-width: 767px) {
    .contacts-form .form-item {
      width: 100%; } }

.contacts-form .description {
  margin-top: 99px; }
  @media only screen and (max-width: 1299px) {
    .contacts-form .description {
      margin-top: 80px; } }
  @media only screen and (max-width: 991px) {
    .contacts-form .description {
      margin-top: 70px;
      margin-right: 16vw; } }
  @media only screen and (max-width: 767px) {
    .contacts-form .description {
      margin-top: 60px; } }

@media only screen and (max-width: 767px) {
  .contacts-form .form-group {
    width: 100%; } }

@media only screen and (max-width: 991px) {
  .contacts-form .form-controll {
    display: flex;
    flex-direction: column; }
    .contacts-form .form-controll .checkbox {
      order: -1; }
    .contacts-form .form-controll .button {
      margin-bottom: 0; } }

.contacts-form .checkbox {
  width: 10.5vw;
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .contacts-form .checkbox {
      width: 14.8vw; } }
  @media only screen and (max-width: 1300px) {
    .contacts-form .checkbox {
      width: 12vw; } }
  @media only screen and (max-width: 991px) {
    .contacts-form .checkbox {
      width: 32vw; } }
  @media only screen and (max-width: 767px) {
    .contacts-form .checkbox {
      width: 100%; } }

.contacts-form .button {
  margin-bottom: 55px; }
  @media only screen and (max-width: 1680px) {
    .contacts-form .button {
      margin-bottom: 49.5px; } }
  @media only screen and (max-width: 1300px) {
    .contacts-form .button {
      margin-bottom: 49.5px; } }
  @media only screen and (max-width: 991px) {
    .contacts-form .button {
      margin-bottom: 44px; } }
  @media only screen and (max-width: 767px) {
    .contacts-form .button {
      margin-bottom: 38.5px; } }

.main-contacts {
  margin-top: 100px; }
  @media only screen and (max-width: 1299px) {
    .main-contacts {
      margin-bottom: 90px; } }
  @media only screen and (max-width: 991px) {
    .main-contacts {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .main-contacts {
      margin-bottom: 60px; } }

.main__map.active {
  margin-bottom: 260px;
  box-shadow: 2px 8px 18px 6px rgba(0, 0, 0, 0.21); }
  @media only screen and (max-width: 1299px) {
    .main__map.active {
      margin-bottom: 200px; } }
  @media only screen and (max-width: 991px) {
    .main__map.active {
      margin-bottom: 100px; } }
  @media only screen and (max-width: 767px) {
    .main__map.active {
      margin-bottom: 80px; } }

.price .content {
  padding-top: 0 !important; }

@media only screen and (max-width: 767px) {
  .price .flex-row {
    flex-direction: column; } }

.price .flex-row.top {
  align-items: flex-start; }
  @media only screen and (max-width: 991px) {
    .price .flex-row.top {
      flex-wrap: wrap; } }
  .price .flex-row.top .header-caption, .price .flex-row.top .garant-caption {
    opacity: 0.8;
    color: #d4eaf7;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-top: 99px; }
    @media only screen and (max-width: 1299px) {
      .price .flex-row.top .header-caption, .price .flex-row.top .garant-caption {
        margin-top: 80px; } }
    @media only screen and (max-width: 1680px) {
      .price .flex-row.top .header-caption, .price .flex-row.top .garant-caption {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 3.5px; } }
    @media only screen and (max-width: 991px) {
      .price .flex-row.top .header-caption, .price .flex-row.top .garant-caption {
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-top: 70px; } }
    @media only screen and (max-width: 767px) {
      .price .flex-row.top .header-caption, .price .flex-row.top .garant-caption {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 3.01px;
        margin-top: 60px; } }
  @media only screen and (max-width: 991px) {
    .price .flex-row.top .header_1 {
      margin-bottom: 0; } }
  @media only screen and (max-width: 767px) {
    .price .flex-row.top .header_1 {
      padding-left: 11.95vw; } }
  .price .flex-row.top .header-caption div {
    width: 15.75vw; }
  @media only screen and (max-width: 1300px) {
    .price .flex-row.top .header-caption div {
      width: 100%;
      padding-right: 20px; } }
  @media only screen and (max-width: 991px) {
    .price .flex-row.top .header-caption {
      width: 48vw; }
      .price .flex-row.top .header-caption div {
        width: unset; } }
  @media only screen and (max-width: 767px) {
    .price .flex-row.top .header-caption {
      margin-left: 11.95vw;
      margin-bottom: 45px;
      width: unset;
      margin-top: 40px; } }
  .price .flex-row.top .garant-caption:after {
    height: 90%;
    top: 5%; }
  @media only screen and (max-width: 991px) {
    .price .flex-row.top .garant-caption {
      width: 32vw;
      margin-left: 48vw;
      margin-bottom: 55px;
      margin-top: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .price .flex-row.top .garant-caption {
      margin-bottom: 50px;
      width: 47.8vw;
      margin-left: 23.9vw; } }

@media only screen and (max-width: 767px) {
  .price .button-wrapper {
    align-self: flex-end; } }

.price .right > .text-default {
  margin-bottom: 70px; }
  @media only screen and (max-width: 1680px) {
    .price .right > .text-default {
      margin-bottom: 63px; } }
  @media only screen and (max-width: 1300px) {
    .price .right > .text-default {
      margin-bottom: 63px; } }
  @media only screen and (max-width: 991px) {
    .price .right > .text-default {
      margin-bottom: 56px; } }
  @media only screen and (max-width: 767px) {
    .price .right > .text-default {
      margin-bottom: 49px; } }

.price .right .flex-row {
  justify-content: space-between; }
  .price .right .flex-row .text-default {
    margin-bottom: 0; }
    @media only screen and (max-width: 1300px) {
      .price .right .flex-row .text-default {
        width: 18vw; } }
    @media only screen and (max-width: 991px) {
      .price .right .flex-row .text-default {
        width: 24vw; } }
    @media only screen and (max-width: 767px) {
      .price .right .flex-row .text-default {
        margin-bottom: 10px;
        width: 59.75vw; } }

.price .image-wrapper {
  position: relative;
  box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
  width: 26.25vw; }
  @media only screen and (max-width: 1680px) {
    .price .image-wrapper {
      width: 37vw; } }
  @media only screen and (max-width: 1300px) {
    .price .image-wrapper {
      width: 30vw; } }
  @media only screen and (max-width: 991px) {
    .price .image-wrapper {
      width: 40vw; } }
  @media only screen and (max-width: 767px) {
    .price .image-wrapper {
      width: 100%;
      margin-bottom: 48px; } }
  .price .image-wrapper img {
    width: 100%; }

.price .svg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  z-index: 5;
  animation: opacity 4s linear infinite; }

.price .payment {
  opacity: 0.8;
  color: #d4eaf7;
  font-size: 25px;
  font-weight: 300;
  line-height: 29.91px;
  text-transform: uppercase;
  letter-spacing: 6.27px;
  margin-bottom: 90px; }
  .price .payment .number {
    font-size: 50px;
    font-weight: 400;
    line-height: 29.91px;
    text-transform: uppercase;
    letter-spacing: 5.02px; }
  @media only screen and (max-width: 1300px) {
    .price .payment {
      font-size: 24px;
      line-height: 29.91px;
      letter-spacing: 6.02px;
      margin-bottom: 65px; }
      .price .payment .number {
        font-size: 40px;
        line-height: 75px;
        letter-spacing: 4.01px; } }
  @media only screen and (max-width: 991px) {
    .price .payment {
      line-height: 29.91px;
      letter-spacing: 4.01px;
      margin-bottom: 60px; }
      .price .payment .number {
        font-size: 30px;
        line-height: 55px;
        letter-spacing: 3.01px; } }
  @media only screen and (max-width: 767px) {
    .price .payment {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 29.91px;
      line-height: 29.91px; }
      .price .payment .number {
        font-size: 35px;
        font-weight: 400;
        line-height: 29.91px;
        letter-spacing: 3.51px; } }

.confidence .content {
  padding-top: 0 !important; }
  @media only screen and (max-width: 767px) {
    .confidence .content > .flex-row {
      flex-direction: column; }
    .confidence .content .right {
      margin-left: 23.9vw; } }

.confidence .right.flex-row {
  flex-wrap: wrap;
  margin-top: 130px;
  align-items: center; }

.confidence .image-wrapper {
  text-align: center;
  margin-bottom: 90px; }
  .confidence .image-wrapper:nth-child(n + 4) {
    margin-bottom: 0px !important; }
  @media only screen and (max-width: 767px) {
    .confidence .image-wrapper {
      margin-bottom: 60px; } }
  .confidence .image-wrapper img {
    width: 70%; }

.portfolio-home .content {
  padding-top: 0 !important; }

.portfolio-home .flex-row {
  justify-content: space-between; }

.portfolio-home .see-all {
  margin-top: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .portfolio-home .see-all a {
    opacity: 0.8;
    color: #d4eaf7;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-right: 3.15vw; }
    @media only screen and (max-width: 1680px) {
      .portfolio-home .see-all a {
        padding-right: 4.44vw; } }
    @media only screen and (max-width: 1300px) {
      .portfolio-home .see-all a {
        padding-right: 3.6vw; } }
    @media only screen and (max-width: 991px) {
      .portfolio-home .see-all a {
        padding-right: 4.8vw; } }
    .portfolio-home .see-all a:hover {
      opacity: 0.4; }
    .portfolio-home .see-all a span {
      display: flex;
      width: 100%;
      width: 49px;
      height: 9px;
      opacity: 0.4;
      margin-left: 10px; }
      .portfolio-home .see-all a span svg {
        fill: #d4eaf7; }

.portfolio-home .portfolio__list {
  padding-left: 0;
  margin: 0;
  list-style: none;
  padding-top: 60px;
  overflow: hidden; }
  .portfolio-home .portfolio__list .portfolio-gallery-wrapper {
    font-size: 0;
    box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
    width: 100%;
    width: 36.75vw;
    flex-shrink: 0; }
    @media only screen and (max-width: 1680px) {
      .portfolio-home .portfolio__list .portfolio-gallery-wrapper {
        width: 51.8vw; } }
    @media only screen and (max-width: 1300px) {
      .portfolio-home .portfolio__list .portfolio-gallery-wrapper {
        width: 42vw; } }
    @media only screen and (max-width: 991px) {
      .portfolio-home .portfolio__list .portfolio-gallery-wrapper {
        width: 56vw; } }
    @media only screen and (max-width: 767px) {
      .portfolio-home .portfolio__list .portfolio-gallery-wrapper {
        width: 100%; } }
  .portfolio-home .portfolio__list .slick-slide img {
    width: 100%; }
  .portfolio-home .portfolio__list .portfolio__item {
    margin-bottom: 240px;
    opacity: 0;
    transform: translate3d(0, 0, 0) translateY(40px);
    transition: all 1200ms;
    transition-delay: 0.5s; }
    .portfolio-home .portfolio__list .portfolio__item.scrolled {
      transform: translateY(0%);
      opacity: 1; }
    .portfolio-home .portfolio__list .portfolio__item:last-child {
      margin-bottom: 0; }
    .portfolio-home .portfolio__list .portfolio__item .bottom.arrow-wrapper {
      transform: translateX(-16px); }
    @media only screen and (max-width: 767px) {
      .portfolio-home .portfolio__list .portfolio__item {
        margin-bottom: 170px;
        position: relative;
        padding-bottom: 100px; }
        .portfolio-home .portfolio__list .portfolio__item .bottom.arrow-wrapper {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0; }
        .portfolio-home .portfolio__list .portfolio__item > .flex-row {
          flex-direction: column; }
          .portfolio-home .portfolio__list .portfolio__item > .flex-row .portfolio-description {
            padding-left: 23.9vw;
            width: 100%;
            order: -1;
            margin-bottom: 42px; }
        .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) .bottom.arrow-wrapper {
          transform: translateX(0); }
        .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) > .flex-row .portfolio-description {
          padding-right: 23.9vw;
          padding-left: 0; } }
  .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) .bottom.arrow-wrapper {
    transform: translateX(16px); }
  .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) > .flex-row .portfolio-description {
    text-align: right;
    order: -1; }
  .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) .bottom.arrow-wrapper {
    justify-content: flex-end; }
  .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) .caption-left-line {
    padding-right: 20px; }
    .portfolio-home .portfolio__list .portfolio__item:nth-child(2n) .caption-left-line:after {
      right: 0;
      left: unset; }
  .portfolio-home .portfolio__list .caption {
    margin-bottom: 30px; }
    @media only screen and (max-width: 1680px) {
      .portfolio-home .portfolio__list .caption {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 1300px) {
      .portfolio-home .portfolio__list .caption {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 991px) {
      .portfolio-home .portfolio__list .caption {
        margin-bottom: 24px; } }
    @media only screen and (max-width: 767px) {
      .portfolio-home .portfolio__list .caption {
        margin-bottom: 21px; } }
  .portfolio-home .portfolio__list .portfolio-description {
    display: flex;
    flex-direction: column; }
    .portfolio-home .portfolio__list .portfolio-description .text-default {
      margin-bottom: 40px; }
    .portfolio-home .portfolio__list .portfolio-description .arrow-wrapper {
      margin-top: auto; }

.portfolio {
  border-top: 1px solid transparent; }
  .portfolio .content {
    padding-top: 0 !important; }
  .portfolio .flex-row {
    justify-content: space-between; }
  .portfolio .see-all {
    margin-top: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .portfolio .see-all a {
      opacity: 0.8;
      color: #d4eaf7;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 4px;
      text-decoration: none;
      display: flex;
      align-items: center; }
      .portfolio .see-all a:hover {
        opacity: 0.4; }
      .portfolio .see-all a span {
        display: flex;
        width: 100%;
        width: 49px;
        height: 9px;
        opacity: 0.4;
        margin-left: 10px; }
        .portfolio .see-all a span svg {
          fill: #d4eaf7; }
  .portfolio .portfolio__list {
    padding-left: 0;
    margin: 0;
    list-style: none;
    padding-top: 80px; }
    .portfolio .portfolio__list .portfolio-gallery-wrapper {
      font-size: 0;
      box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
      width: 100%;
      width: 36.75vw;
      flex-shrink: 0; }
      @media only screen and (max-width: 1680px) {
        .portfolio .portfolio__list .portfolio-gallery-wrapper {
          width: 51.8vw; } }
      @media only screen and (max-width: 1300px) {
        .portfolio .portfolio__list .portfolio-gallery-wrapper {
          width: 42vw; } }
      @media only screen and (max-width: 991px) {
        .portfolio .portfolio__list .portfolio-gallery-wrapper {
          width: 56vw; } }
      @media only screen and (max-width: 767px) {
        .portfolio .portfolio__list .portfolio-gallery-wrapper {
          width: 100%; } }
    .portfolio .portfolio__list .slick-slide img {
      width: 100%; }
    .portfolio .portfolio__list .portfolio__item {
      margin-bottom: 240px; }
      .portfolio .portfolio__list .portfolio__item:last-child {
        margin-bottom: 0; }
      .portfolio .portfolio__list .portfolio__item .bottom.arrow-wrapper {
        transform: translateX(-16px); }
      @media only screen and (max-width: 767px) {
        .portfolio .portfolio__list .portfolio__item {
          margin-bottom: 170px;
          position: relative;
          padding-bottom: 100px; }
          .portfolio .portfolio__list .portfolio__item .bottom.arrow-wrapper {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0; }
          .portfolio .portfolio__list .portfolio__item > .flex-row {
            flex-direction: column; }
            .portfolio .portfolio__list .portfolio__item > .flex-row .portfolio-description {
              padding-left: 23.9vw;
              width: 100%;
              order: -1;
              margin-bottom: 42px; }
          .portfolio .portfolio__list .portfolio__item:nth-child(2n) .bottom.arrow-wrapper {
            transform: translateX(0); }
          .portfolio .portfolio__list .portfolio__item:nth-child(2n) > .flex-row .portfolio-description {
            padding-right: 23.9vw;
            padding-left: 0; } }
    .portfolio .portfolio__list .portfolio__item:nth-child(2n) .bottom.arrow-wrapper {
      transform: translateX(16px); }
    .portfolio .portfolio__list .portfolio__item:nth-child(2n) > .flex-row .portfolio-description {
      text-align: right;
      order: -1; }
    .portfolio .portfolio__list .portfolio__item:nth-child(2n) .bottom.arrow-wrapper {
      justify-content: flex-end; }
    .portfolio .portfolio__list .portfolio__item:nth-child(2n) .caption-left-line {
      padding-right: 20px; }
      .portfolio .portfolio__list .portfolio__item:nth-child(2n) .caption-left-line:after {
        right: 0;
        left: unset; }
    .portfolio .portfolio__list .caption {
      margin-bottom: 30px; }
      @media only screen and (max-width: 1680px) {
        .portfolio .portfolio__list .caption {
          margin-bottom: 27px; } }
      @media only screen and (max-width: 1300px) {
        .portfolio .portfolio__list .caption {
          margin-bottom: 27px; } }
      @media only screen and (max-width: 991px) {
        .portfolio .portfolio__list .caption {
          margin-bottom: 24px; } }
      @media only screen and (max-width: 767px) {
        .portfolio .portfolio__list .caption {
          margin-bottom: 21px; } }
    .portfolio .portfolio__list .portfolio-description {
      display: flex;
      flex-direction: column; }
      .portfolio .portfolio__list .portfolio-description .text-default {
        margin-bottom: 40px; }
      .portfolio .portfolio__list .portfolio-description .arrow-wrapper {
        margin-top: auto; }

.copyright {
  padding-bottom: 60px;
  margin-top: 25px; }
  @media only screen and (max-width: 767px) {
    .copyright {
      margin-top: 30px;
      padding-bottom: 20px; } }
  .copyright .container .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px;
    letter-spacing: 1.4px; }
    .copyright .container .content a {
      font-size: 14px;
      letter-spacing: 1.4px; }
    @media only screen and (max-width: 1299px) {
      .copyright .container .content {
        font-size: 14px; }
        .copyright .container .content a {
          font-size: 14px;
          letter-spacing: 1px; } }
    @media only screen and (max-width: 991px) {
      .copyright .container .content {
        font-size: 12px;
        letter-spacing: 1px; }
        .copyright .container .content a {
          font-size: 12px;
          letter-spacing: 1px; } }
    @media only screen and (max-width: 767px) {
      .copyright .container .content {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left !important;
        font-size: 12px; }
        .copyright .container .content a {
          font-size: 12px; }
        .copyright .container .content > div {
          padding-left: 0px;
          padding-right: 0px;
          margin-bottom: 8px;
          width: unset; }
        .copyright .container .content > div:last-child {
          padding-bottom: 0px;
          margin-top: 24px; } }
    .copyright .container .content .logo-caption {
      font-weight: 300;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 2px;
      opacity: 0.5;
      color: #d4eaf7;
      transform: translateX(3px); }
      .copyright .container .content .logo-caption span {
        display: inline-block;
        width: 110px;
        text-align: center; }
        @media only screen and (max-width: 1680px) {
          .copyright .container .content .logo-caption span {
            width: 110px; } }
        @media only screen and (max-width: 991px) {
          .copyright .container .content .logo-caption span {
            width: 87px;
            letter-spacing: 1px;
            font-size: 11px;
            line-height: 1; } }
        @media only screen and (max-width: 767px) {
          .copyright .container .content .logo-caption span {
            display: none; } }
      @media only screen and (max-width: 991px) {
        .copyright .container .content .logo-caption {
          letter-spacing: 1px; } }
      @media only screen and (max-width: 767px) {
        .copyright .container .content .logo-caption {
          transform: translateX(3px);
          letter-spacing: 1px; } }
    .copyright .container .content .copyright__copy {
      color: rgba(212, 234, 247, 0.2); }
      @media only screen and (max-width: 991px) {
        .copyright .container .content .copyright__copy {
          margin-left: 0; } }
    @media only screen and (max-width: 991px) {
      .copyright .container .content .copyright__terms {
        text-align: right; } }
    @media only screen and (max-width: 767px) {
      .copyright .container .content .copyright__terms {
        text-align: left; } }
    .copyright .container .content .copyright__terms a {
      color: rgba(212, 234, 247, 0.2);
      text-decoration: none; }
      .copyright .container .content .copyright__terms a:hover {
        text-decoration: none;
        color: rgba(212, 234, 247, 0.2);
        opacity: 0.4; }
    @media only screen and (max-width: 991px) {
      .copyright .container .content .copyright__created {
        flex-grow: 1;
        text-align: right; } }
    .copyright .container .content .copyright__created a {
      text-decoration: none;
      color: rgba(212, 234, 247, 0.2); }
      .copyright .container .content .copyright__created a:hover {
        text-decoration: none; }
        .copyright .container .content .copyright__created a:hover span {
          color: rgba(212, 234, 247, 0.2);
          text-decoration: none; }
    .copyright .container .content .copyright__created span {
      color: rgba(212, 234, 247, 0.2);
      text-decoration: none; }

.type-lights .content {
  padding-top: 0 !important; }

.type-lights .type-lights__list {
  padding-left: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: calc(10.5vw); }
  @media only screen and (max-width: 1680px) {
    .type-lights .type-lights__list {
      padding-left: 14.8vw; } }
  @media only screen and (max-width: 1300px) {
    .type-lights .type-lights__list {
      padding-left: 12vw; } }
  @media only screen and (max-width: 991px) {
    .type-lights .type-lights__list {
      padding-left: 16vw; } }
  @media only screen and (max-width: 767px) {
    .type-lights .type-lights__list {
      padding-left: 0; } }

.type-lights .svg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  animation: opacity 4s linear infinite;
  z-index: 5; }

.type-lights .image-wrapper {
  position: relative;
  box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25); }

.type-lights .type-lights__item {
  margin-top: 200px; }
  .type-lights .type-lights__item img {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .type-lights .type-lights__item {
      margin-left: 23.9vw;
      width: 71.7vw;
      margin-top: 0;
      margin-bottom: 80px; }
      .type-lights .type-lights__item:nth-child(2n) {
        margin-left: 0px; }
      .type-lights .type-lights__item:last-child {
        margin-bottom: 0; } }
  .type-lights .type-lights__item:nth-child(2n) {
    margin-top: 0px; }

.type-lights .caption {
  padding-left: 25px;
  position: relative;
  margin-bottom: 30px; }
  @media only screen and (max-width: 1680px) {
    .type-lights .caption {
      margin-bottom: 27px; } }
  @media only screen and (max-width: 1300px) {
    .type-lights .caption {
      margin-bottom: 27px; } }
  @media only screen and (max-width: 991px) {
    .type-lights .caption {
      margin-bottom: 24px; } }
  @media only screen and (max-width: 767px) {
    .type-lights .caption {
      margin-bottom: 21px; } }
  .type-lights .caption:after {
    content: '';
    position: absolute;
    width: 1px;
    box-shadow: 2px 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 1px 1px rgba(0, 189, 197, 0.35);
    background-color: #039ea4;
    height: 80%;
    left: 0;
    top: 10%; }

.type-lights .text-default {
  margin-bottom: 55px; }
  @media only screen and (max-width: 1680px) {
    .type-lights .text-default {
      margin-bottom: 49.5px; } }
  @media only screen and (max-width: 1300px) {
    .type-lights .text-default {
      margin-bottom: 49.5px; } }
  @media only screen and (max-width: 991px) {
    .type-lights .text-default {
      margin-bottom: 44px; } }
  @media only screen and (max-width: 767px) {
    .type-lights .text-default {
      margin-bottom: 38.5px; } }

.up_down {
  opacity: 0;
  transform: translate3d(0, 0, 0) translateY(40px);
  transition: all 1200ms;
  transition-delay: 0.5s; }

.type-lights__list.scrolled .up_down {
  transform: translateY(0%);
  opacity: 1; }

@media only screen and (max-width: 767px) {
  .footer {
    margin-bottom: 60px; } }

.footer a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 2.4px;
  color: rgba(212, 234, 247, 0.4); }
  @media only screen and (max-width: 1300px) {
    .footer a {
      font-size: 14px;
      letter-spacing: 2.1px; } }
  @media only screen and (max-width: 991px) {
    .footer a {
      font-size: 14px;
      letter-spacing: 1px; } }
  @media only screen and (max-width: 767px) {
    .footer a {
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 24px; } }

.footer .content {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.footer .footer__item-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .footer .footer__item-row {
      align-items: flex-start;
      padding-bottom: 20px; } }
  .footer .footer__item-row:first-child {
    margin-top: 10px; }
  .footer .footer__item-row .logo {
    margin-bottom: 10px; }
    .footer .footer__item-row .logo span {
      display: none; }
      @media only screen and (max-width: 767px) {
        .footer .footer__item-row .logo span {
          display: block;
          text-align: center; } }
    .footer .footer__item-row .logo img {
      max-width: 100%; }
  .footer .footer__item-row .left {
    width: 110px;
    text-align: center;
    flex-shrink: 0;
    margin-right: calc(10.5vw  - 110px); }
    @media only screen and (max-width: 1680px) {
      .footer .footer__item-row .left {
        margin-right: calc(14.8vw - 110px); } }
    @media only screen and (max-width: 1300px) {
      .footer .footer__item-row .left {
        margin-right: calc(12vw  - 110px); } }
    @media only screen and (max-width: 991px) {
      .footer .footer__item-row .left {
        margin-right: calc(16vw - 67px);
        width: 67px; } }
    @media only screen and (max-width: 767px) {
      .footer .footer__item-row .left {
        width: 23.9vw;
        margin-left: 0px;
        margin-right: 23.9vw;
        margin-bottom: 0;
        text-align: left; } }

.footer .contacts {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start; }
  @media only screen and (max-width: 767px) {
    .footer .contacts {
      display: block; }
      .footer .contacts .first {
        margin-top: 20px; } }
  .footer .contacts .contacts__item-col {
    display: block; }
    .footer .contacts .contacts__item-col.first > div:first-child {
      min-width: calc(10.5vw * 2); }
    .footer .contacts .contacts__item-col.last > div {
      min-width: 10.5vw; }
    @media only screen and (max-width: 1300px) {
      .footer .contacts .contacts__item-col.first > div:first-child {
        min-width: calc(12vw * 2); }
      .footer .contacts .contacts__item-col.last > div {
        min-width: 12vw; } }
    @media only screen and (max-width: 767px) {
      .footer .contacts .contacts__item-col {
        width: unset; }
        .footer .contacts .contacts__item-col.first > div:first-child {
          margin-bottom: 20px; } }

.footer .footer__item-col.social {
  text-align: left; }
  @media only screen and (max-width: 767px) {
    .footer .footer__item-col.social {
      position: absolute;
      bottom: 0; } }

.footer .social__item {
  display: inline-flex;
  height: 16px;
  width: 16px;
  margin-right: 35px; }
  @media only screen and (max-width: 1299px) {
    .footer .social__item {
      margin-right: 30px; } }
  @media only screen and (max-width: 991px) {
    .footer .social__item {
      width: 11px;
      height: 11px;
      margin-right: 25px; } }
  .footer .social__item:hover {
    transition: all 0.2s;
    opacity: 0.4; }
  .footer .social__item:last-child {
    margin-right: 0; }
  .footer .social__item svg {
    fill: rgba(212, 234, 247, 0.4); }

.form-callback {
  display: none;
  width: 100%; }
  .form-callback.active {
    display: block;
    padding-top: 5vh; }
  .form-callback .form-item {
    margin-bottom: 30px; }
  .form-callback .header_1,
  .form-callback .header_1-small {
    margin-bottom: 40px; }
  .form-callback .button-wrapper:after {
    left: 25%;
    background-image: linear-gradient(-216deg, rgba(7, 26, 30, 0.09) 0%, rgba(100, 240, 254, 0.23) 50%, rgba(13, 35, 39, 0) 100%); }
  .form-callback button {
    margin: 40px auto 0; }

.form-desc .content {
  padding-top: 0 !important; }

@media only screen and (max-width: 767px) {
  .form-desc .flex-row {
    flex-direction: column; } }

.form-desc .description {
  padding-top: 10px;
  margin-top: 99px; }
  @media only screen and (max-width: 1299px) {
    .form-desc .description {
      margin-top: 80px; } }
  @media only screen and (max-width: 991px) {
    .form-desc .description {
      margin-top: 70px; } }
  @media only screen and (max-width: 767px) {
    .form-desc .description {
      margin-top: 60px; } }
  .form-desc .description > .caption {
    margin-bottom: 30px; }
    @media only screen and (max-width: 1680px) {
      .form-desc .description > .caption {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 1300px) {
      .form-desc .description > .caption {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 991px) {
      .form-desc .description > .caption {
        margin-bottom: 24px; } }
    @media only screen and (max-width: 767px) {
      .form-desc .description > .caption {
        margin-bottom: 21px; } }
    @media only screen and (max-width: 767px) {
      .form-desc .description > .caption {
        letter-spacing: 2px;
        padding-left: 11.95vw; } }
  .form-desc .description > .text-default {
    margin-bottom: 70px; }
    @media only screen and (max-width: 1680px) {
      .form-desc .description > .text-default {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 1300px) {
      .form-desc .description > .text-default {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 991px) {
      .form-desc .description > .text-default {
        margin-bottom: 56px; } }
    @media only screen and (max-width: 767px) {
      .form-desc .description > .text-default {
        margin-bottom: 49px; } }
    @media only screen and (max-width: 767px) {
      .form-desc .description > .text-default {
        padding-left: 11.95vw; } }
  .form-desc .description .flex-row {
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 991px) {
      .form-desc .description .flex-row {
        flex-direction: column; } }

.form-desc .form-item {
  margin-bottom: 30px; }
  @media only screen and (max-width: 1300px) {
    .form-desc .form-item {
      width: 231px; } }
  @media only screen and (max-width: 991px) {
    .form-desc .form-item {
      width: 309px; } }
  @media only screen and (max-width: 767px) {
    .form-desc .form-item {
      width: 100%; } }

@media only screen and (max-width: 767px) {
  .form-desc .form-group {
    width: 100%; } }

@media only screen and (max-width: 991px) {
  .form-desc .form-controll {
    display: flex;
    flex-direction: column; }
    .form-desc .form-controll .checkbox {
      order: -1; }
    .form-desc .form-controll .button {
      margin-bottom: 0; } }

.form-desc .checkbox {
  width: 10.5vw;
  flex-shrink: 0; }
  @media only screen and (max-width: 1680px) {
    .form-desc .checkbox {
      width: 14.8vw; } }
  @media only screen and (max-width: 1300px) {
    .form-desc .checkbox {
      width: 12vw; } }
  @media only screen and (max-width: 991px) {
    .form-desc .checkbox {
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .form-desc .checkbox {
      width: 100%; } }

.form-desc .button {
  margin-bottom: 55px; }
  @media only screen and (max-width: 1680px) {
    .form-desc .button {
      margin-bottom: 49.5px; } }
  @media only screen and (max-width: 1300px) {
    .form-desc .button {
      margin-bottom: 49.5px; } }
  @media only screen and (max-width: 991px) {
    .form-desc .button {
      margin-bottom: 44px; } }
  @media only screen and (max-width: 767px) {
    .form-desc .button {
      margin-bottom: 38.5px; } }

.gallery-header .header_1,
.gallery-header .header_1-small {
  text-align: left; }

.gallery-header .text-default {
  margin-bottom: 0; }

.gallery-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .gallery-content a {
    display: block;
    font-size: 0px;
    width: 21vw;
    margin-bottom: 10.5vw;
    line-height: 0;
    box-shadow: 0 8px 18px 6px transparent, 0 0 139px rgba(0, 189, 197, 0); }
    .gallery-content a:hover {
      box-shadow: 0 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 139px rgba(0, 189, 197, 0.15); }
    @media only screen and (max-width: 1680px) {
      .gallery-content a {
        width: 29.6vw;
        margin-bottom: 14.8vw; } }
    @media only screen and (max-width: 1300px) {
      .gallery-content a {
        width: 24vw;
        margin-bottom: 12vw; } }
    @media only screen and (max-width: 991px) {
      .gallery-content a {
        width: 32vw;
        margin-bottom: 16vw;
        box-shadow: none; }
        .gallery-content a:hover {
          box-shadow: none; } }
    @media only screen and (max-width: 767px) {
      .gallery-content a {
        width: 35.85vw;
        margin-bottom: 11.95vw; } }
    .gallery-content a:nth-child(4n) {
      margin-right: 10.5vw; }
      @media only screen and (max-width: 1680px) {
        .gallery-content a:nth-child(4n) {
          margin-right: 14.8vw; } }
      @media only screen and (max-width: 1300px) {
        .gallery-content a:nth-child(4n) {
          margin-right: 12vw; } }
      @media only screen and (max-width: 991px) {
        .gallery-content a:nth-child(4n) {
          margin-right: 16vw; } }
      @media only screen and (max-width: 767px) {
        .gallery-content a:nth-child(4n) {
          margin-right: 11.95vw; } }
    .gallery-content a:nth-child(4n+1) {
      margin-left: 10.5vw; }
      @media only screen and (max-width: 1680px) {
        .gallery-content a:nth-child(4n+1) {
          margin-left: 14.8vw; } }
      @media only screen and (max-width: 1300px) {
        .gallery-content a:nth-child(4n+1) {
          margin-left: 12vw; } }
      @media only screen and (max-width: 991px) {
        .gallery-content a:nth-child(4n+1) {
          margin-left: 16vw; } }
      @media only screen and (max-width: 767px) {
        .gallery-content a:nth-child(4n+1) {
          margin-left: 11.95vw; } }
    .gallery-content a span {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-size: 0; }
    .gallery-content a img {
      width: 100%;
      height: auto;
      margin: 0 !important; }

.header {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 10; }
  .header .container {
    padding-left: 3px;
    padding-right: 0; }
  .header__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .header__content {
        justify-content: center;
        flex-direction: column; } }
  .header .header__item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .header .header__item-row:first-child {
      margin-top: 10px; }
    .header .header__item-row .logo {
      margin-bottom: 10px; }
      .header .header__item-row .logo img {
        max-width: 100%; }
    .header .header__item-row .left {
      width: 160px;
      text-align: center;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      margin-right: calc(10.5vw * 2 - 160px); }
      @media only screen and (max-width: 1680px) {
        .header .header__item-row .left {
          margin-right: calc(14.8vw * 2 - 160px); } }
      @media only screen and (max-width: 1300px) {
        .header .header__item-row .left {
          margin-right: calc(12vw * 2 - 160px); } }
      @media only screen and (max-width: 991px) {
        .header .header__item-row .left {
          margin-right: calc(16vw * 2 - 120px);
          width: 120px; } }
      @media only screen and (max-width: 767px) {
        .header .header__item-row .left {
          width: calc(23.9vw - 5px);
          margin-left: 2px;
          margin-bottom: 0; } }
      .header .header__item-row .left span {
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 6px;
        opacity: 0.5;
        color: #d4eaf7;
        display: inline-block;
        transform: translateX(3px); }
        @media only screen and (max-width: 991px) {
          .header .header__item-row .left span {
            font-size: 14px;
            letter-spacing: 1px; } }
        @media only screen and (max-width: 767px) {
          .header .header__item-row .left span {
            font-size: 12px;
            transform: translateX(3px);
            letter-spacing: 1px; } }
    .header .header__item-row .right {
      flex-grow: 1; }
  .header .contacts {
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .header .contacts {
        display: none; } }
    @media only screen and (max-width: 991px) {
      .header .contacts .contacts__item {
        margin-bottom: 10px; } }
    .header .contacts .contacts__item-col {
      display: flex; }
      .header .contacts .contacts__item-col.first > div:first-child {
        min-width: calc(10.5vw * 2); }
      .header .contacts .contacts__item-col.last > div {
        min-width: 10.5vw; }
      @media only screen and (max-width: 1680px) {
        .header .contacts .contacts__item-col {
          display: block; } }
      @media only screen and (max-width: 1300px) {
        .header .contacts .contacts__item-col {
          display: flex; }
          .header .contacts .contacts__item-col.first > div:first-child {
            min-width: calc(12vw * 2); }
          .header .contacts .contacts__item-col.last > div {
            min-width: 12vw; } }
      @media only screen and (max-width: 1200px) {
        .header .contacts .contacts__item-col {
          display: block; } }
      @media only screen and (max-width: 991px) {
        .header .contacts .contacts__item-col.last {
          margin-right: 16vw; } }
    .header .contacts a {
      color: #d4eaf7;
      text-decoration: none;
      opacity: 0.4;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: 2.4px; }
      @media only screen and (max-width: 1300px) {
        .header .contacts a {
          opacity: 0.5;
          font-size: 14px;
          letter-spacing: 2.1px; } }
  .header__item:not(.logo) {
    flex-grow: 1;
    margin-left: 240px; }
  .header__item.logo img {
    display: block;
    margin-right: auto;
    max-width: 100%; }
    @media only screen and (max-width: 767px) {
      .header__item.logo img {
        margin: 0px; } }
  @media only screen and (max-width: 1299px) {
    .header__content {
      padding-right: 0;
      padding-left: 0; } }
  @media only screen and (max-width: 991px) {
    .header__content {
      flex-wrap: wrap;
      align-items: center;
      align-content: center; }
    .header__item {
      width: 50%; }
      .header__item:nth-child(1), .header__item:nth-child(2) {
        margin-bottom: 20px; }
      .header__item.separator {
        display: none; } }
  @media only screen and (max-width: 767px) {
    .header__item {
      width: auto;
      text-align: center; }
      .header__item:not(:last-child) {
        margin-bottom: 20px; } }

body:not(.home-page) .header {
  position: static;
  margin-bottom: 145px; }
  @media only screen and (max-width: 991px) {
    body:not(.home-page) .header {
      margin-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    body:not(.home-page) .header {
      margin-bottom: 50px; } }

.heroimage {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  overflow: hidden;
  color: #d4eaf7; }
  .heroimage .container {
    position: relative;
    z-index: 4; }
  .heroimage__content {
    min-height: 100vh;
    padding-top: 29vh;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative; }
    @media only screen and (max-width: 1299px) {
      .heroimage__content {
        padding-top: 27vh; } }
    @media only screen and (max-width: 991px) {
      .heroimage__content {
        padding-top: 25vh; } }
    @media only screen and (max-width: 767px) {
      .heroimage__content {
        padding-top: 18vh;
        flex-direction: column; } }
  .heroimage ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    color: #d4eaf7; }
  .heroimage .city-list {
    opacity: 0.8;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.9;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    padding-left: 1vw; }
    .heroimage .city-list:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 90%;
      box-shadow: 2px 8px 20px 8px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 189, 197, 0.5);
      background-color: #039ea4;
      top: 5%;
      left: 0; }
    @media only screen and (max-width: 1680px) {
      .heroimage .city-list {
        letter-spacing: 4px; } }
    @media only screen and (max-width: 991px) {
      .heroimage .city-list {
        font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .heroimage .city-list {
        padding-left: 15px; } }
  .heroimage .types-light {
    opacity: 0.8;
    font-size: 25px;
    font-weight: 300;
    line-height: 60px;
    text-transform: uppercase;
    letter-spacing: 6.25px;
    position: relative; }
    .heroimage .types-light .types-light__item {
      overflow: hidden; }
    .heroimage .types-light .active p span {
      transform: translate3d(0, 0, 0); }
    .heroimage .types-light p {
      margin: 0;
      display: inline-block; }
    .heroimage .types-light p span {
      display: inline-block;
      transform: translate3d(0, 100%, 0);
      transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
    .heroimage .types-light:after {
      content: '';
      width: 20vw;
      height: 20vw;
      position: absolute;
      top: 50%;
      left: 200px;
      border-radius: 50%;
      transform: translate3d(-1vw, -5vw, 0);
      z-index: -1;
      background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, rgba(9, 30, 35, 0.21) 54%, rgba(7, 26, 30, 0.21) 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%);
      opacity: 0; }
    @media only screen and (max-width: 1680px) {
      .heroimage .types-light {
        color: #ffffff;
        font-size: 22px;
        line-height: 2.5;
        letter-spacing: 5.5px; }
        .heroimage .types-light:after {
          width: 20vw;
          height: 20vw;
          position: absolute;
          top: 50%;
          left: 230px;
          border-radius: 50%;
          transform: translate3d(-10vw, -10vw, 0);
          opacity: 0.5; } }
    @media only screen and (max-width: 1300px) {
      .heroimage .types-light:after {
        width: 26vw;
        height: 26vw;
        position: absolute;
        top: 50%;
        left: 250px;
        border-radius: 50%;
        transform: translate3d(-13vw, -13vw, 0);
        opacity: 0.5; } }
    @media only screen and (max-width: 991px) {
      .heroimage .types-light {
        font-size: 20px;
        letter-spacing: 5px; } }
    @media only screen and (max-width: 767px) {
      .heroimage .types-light {
        font-size: 16px; }
        .heroimage .types-light:after {
          width: 240px;
          height: 240px;
          position: absolute;
          top: 50%;
          left: 10px;
          border-radius: 50%;
          transform: translate3d(0, -120px, 0);
          /* Style for "Эллипс 1" */ } }
  .heroimage .col-left {
    width: calc(10.5vw * 6); }
    @media only screen and (max-width: 1680px) {
      .heroimage .col-left {
        width: calc(14.8vw * 4); } }
    @media only screen and (max-width: 1300px) {
      .heroimage .col-left {
        width: calc(12vw * 5); } }
    @media only screen and (max-width: 991px) {
      .heroimage .col-left {
        width: calc(16vw * 4); } }
    @media only screen and (max-width: 767px) {
      .heroimage .col-left {
        width: calc(23.9vw * 2); } }
  @media only screen and (max-width: 767px) {
    .heroimage .col-right {
      margin-top: -31px;
      margin-bottom: 25px;
      margin-left: calc(23.9vw * 2); } }
  .heroimage .col-bottom {
    width: 100%;
    margin-top: auto;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .heroimage .col-bottom {
        margin-top: 0;
        display: none; } }
  .heroimage .social-media {
    display: flex; }
    .heroimage .social-media li {
      min-width: 10.5vw; }
      @media only screen and (max-width: 1680px) {
        .heroimage .social-media li {
          min-width: 14.8vw; } }
      @media only screen and (max-width: 1300px) {
        .heroimage .social-media li {
          min-width: 12vw; } }
    .heroimage .social-media a {
      text-decoration: none;
      opacity: 0.5;
      color: #9fbdce;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 2.4px; }
      @media only screen and (max-width: 1300px) {
        .heroimage .social-media a {
          font-size: 14px; } }
      @media only screen and (max-width: 991px) {
        .heroimage .social-media a {
          font-size: 12px; } }
  .heroimage .verticalline {
    position: absolute;
    right: 0;
    bottom: 40px;
    top: 29vh;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    text-transform: uppercase;
    opacity: 0.8;
    text-shadow: 1px 5px 3px rgba(0, 0, 0, 0.18);
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 4.8px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .heroimage .verticalline span {
      height: 49px;
      width: 9px;
      display: flex;
      opacity: 0.9; }
      .heroimage .verticalline span svg {
        fill: #ffffff; }
    @media only screen and (max-width: 1680px) {
      .heroimage .verticalline {
        font-size: 14px;
        letter-spacing: 4.2px; } }
    @media only screen and (max-width: 991px) {
      .heroimage .verticalline {
        font-size: 12px; } }
    @media only screen and (max-width: 1299px) {
      .heroimage .verticalline {
        top: 27vh; } }
    @media only screen and (max-width: 991px) {
      .heroimage .verticalline {
        top: 25vh; } }
    @media only screen and (max-width: 767px) {
      .heroimage .verticalline {
        display: none; } }
  .heroimage .house {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(10%);
    width: 73vw;
    z-index: 1; }
    .heroimage .house:after {
      content: '';
      width: 23vw;
      height: 23vw;
      position: absolute;
      top: 50%;
      left: 200px;
      border-radius: 50%;
      opacity: .5;
      transform: translate3d(-20vw, -14vw, 0);
      z-index: -1;
      background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, rgba(9, 30, 35, 0.21) 54%, rgba(7, 26, 30, 0.21) 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%); }
    @media only screen and (max-width: 1680px) {
      .heroimage .house:after {
        display: none; } }
    .heroimage .house.active .svg-wrapper {
      animation: light 6s linear infinite; }
    .heroimage .house.active .image-wrapper {
      opacity: 1; }
    .heroimage .house.active img {
      filter: grayscale(0%); }
    @media only screen and (max-width: 991px) {
      .heroimage .house {
        left: 0;
        width: 110%;
        transform: translateX(-10vw); } }
    @media only screen and (max-width: 767px) {
      .heroimage .house {
        left: 0;
        width: 130vw;
        transform: translateX(-30vw); } }
    .heroimage .house .image-wrapper {
      opacity: 0;
      transition: all 2s;
      position: relative;
      z-index: 1; }
    .heroimage .house img {
      filter: grayscale(100%);
      transition: all 2s;
      transition-delay: 0.5s;
      max-width: 100%; }
    .heroimage .house .svg-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 1s;
      z-index: 5;
      opacity: 0; }
  .heroimage .header_1,
  .heroimage .header_1-small {
    opacity: 0.8;
    font-size: 90px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 12.75px;
    text-align: left;
    margin-bottom: 12vh;
    overflow: hidden; }
    .heroimage .header_1 p, .heroimage .header_1 span,
    .heroimage .header_1-small p,
    .heroimage .header_1-small span {
      display: inline-block;
      margin: 0; }
    .heroimage .header_1 span,
    .heroimage .header_1-small span {
      transform: translatey(100%);
      transition: all 0.4s;
      will-change: transform; }
    .heroimage .header_1.active span,
    .heroimage .header_1-small.active span {
      transform: translatey(0%); }
    .heroimage .header_1.done span,
    .heroimage .header_1-small.done span {
      will-change: unset; }
    @media only screen and (max-width: 1680px) {
      .heroimage .header_1,
      .heroimage .header_1-small {
        font-size: 70px;
        letter-spacing: 9.92px;
        margin-bottom: 8vh; } }
    @media only screen and (max-width: 991px) {
      .heroimage .header_1,
      .heroimage .header_1-small {
        font-size: 50px; } }
    @media only screen and (max-width: 767px) {
      .heroimage .header_1,
      .heroimage .header_1-small {
        font-size: 25px;
        margin-bottom: 7vh; } }
  .heroimage .text-default {
    margin-bottom: 0;
    color: #d4eaf7;
    text-align: left; }
  .heroimage .button-wrapper {
    position: relative;
    margin-top: 140px; }
    @media only screen and (max-width: 767px) {
      .heroimage .button-wrapper {
        margin-top: 10vh; }
        .heroimage .button-wrapper:after {
          width: 240px;
          height: 240px;
          transform: translate3d(-120px, -120px, 0); } }
    .heroimage .button-wrapper:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      z-index: -5;
      background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, #091e23 54%, #071a1e 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%);
      opacity: 0.5; }

@media only screen and (min-width: 992px) {
  .menu {
    background: transparent; }
    .menu .container {
      background: #d4eaf7; }
    .menu .social-menu {
      display: none; }
    .menu__list {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
      list-style-type: none; }
      .menu__list p {
        margin: 0;
        display: inline-block; }
      .menu__list > .menu__list_item {
        position: relative;
        z-index: 12;
        flex-grow: 1;
        height: auto;
        text-align: left;
        min-width: 10.5vw; }
        .menu__list > .menu__list_item span {
          will-change: transform;
          display: inline-block;
          transition: all 1200ms cubic-bezier(0.19, 1, 0.22, 1); }
        .menu__list > .menu__list_item.first a {
          padding-left: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1680px) {
        .menu__list > .menu__list_item {
          min-width: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1300px) {
        .menu__list > .menu__list_item {
          min-width: 12vw; } }

@media only screen and (min-width: 992px) {
        .menu__list > .menu__list_item:hover span {
          transform: rotateX(360deg); }
        .menu__list > .menu__list_item:hover.parent:not([data-type="msCategory"]) > a:not([hidechild]) + .menu__list-inner {
          display: block; }
        .menu__list > .menu__list_item.active > a {
          opacity: 0.2; }
        .menu__list > .menu__list_item > a {
          height: auto;
          padding: 0 0px;
          color: #d4eaf7;
          font-size: 16px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 300;
          text-decoration: none;
          text-transform: uppercase;
          line-height: auto;
          background: transparent;
          /* Style for "О нас" */ } }
        @media only screen and (min-width: 992px) and (max-width: 1300px) {
          .menu__list > .menu__list_item > a {
            font-size: 14px;
            letter-spacing: 2.8px; } }

@media only screen and (min-width: 992px) {
        .menu__list > .menu__list_item a {
          display: block;
          opacity: 0.4;
          letter-spacing: 3.2px; }
          .menu__list > .menu__list_item a[noclick] {
            cursor: default; }
      .menu__list-inner {
        position: absolute;
        top: auto;
        left: 0;
        z-index: 10;
        display: none;
        width: 300px;
        margin: 0;
        padding-left: 0;
        list-style-type: none; }
        .menu__list-inner .menu__list_item.parent {
          position: relative; }
          .menu__list-inner .menu__list_item.parent ul {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: all .3s ease-in-out; }
          .menu__list-inner .menu__list_item.parent:hover > ul {
            left: 300px;
            visibility: visible;
            opacity: 1; }
        .menu__list-inner .menu__list_item.active > a {
          font-weight: 600;
          background: #aaa; }
        .menu__list-inner .menu__list_item a {
          position: relative;
          z-index: 11;
          text-align: left;
          width: 100%;
          padding: 12px 20px 10px 10px;
          color: #d4eaf7;
          font-size: 18px;
          font-family: "Roboto Condensed", sans-serif;
          text-decoration: none;
          background: #5f5; }
          .menu__list-inner .menu__list_item a:hover {
            font-weight: 600;
            background: #aaa; }
        .menu__list-inner .menu__list_item:not(:first-child) > a::before {
          position: absolute;
          top: 0;
          left: 20px;
          width: 260px;
          height: 1px;
          background: #d4eaf7;
          content: ''; }
        .menu__list-inner .menu__list_item:not(:last-child) > a::after {
          position: absolute;
          bottom: 0;
          left: 20px;
          width: 260px;
          height: 1px;
          background: #d4eaf7;
          content: ''; } }

@media only screen and (min-width: 992px) {
  .menu__wrapper, .menu__toggle, .menu__back, .menu__nav,
  .menu .hidden,
  .menu .button {
    display: none; } }

@media only screen and (max-width: 991px) {
  .menu__bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    visibility: hidden;
    display: none; }
  .menu .social-menu {
    display: block;
    margin-top: auto; }
    .menu .social-menu .social-media {
      display: flex; }
      .menu .social-menu .social-media > div {
        margin-right: 60px; }
  .menu__toggle {
    display: block;
    position: fixed;
    top: 40px;
    right: 15px;
    z-index: 30;
    border: none;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: transparent; } }
  @media only screen and (max-width: 991px) and (max-width: 767px) {
    .menu__toggle {
      right: 15px;
      top: 20px; } }

@media only screen and (max-width: 991px) {
    .menu__toggle_item {
      width: 100%;
      height: 1px;
      position: absolute;
      transition: all .4s;
      background-color: rgba(212, 234, 247, 0.4); }
      .menu__toggle_item:nth-child(1) {
        top: 0;
        transform-origin: left bottom; }
      .menu__toggle_item:nth-child(2) {
        bottom: left top; }
  .menu__list {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: 21;
    transform: translateX(-100%);
    margin: 0;
    padding: 10vh 0 5vh 10vw;
    transition: all 1s;
    overflow-y: auto;
    overflow-x: hidden;
    background: #031c21;
    will-change: transform;
    background: url("/app/images/pattern.jpg");
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .menu__list > li {
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 1s; }
    .menu__list li {
      width: 137px;
      list-style-type: none;
      font-size: 18px;
      background-color: transparent;
      text-align: left; }
      .menu__list li.active a {
        opacity: 0.4; }
      .menu__list li.open > ul {
        right: 0;
        width: 100%;
        transition: all .4s ease-out; }
      .menu__list li a {
        position: relative;
        display: inline-block;
        padding: 15px 0 15px 00px;
        line-height: 20px;
        color: rgba(212, 234, 247, 0.4);
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 2.8px; }
    .menu__list ul {
      position: absolute;
      top: 0;
      right: -100vw;
      bottom: 0;
      width: 100%;
      margin: 0;
      padding-left: 0;
      background: #5f5;
      transition: all .4s ease-in; }
    .menu__list > li > a {
      text-transform: uppercase; }
    .menu__list > li > ul {
      z-index: 22; }
      .menu__list > li > ul > li > ul {
        z-index: 23; }
  .menu.active .menu__list {
    transform: translateX(0); }
    .menu.active .menu__list > li {
      opacity: 1; }
  .menu.active .menu__bg {
    visibility: visible;
    opacity: 1; }
  .menu.active .menu__toggle_item {
    background-color: #aaa; }
    .menu.active .menu__toggle_item:nth-child(1) {
      top: 17%;
      left: 12%;
      transform: rotate(45deg); }
    .menu.active .menu__toggle_item:nth-child(2) {
      transform: rotate(-45deg); } }

@media only screen and (max-width: 767px) {
  .social-menu {
    width: 100% !important; }
  .social-media {
    width: 100% !important;
    margin-bottom: 35px; }
    .social-media > div {
      margin-right: 45px !important; } }

/*------------slider-----------*/
.slider {
  position: relative;
  transition: all 0s; }
  .slider * {
    transition: all 0s; }
  @media only screen and (max-width: 767px) {
    .slider .slider-content {
      display: none; } }
  .slider .slider-content-item {
    position: relative;
    width: 100%;
    height: 690px; }
    .slider .slider-content-item .silder-wrapper {
      color: white;
      width: 650px;
      margin-left: 300px;
      margin-top: 120px;
      background: transparent; }
      @media only screen and (max-width: 1299px) {
        .slider .slider-content-item .silder-wrapper {
          width: 300px;
          margin-left: 200px;
          margin-top: 100px; } }
      @media only screen and (max-width: 991px) {
        .slider .slider-content-item .silder-wrapper {
          width: 200px;
          margin-top: 120px;
          margin-left: 100px; } }
    @media only screen and (max-width: 1299px) {
      .slider .slider-content-item {
        height: 510px; } }
    @media only screen and (max-width: 991px) {
      .slider .slider-content-item {
        position: relative;
        width: 100%;
        height: 390px; } }
  .slider .replace-slider {
    display: none; }
    @media only screen and (max-width: 767px) {
      .slider .replace-slider {
        display: inline;
        max-width: 100%; } }
  .slider .slick-dots {
    display: none !important; }
  .slider .arrow {
    position: absolute;
    top: 50%;
    margin-top: -41px;
    z-index: 10; }
  .slider .arrow-next {
    right: calc((100% - 1240px) / 2); }
    @media only screen and (max-width: 1299px) {
      .slider .arrow-next {
        right: calc((100% - 930px) / 2); } }
    @media only screen and (max-width: 991px) {
      .slider .arrow-next {
        right: calc((100% - 710px) / 2); } }
  .slider .arrow-prev {
    left: calc((100% - 1240px) / 2); }
    @media only screen and (max-width: 1299px) {
      .slider .arrow-prev {
        left: calc((100% - 930px) / 2); } }
    @media only screen and (max-width: 991px) {
      .slider .arrow-prev {
        left: calc((100% - 710px) / 2); } }
  .slider .slider-image-static {
    display: none; }
    .slider .slider-image-static img {
      width: 100%;
      height: auto; }
    @media only screen and (max-width: 767px) {
      .slider .slider-image-static {
        display: block; } }

.slider-content:not(.slick-initialized) {
  display: none; }

.lg-sub-html,
.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45); }

@font-face {
  font-family: lg;
  src: url(../fonts/lg.eot?n1z373);
  src: url(../fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(../fonts/lg.woff?n1z373) format("woff"), url(../fonts/lg.ttf?n1z373) format("truetype"), url(../fonts/lg.svg?n1z373#lg) format("svg");
  font-weight: 400;
  font-style: normal; }

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: 0; }

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: .5; }

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }

.lg-actions .lg-next:before {
  content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }

.lg-actions .lg-prev:after {
  content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: -30px; } }

@-moz-keyframes lg-right-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: -30px; } }

@-ms-keyframes lg-right-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: -30px; } }

@keyframes lg-right-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: -30px; } }

@-webkit-keyframes lg-left-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: 30px; } }

@-moz-keyframes lg-left-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: 30px; } }

@-ms-keyframes lg-left-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: 30px; } }

@keyframes lg-left-end {
  0%,
  100% {
    left: 0; }
  50% {
    left: 30px; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end .3s;
  -o-animation: lg-right-end .3s;
  animation: lg-right-end .3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end .3s;
  -o-animation: lg-left-end .3s;
  animation: lg-left-end .3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  -webkit-transition: color .2s linear;
  -o-transition: color .2s linear;
  transition: color .2s linear; }

.lg-toolbar .lg-icon:hover {
  color: #FFF; }

.lg-toolbar .lg-close:after {
  content: "\e070"; }

.lg-toolbar .lg-download:after {
  content: "\e0f2"; }

.lg-sub-html {
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700; }

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-next,
.lg-prev,
.lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color .25s ease;
    -o-transition: border-color .25s ease;
    transition: border-color .25s ease; } }

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #a90707; }

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom .25s ease;
  -o-transition: bottom .25s ease;
  transition: bottom .25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }

.lg-outer .lg-toogle-thumb:hover,
.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff"; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url(../images/vimeo-play.png) no-repeat; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url(../images/vimeo-play.png) 0 -58px no-repeat; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: url(../images/video-play.png) no-repeat;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: .8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url(../images/youtube-play.png) no-repeat; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(../images/youtube-play.png) 0 -60px no-repeat; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 80ms ease 0s;
  -moz-transition: opacity 80ms ease 0s;
  -o-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s; }

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0; }

.lg-progress-bar.lg-start .lg-progress {
  width: 100%; }

.lg-show-autoplay .lg-progress-bar {
  opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }

#lg-actual-size:after {
  content: "\e033"; }

#lg-zoom-out {
  opacity: .5;
  pointer-events: none; }

#lg-zoom-out:after {
  content: "\e312"; }

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #fff inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity .15s ease 0s, -webkit-transform .15s ease 0s;
  -moz-transition: opacity .15s ease 0s, -moz-transform .15s ease 0s;
  -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
  transition: opacity .15s ease 0s, transform .15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow .3s ease 0s;
  -o-transition: box-shadow .3s ease 0s;
  transition: box-shadow .3s ease 0s;
  width: 12px; }

.lg-outer .lg-pager:focus,
.lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #fff inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  transition: visibility 0s linear .18s, opacity .18s linear 0s; }

.lg-outer.lg-dropdown-active #lg-dropdown-overlay,
.lg-outer.lg-dropdown-active .lg-dropdown {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -moz-transition: -moz-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  transition: transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s; }

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px; }

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0; }

.lg-outer .lg-dropdown > li:hover .lg-icon,
.lg-outer .lg-dropdown > li:hover a {
  color: #333; }

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px; }

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07); }

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle; }

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto; }

.lg-outer,
.lg-outer .lg,
.lg-outer .lg-inner {
  width: 100%;
  height: 100%; }

.lg-outer #lg-share {
  position: relative; }

.lg-outer #lg-share:after {
  content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.lg-outer.lg-visible {
  opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1; }

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%; }

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap; }

.lg-outer .lg-item {
  background: url(../images/loading.gif) center center no-repeat;
  display: none !important; }

.lg-outer.lg-css .lg-current,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide,
.lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important; }

.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.lg-outer .lg-img-wrap:before,
.lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px; }

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.lg-outer .lg-item.lg-complete {
  background-image: none; }

.lg-outer .lg-item.lg-current {
  z-index: 1060; }

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1; }

.lg-outer .lg-empty-html,
.lg-outer.lg-hide-download #lg-download {
  display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-backdrop.in {
  opacity: 1; }

.lg-css3.lg-no-trans .lg-current,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-prev-slide {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item,
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-current,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity .1s ease 0s;
  -moz-transition: opacity .1s ease 0s;
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.slick-slider {
  position: relative;
  display: block;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list,
.slick-track {
  transform: translate3d(0, 0, 0);
  transition: all 0s; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  position: relative;
  top: 0;
  left: 0; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-arrow {
  padding: 0; }
  .slick-arrow:focus {
    outline: none; }

.slick__arrow.slick-hidden {
  display: none; }

.slick-slide {
  float: left;
  min-height: 1px; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide:focus {
    outline: none;
    cursor: move; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-slide > img {
    display: block; }
  .slick-slide.dragging > img {
    pointer-events: none; }

.slick-dots button:focus {
  outline: none; }

body.loaded .uppered {
  transform: translateY(0%);
  opacity: 1; }

.uppered {
  opacity: 0;
  transform: translate3d(0, 0, 0) translateY(50%);
  transition: all 1200ms; }

*[data-scroll] {
  overflow: hidden; }
  *[data-scroll] .up {
    opacity: 0;
    transform: translate3d(0, 0, 0) translateY(40px);
    transition: all 1200ms;
    transition-delay: 0.5s; }

.scrolled {
  overflow: visible; }
  .scrolled .up {
    transform: translateY(0%);
    opacity: 1; }

.page_error {
  background: #031c21;
  background-image: url("/app/images/pattern.jpg");
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  align-content: center;
  min-height: 100vh; }

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 42vw; }
  @media only screen and (max-width: 1680px) {
    .not-found {
      width: 59.2vw; } }
  @media only screen and (max-width: 1300px) {
    .not-found {
      width: 48vw; } }
  @media only screen and (max-width: 991px) {
    .not-found {
      width: 64vw; } }
  @media only screen and (max-width: 767px) {
    .not-found {
      width: 95.6vw; } }
  .not-found h1 {
    align-self: flex-start;
    margin-bottom: 70px;
    position: relative;
    margin-left: 5.25vw; }
    @media only screen and (max-width: 1680px) {
      .not-found h1 {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 1300px) {
      .not-found h1 {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 991px) {
      .not-found h1 {
        margin-bottom: 56px; } }
    @media only screen and (max-width: 767px) {
      .not-found h1 {
        margin-bottom: 49px; } }
    @media only screen and (max-width: 840px) {
      .not-found h1 {
        margin-left: 7.4vw; } }
    @media only screen and (max-width: 1300px) {
      .not-found h1 {
        margin-left: 6vw; } }
    @media only screen and (max-width: 991px) {
      .not-found h1 {
        margin-left: 8vw; } }
    @media only screen and (max-width: 767px) {
      .not-found h1 {
        margin-left: 11.95vw; } }
  .not-found img {
    width: 100%;
    position: absolute;
    top: 0;
    left: -5.25vw;
    width: 42vw;
    transform: translateY(-50%) translateX(-5%); }
    @media only screen and (max-width: 1680px) {
      .not-found img {
        width: 59.2vw;
        left: -7.4vw; } }
    @media only screen and (max-width: 1300px) {
      .not-found img {
        width: 48vw;
        left: -6vw; } }
    @media only screen and (max-width: 991px) {
      .not-found img {
        width: 64vw;
        left: -8vw; } }
    @media only screen and (max-width: 767px) {
      .not-found img {
        width: 95.6vw;
        left: -11.95vw; } }
  .not-found p {
    margin: 0;
    margin-bottom: 70px;
    margin-left: 5.25vw; }
    @media only screen and (max-width: 1680px) {
      .not-found p {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 1300px) {
      .not-found p {
        margin-bottom: 63px; } }
    @media only screen and (max-width: 991px) {
      .not-found p {
        margin-bottom: 56px; } }
    @media only screen and (max-width: 767px) {
      .not-found p {
        margin-bottom: 49px; } }
    @media only screen and (max-width: 840px) {
      .not-found p {
        margin-left: 7.4vw; } }
    @media only screen and (max-width: 1300px) {
      .not-found p {
        margin-left: 6vw; } }
    @media only screen and (max-width: 991px) {
      .not-found p {
        margin-left: 8vw; } }
    @media only screen and (max-width: 767px) {
      .not-found p {
        margin-left: 11.95vw; } }
  .not-found .came-back {
    display: flex;
    align-items: center; }
    .not-found .came-back a {
      opacity: 0.8;
      color: #d4eaf7;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 4px;
      text-decoration: none;
      display: flex;
      align-items: center;
      margin-left: 10.5vw;
      flex-shrink: 0; }
      @media only screen and (max-width: 1680px) {
        .not-found .came-back a {
          margin-left: 14.8vw; } }
      @media only screen and (max-width: 1300px) {
        .not-found .came-back a {
          margin-left: 12vw; } }
      @media only screen and (max-width: 991px) {
        .not-found .came-back a {
          margin-left: 16vw; } }
      @media only screen and (max-width: 767px) {
        .not-found .came-back a {
          margin-left: 23.9vw; }
          .not-found .came-back a span {
            width: 30px; } }
      .not-found .came-back a:hover {
        opacity: 0.4; }
      @media only screen and (max-width: 991px) {
        .not-found .came-back a {
          font-size: 15px;
          line-height: 1.3; } }
      @media only screen and (max-width: 767px) {
        .not-found .came-back a {
          font-size: 14px;
          line-height: 1.3; } }
      .not-found .came-back a span {
        display: flex;
        width: 100%;
        width: 49px;
        height: 9px;
        opacity: 0.4;
        margin-left: 10px; }
        .not-found .came-back a span svg {
          fill: #d4eaf7; }
  .not-found a {
    text-decoration: none; }

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 19;
  display: none;
  padding-right: 0 !important;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all .4s !important; }
  .overlay.active {
    display: block; }
  .overlay.open .overlay__content {
    will-change: transform;
    transform: translateX(0); }
    .overlay.open .overlay__content > * {
      opacity: 1; }
  .overlay__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.6); }
  .overlay__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    min-height: 100%; }
    @media only screen and (max-width: 991px) {
      .overlay__wrapper {
        align-items: center; } }
  .overlay__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px; }
    .overlay__close div {
      position: absolute;
      top: 22px;
      left: -22px;
      width: 24px;
      height: 2px;
      background-color: rgba(212, 234, 247, 0.4); }
      .overlay__close div:first-child {
        transform: rotate(45deg); }
      .overlay__close div:last-child {
        transform: rotate(-45deg); }
    .overlay__close:hover {
      cursor: pointer; }
      .overlay__close:hover div {
        background-color: rgba(212, 234, 247, 0.2); }
  .overlay__content {
    position: relative;
    z-index: 21;
    transition: transform .4s;
    width: 50vw;
    background: url("/app/images/pattern.jpg");
    height: 100vh;
    padding: 20vh 10vw 0 10vw;
    transform: translateX(-100vw);
    transition: all 1s; }
    @media only screen and (max-width: 991px) {
      .overlay__content {
        width: 100vw; } }
    @media only screen and (max-width: 767px) {
      .overlay__content {
        padding-top: 10vh; } }
    .overlay__content > * {
      max-width: 100%;
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 1s; }
  @media only screen and (max-width: 767px) {
    .overlay__content {
      max-width: 100%; } }

.success {
  display: none;
  width: 500px;
  padding: 60px 60px;
  background: #aaa;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.4); }
  .success img {
    display: block;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto; }
  .success .header_1,
  .success .header_1-small,
  .success .text-default {
    text-align: center; }
  .success.active {
    display: block; }

.scroll-top {
  position: fixed;
  right: 20px;
  z-index: 15;
  bottom: 45px;
  display: block;
  width: 45px;
  height: 45px;
  opacity: 0;
  transform: rotate(-90deg);
  padding: 4px;
  background: transparent; }
  .scroll-top .stick {
    fill: rgba(212, 234, 247, 0.4); }
  @media only screen and (max-width: 991px) {
    .scroll-top {
      bottom: -82px;
      width: 40px;
      height: 40px; } }
  .scroll-top.active {
    bottom: 10px;
    opacity: 1; }
  .scroll-top:hover {
    background: transparent;
    cursor: pointer; }
    .scroll-top:hover .stick {
      fill: rgba(212, 234, 247, 0.3); }

/*   Lines    */
.mceContentBody {
  background-color: #fff; }

.mce-border-dashed-bottom {
  border-bottom: 1px dashed; }

.mce-border-site {
  border: 2px solid #039ea4;
  border-radius: 3px;
  padding: 10px;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em; }

.mce-button-invert {
  display: inline;
  border: 0px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: #fd5e62;
  color: #fff; }

.mce-text-shadow-lite {
  text-shadow: 1px 1px #f3f3f3; }

.mce-text-shadow-dark {
  text-shadow: 1px 1px #3c3c3c; }

.mce-qoute {
  border-left: 1px solid #039ea4;
  padding: 25px;
  font-style: italic;
  background: rgba(0, 0, 0, 0.08);
  display: block;
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative; }
  .mce-qoute:after {
    position: absolute;
    content: '';
    background: #039ea4;
    border-radius: 50%;
    width: 1px;
    height: 100%;
    left: 0;
    top: 0px;
    box-shadow: 2px 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 1px 1px rgba(0, 189, 197, 0.35); }
  @media only screen and (max-width: 1300px) {
    .mce-qoute {
      padding-left: 15px; } }
  @media only screen and (max-width: 991px) {
    .mce-qoute {
      padding-left: 12px; } }

.mce-epick {
  display: block;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center; }
  .mce-epick:before {
    content: '';
    width: 190px;
    position: absolute;
    left: 50%;
    margin-left: -95px;
    top: 0px;
    border: 1px solid #d4eaf7; }
  .mce-epick:after {
    content: '';
    width: 190px;
    position: absolute;
    left: 50%;
    margin-left: -95px;
    bottom: 0px;
    border: 1px solid #d4eaf7; }

.spoiler * {
  transition: all 1s; }

.spoiler .text-wrapper {
  overflow: hidden; }

.spoiler .visible-text {
  padding-bottom: 10px; }
  .spoiler .visible-text p {
    margin-bottom: 0; }

.spoiler .hidden-text {
  opacity: 0;
  max-height: 0; }
  .spoiler .hidden-text p {
    margin: 0px; }

.spoiler .toggler {
  margin-top: 0px;
  text-align: left;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px; }

.spoiler.active .hidden-text {
  max-height: 999px;
  opacity: 1; }

#tinymce .hidden-text {
  opacity: .8;
  max-height: none; }
