/*------------slider-----------*/
@mixin slider {
	$containerTrue: false; 							//	есть ли контейнер у салйдера (полнаразмерный ли он)
	$dots: false; 									//	есть ли точки
	$arrow: true; 									//	есть ли стрелки
	$textBlock: true; 								//	есть ли текстовый блок
	$sliderHeight: 690px; 							//	высота слайдера на десктопе
	$sliderHeightMedium: 510px; 						//	высота слайдера на нетбуке
	$sliderHeightSmall: 390px; 						// 	высота слайдера на планшете
	$viewDots: circle; 								//	вид точек circle либо block
	$dotsWidth: 8px; 								//	ширина высота точек
	$dotsPost: slider; 								//	налезают ли точки на слайдер
	$dotsPostBottom: 30px; 							//	отступ от низа слайдера то точек
	$dotsColor: red; 								//	цвет точек
	$arrowPost: true;								//	привязана ли стрелка к контайнеру(от него ли идет отчет)

	$textBlockWidth: 650px; 							//	размеры текстового блока
	$textBlockWidthMedium: 300px;
	$textBlockWidthSmall: 200px;
	$textBlockBg: transparent; 						//	цвет текстового блока

	$textBlockMarginLeft: 300px; 					//	отступы слева и сверху
	$textBlockMarginLeftMedium: 200px;
	$textBlockMarginLeftSmall: 100px;
	$textBlockMarginTop: 120px;
	$textBlockMarginTopMedium: 100px;
	$textBlockMarginTopSmall: 120px;

	.slider {
		position: relative;
		transition: all 0s;
		* {
			transition: all 0s; }
		.slider-content {
			@include phone {
				display: none; } }
		.slider-content-item {
			position: relative;
			width: 100%;
			height: $sliderHeight;
			@if $textBlock == true {
				.silder-wrapper {
					color: white;
					width: $textBlockWidth;
					margin-left: $textBlockMarginLeft;
					margin-top: $textBlockMarginTop;
					background: $textBlockBg;
					@include medium {
						width: $textBlockWidthMedium;
						margin-left: $textBlockMarginLeftMedium;
						margin-top: $textBlockMarginTopMedium; }
					@include small {
						width: $textBlockWidthSmall;
						margin-top: $textBlockMarginTopSmall;
						margin-left: $textBlockMarginLeftSmall; } } }
			.container {
				@if ($containerTrue == true) {
					width: 100%;
					margin: 0px;
					padding: 0px;
					height: 100%; } }

			@include medium {
				height: $sliderHeightMedium; }
			@include small {
				position: relative;
				width: 100%;
				height: $sliderHeightSmall; } }
		.replace-slider {
			display: none;
			@include phone {
				display: inline;
				max-width: 100%; } }

		@if ($dots == true) {
			.slick-dots {
				padding: 0;
				list-style: none;
				margin: 0;
				height: 39px;
				@if ($dotsPost == slider) {
					position: absolute;
					bottom: $dotsPostBottom; }
				width: 100%;
				text-align: center;
				li {
					position: relative;
					display: inline-block;
					width: 20px;
					height: 20px;
					margin: 0 5px;
					padding: 0;
					button {
						font-size: 0px;
						line-height: 0px;
						position: absolute;
						left: 50%;
						display: block;
						top: 0px;
						width: $dotsWidth;
						height: $dotsWidth;
						padding: 0px;
						margin-left: -#{$dotsWidth / 2};
						-webkit-font-smoothing: antialiased;
						background:  transparent;
						border: 1px solid $dotsColor;
						box-sizing: border-box;
						cursor: pointer;
						@if ($viewDots == circle) {
							border-radius: 50%; } } }
				.slick-active {
					button {
						background: $dotsColor; } } } }
		@else if ($dots == false) {
			.slick-dots {
				display: none !important; } }

		@if ($arrow == true) {
			.arrow {
				position: absolute;
				top: 50%;
				margin-top: -#{$arrows__size / 2};
				z-index: 10; }
			.arrow-next {
				@if ($arrowPost == true) {
					right: calc((100% - 1240px) / 2);
					@include medium {
						right: calc((100% - 930px) / 2); }
					@include small {
						right: calc((100% - 710px) / 2); } }
				@else {
					right: 0px; } }
			.arrow-prev {
				@if ($arrowPost == true) {
					left: calc((100% - 1240px) / 2);
					@include medium {
						left: calc((100% - 930px) / 2); }
					@include small {
						left: calc((100% - 710px) / 2); } }
				@else {
					left: 0px; } }
			.slider-image-static {
				display: none;
				img {
					width: 100%;
					height: auto; }
				@include phone {
					display: block; } } }
		@else {
			.slider__arrow {
				display: none; } } }
	.slider-content:not(.slick-initialized) {
		display: none; } }

@include slider;
