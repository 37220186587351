@mixin copyright_1 {
	$global__bg: transparent;
	$global__box-shadow: none;
	$global__margin-top: none;						//	если не используется
	$global__margin-bottom: none;					//	установить значение 'none'

	$container__bg: none;
	$container__box-shadow: none;
	$copyright-font-family: $font-family__1;
	$copyright__height: 100px;						//	высота copyright
	$copyright__gorizont-align: left;				//	горизонтальное выравнивание copyright(justify, center, left, right)


	$text_color: rgba($color__4, 0.2);						//	цвет текста (Все права защищены)
	$linck-color-terms: rgba($color__4, 0.2);					//	цвет пользовательского соглашения
	$linck-color-terms-hover: rgba($color__4, 0.2);			//	цвет пользовательского соглашения hover

	$link_text-decoration: none; 					//	декорирование ссылок
	$link_text-decoration-hover: none; 				//	декорирование ссылок при наведении
	$web2_color: $text_color;							//	цвет подписи "web2"
	$web2_color-hover: $text_color;					//	цвет подписи "web2" при наведении на "Создание сайтов web2"
	$web2_text-decoration: none;
	$web2_text-decoration-hover: underline;


	.copyright {
		padding-bottom: 60px;
		margin-top: 25px;
		@include phone {
			margin-top: 30px;
			padding-bottom: 20px; }
		.container {

			.content {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-family: $copyright-font-family;
				padding-top: 0 !important;
				padding-bottom: 0 !important;
				font-size: $font-size__6;
				letter-spacing: 1.4px;
				a {
					font-size: $font-size__6;
					letter-spacing: 1.4px; }

				@include medium {
					font-size: 14px;
					a {
						font-size: 14px;
						letter-spacing: 1px; } }
				@include small {
					font-size: 12px;
					letter-spacing: 1px;
					a {
						font-size: 12px;
						letter-spacing: 1px; } }
				@include phone {
					flex-direction: column;
					padding-left: 0px;
					padding-right: 0px;
					-webkit-justify-content: flex-start;
					justify-content: flex-start;
					align-items: flex-start;
					text-align: left !important;
					font-size: 12px;
					a {
						font-size: 12px; }

					>div {
						padding-left: 0px;
						padding-right: 0px;
						margin-bottom: 8px;
						width: unset; }

					>div:last-child {
						padding-bottom: 0px;
						margin-top: 24px; } }

				.logo-caption {
					font-weight: 300;
					line-height: 30px;
					text-transform: uppercase;
					letter-spacing: 2px;
					opacity: 0.5;
					color: $color__4;

					transform: translateX(3px);
					span {
						display: inline-block;
						width:  110px;
						text-align: center;
						@include max(#{$firstbreckpoint}) {
							width: 110px; }
						@include small {
							width: 87px;
							letter-spacing: 1px;
							font-size: 11px;
							line-height: 1; }
						@include phone {
							display: none; } }

					@include small {

						letter-spacing: 1px; }
					@include phone {

						transform: translateX(3px);
						letter-spacing: 1px; } }


				.copyright__copy {
					color: $text_color;

					@include small {
						margin-left: 0; } }

				.copyright__terms {
					@include small {
						text-align: right; }
					@include phone {
						text-align: left; }
					a {
						color: $linck-color-terms;
						text-decoration: $link_text-decoration;
						&:hover {
							text-decoration: $link_text-decoration-hover;
							color: $linck-color-terms-hover;
							opacity: 0.4; } } }
				.copyright__created {
					@include small {
						flex-grow: 1;
						text-align: right; }
					a {
						text-decoration: $link_text-decoration;
						color: $text_color;
						&:hover {
							text-decoration: none;
							span {
								color: $web2_color-hover;
								text-decoration: none; } } }
					span {
						color: $web2_color;
						text-decoration: $web2_text-decoration; } } } } } }


@include copyright_1;
