@mixin main {
	//	variables
	//
	$global__bg: none;
	$container__bg: none;
	$container__box-shadow: none;

	//	если на сайте нет боковой колонки,
	//	то просто игнорируй эти настройки
	$aside__width: 320px;								//	ширина боковой колонки
	$space-to-aside: 30px;								//	расстояние от боковой колонки до контента


	//	styles
	//
	.main {
		@if $global__bg != none {
			background: $global__bg; }
		margin-top: 140px;
		border-top: 1px solid transparent;
		margin-bottom: #{$content__pading-red}px;
		@include max(#{$firstbreckpoint}) {
			margin-bottom: #{$content__pading-red * 0.9}px; }
		@include max($secondbreckpoint) {
			margin-bottom: #{$content__pading-red * 0.9}px; }
		@include small {
			margin-bottom: #{$content__pading-red * 0.8}px; }
		@include phone {
			margin-bottom: #{$content__pading-red * 0.7}px; }



		.container {
			@if $container__bg != none {
				background: $container__bg; }
			@if $container__box-shadow != none {
				box-shadow: $container__box-shadow; } }

		.aside {
			flex-shrink: 0;
			width: $aside__width;
			background-color: pink;
			@include small {
				order: 2; } }

		&__wrapper {
			display: flex;
			align-items: flex-start;
			@include small {
				flex-wrap: wrap;
				justify-content: center; }

			.main__content {
				flex-grow: 1;
				@include content-padding(0); }

			//	первому дочернему элементу делаем правый отступ
			>*:first-child {
				margin-right: $space-to-aside;
				@include small {
					margin-right: 0; } } }

		&__content {
			@include content-padding-color($content__pading-red);
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-bottom: 40px;

			>.text-default {
				>* {
					padding-left: #{$percentVector / 2}vw;
					padding-right: #{$percentVector * 2}vw;
					@include max(#{$firstbreckpoint}) {
						padding-left: #{$percent1360Vector / 2}vw;
						padding-right: #{$percent1360Vector * 2}vw; }
					@include max($secondbreckpoint) {
						padding-left: #{$percentsecondVector / 2}vw;
						padding-right: #{$percentsecondVector * 1}vw; }
					@include small {
						padding-left: #{$percentsmallVector / 2}vw;
						padding-right: #{$percentsmallVector * 2}vw; }
					@include phone {
						padding-left: #{$percentphoneVector / 2}vw;
						padding-right: #{$percentphoneVector / 2}vw;
 } }						// padding-right: 0

				.gallery-blocks {
					padding-left: 0 !important;
					padding-right: 0 !important;
					margin-top: 240px;
					@include max(#{$firstbreckpoint}) {
						margin-top: 200px; }
					@include max($secondbreckpoint) {
						margin-top: 150px; }
					@include small {
						margin-top: 100px; }
					@include phone {
						margin-top: 60px; } } }
			@include small {
				order: 1;
				width: 100%; }
			>*:last-child {
				margin-bottom: 0; }
			h1 {
				@include h1;
				padding-left: #{$percentVector / 2}vw;
				margin-top: $header_decor-margin;
				@include max(#{$firstbreckpoint}) {
					padding-left: #{$percent1360Vector / 2}vw; }
				@include max($secondbreckpoint) {
					padding-left: #{$percentsecondVector / 2}vw; }
				@include medium {
					margin-top: $header_decor-margin-medium; }
				@include small {
					padding-left: #{$percentsmallVector / 2}vw;
					margin-top: $header_decor-margin-small; }
				@include phone {
					padding-left: #{$percentphoneVector / 2}vw;
					margin-top: $header_decor-margin-phone; } }
			h2 {
				@include h2; }
			h3 {
				@include h3; }
			h4 {
				@include h4; }
			a {
				color: $link__color;
				font-weight: $link__font-weight;
				text-decoration: none;
				@if $link__underline == true {
					box-shadow: inset 0 -1px $link__underline-color; }
				@if $link__underline-hover == true {
					&:hover {
						box-shadow: inset 0 -1px $link__underline-color-hover; } }
				@else {
					&:hover {
						box-shadow: none; } }
				&:hover {
					color: $link__color-hover; } }

			ul {
				list-style: none;
				padding-left: 0;
				li {
					position: relative;
					// padding-left: 40px
					&:after {
						position: absolute;
						content: '';
						background: $color__5;
						border-radius: 50%;
						width: 5px;
						height: 5px;
						left: -20px;
						transform: translateX(50%);
						top: 15px;
						box-shadow: 0px 0px 12px 1px  rgba(0,189,197,.35); } } }
			img {
				display: block;
				max-width: 100%;
				@include max(600px) {
					margin-right: 0;
					margin-left: 0; }
				@include phone {
					margin: 10px auto !important; } } }
		&__map {
			min-height: 0;
			visibility: hidden;
			&.active {
				min-height: 600px;
				visibility: visible;
				@include phone {
					min-height: 350px;
					//	костыль для карт, который отменяет стили для картинок на адаптиве (см. выше)
					img {
						margin: 0 !important; } }
				* {
					transition: all 0s; } } } } }
@include main;
