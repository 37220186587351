@mixin form-callback_1 {
	//	variables
	//
	$form__width: 50vw;									//	ширина формы
	$form__padding-top-bottom: 40px;						//	отступы сверху и снизу
	$form__padding-left-right: 80px;						//	отступы слева и справа
	$form__bg: #031c21;
	$form__border: none;
	$form__border-radius: none;
	$form__box-shadow: none;

	$space-to-form: 40px;								//	расстояние от заголовка до инпута и от польз. согл. до кнопки
	$space-between-items: 30px;							//	расстояние между input`ами

	//	styles
	//
	.form-callback {
		display: none;
		width: 100%;



		@if $form__border != none {
			border: $form__border; }
		@if $form__border-radius != none {
			border-radius: $form__border-radius; }
		@if $form__box-shadow != none {
			box-shadow: $form__box-shadow; }
		&.active {
			display: block;
			padding-top: 5vh; }
		.form-item {
			margin-bottom: $space-between-items; }
		.header_1,
		.header_1-small {
			@if $input-d__type == transparent {
				margin-bottom: $space-to-form; }
			@else {
				margin-bottom: $space-to-form - 20px; } }
		.button-wrapper {
			&:after {
				left: 25%;
				background-image: linear-gradient(-216deg, rgba(7, 26, 30, 0.09) 0%, rgba(100, 240, 254, 0.23) 50%, rgba(13, 35, 39, 0) 100%); } }
		button {
			margin: $space-to-form auto 0; } } }


@include form-callback_1;
