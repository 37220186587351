//	Кнопка 'наверх'
//	на экранах меньше 992px, размер кнопки такой же, как размер всех кнопок на адаптиве ($responsive-button)
.scroll-top {
	position: fixed;
	right: 20px;
	z-index: 15;
	bottom: 45px;
	display: block;
	width: 45px;
	height: 45px;
	opacity: 0;
	transform: rotate(-90deg);

	// border: 1px solid rgba($color__5, 0.6)
	padding: 4px;
	@if $arrows__border != none {
		border: $arrows__border; }
	@if $arrows__border-radius != none {
		border-radius: $arrows__border-radius; }
	background: $arrows__bg-color;
	.stick {
		fill: $arrows__stick-color; }
	@include small {
		bottom: -#{$responsive-button};
		width: 40px;
		height: 40px; }
	&.active {
		bottom: 10px;
		opacity: 1; }
	&:hover {
		//	если нужно сместить стрелку вверх - расскомментируй строку ниже
		// bottom: 15px
		@if $arrows__border-hover != $arrows__border {
			border: $arrows__border-hover; }
		@if $arrows__border-radius-hover != $arrows__border-radius {
			border-radius: $arrows__border-radius-hover; }
		background: $arrows__bg-color-hover;
		cursor: pointer;
		@if $arrows__scale-hover != none {
			transform: scale($arrows__scale-hover); }
		.stick {
			fill: $arrows__stick-color-hover; } } }
