body.loaded {
	.uppered {
		transform: translateY(0%);
		opacity: 1; } }

.uppered {
	opacity: 0;
	transform: translate3d(0,0,0) translateY(50%);
	transition: all 1200ms; }

*[data-scroll] {
	overflow: hidden;
	.up {
		opacity: 0;
		transform: translate3d(0,0,0) translateY(40px);
		transition: all 1200ms;
		transition-delay: 0.5s; } }

.scrolled {
	overflow: visible;
	.up {
		transform: translateY(0%);
		opacity: 1; } }
