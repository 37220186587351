@mixin gallery_1 {
	$global__bg: none;
	$global__margin-top: 0px;						//	если не используется
	$global__margin-bottom: 0px;					//	установить значение 'none'
	$global__box-shadow: none;

	$container__bg: none;
	$container__box-shadow: none;

	//	Количество фото в ряду
	$count: 4;
	$count-medium: $count;
	$count-small: 3;
	$count-phone: 2;

	//	Расстояние между фото
	$dist: 10px;
	$dist-medium: $dist * .8;
	$dist-small: $dist * .6;
	$dist-phone: $dist * .4;

	$grid__margin-top: 20px; 								//	отстпут от текста до галереи на десктопе
	$grid__margin-top-phone: $grid__margin-top * .5;
	$grid__margin-bottom-phone: 20px; 						//	отступ от галереи до кнопки на телефоне

	$zoom: true;						//	приблежение при навелении
	$scale: false; 					//	увеличение при навелении
	$border: false;					//	рамка вокруг фото
	$picture: false;					//	рисунок с наложением

	$border-width: 2px;				//	толщина рамки вокруг фото
	$border-color: red; 				//	цвет рамки вокруг фото

	$picture__bg: rgba(0, 0, 0, 0.5); //	цвет заливки при изображении (лупа)

	.gallery {}


	.gallery-header {
		.header_1,
		.header_1-small {
			text-align: left; }
		.text-default {
			margin-bottom: 0; } }
	.gallery-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		a {
			display: block;
			font-size: 0px;
			width: #{$percentVector * 2}vw;
			margin-bottom: #{$percentVector}vw;
			line-height: 0;
			box-shadow: 0 8px 18px 6px rgba(0, 0, 0, 0), 0 0 139px rgba(0, 189, 197, 0);
			&:hover {
				box-shadow: 0 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 139px rgba(0, 189, 197, 0.15); }
			@include max(#{$firstbreckpoint}) {
				width: #{$percent1360Vector * 2}vw;
				margin-bottom: #{$percent1360Vector}vw; }
			@include max($secondbreckpoint) {
				width: #{$percentsecondVector * 2}vw;
				margin-bottom: #{$percentsecondVector}vw; }
			@include small {
				width: #{$percentsmallVector * 2}vw;
				margin-bottom: #{$percentsmallVector}vw;
				box-shadow: none;
				&:hover {
					box-shadow: none; } }
			@include phone {
				width: #{$percentphoneVector * 1.5}vw;
				margin-bottom: #{$percentphoneVector / 2}vw; }

			&:nth-child(4n) {
				margin-right: #{$percentVector}vw;
				@include max(#{$firstbreckpoint}) {
					margin-right: #{$percent1360Vector}vw; }
				@include max($secondbreckpoint) {
					margin-right: #{$percentsecondVector}vw; }
				@include small {
					margin-right: #{$percentsmallVector }vw; }
				@include phone {
					margin-right: #{$percentphoneVector * 0.5}vw; } }
			&:nth-child(4n+1) {
				margin-left:  #{$percentVector}vw;
				@include max(#{$firstbreckpoint}) {
					margin-left: #{$percent1360Vector}vw; }
				@include max($secondbreckpoint) {
					margin-left: #{$percentsecondVector}vw; }
				@include small {
					margin-left: #{$percentsmallVector }vw; }
				@include phone {
					margin-left: #{$percentphoneVector * 0.5}vw; } }

			span {
				display: block;
				width: 100%;
				height: 100%;
				overflow: hidden;
				font-size: 0; }
			img {
				width: 100%;
				height: auto;
				margin: 0 !important; } } } }

@include gallery_1;
