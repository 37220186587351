$d: default;

/**
 *	Медиа запросы
 */
@mixin medium {
	@media only screen and (max-width: 1299px) {
		@content; } }
@mixin small {
	@media only screen and (max-width: 991px) {
		@content; } }
@mixin phone {
	@media only screen and (max-width: 767px) {
		@content; } }
@mixin xs {
	@media only screen and (max-width: 399px) {
		@content; } }
@mixin max($max-width) {
	@media only screen and (max-width: $max-width) {
		@content; } }
@mixin min($min-width) {
	@media only screen and (min-width: $min-width) {
		@content; } }
@mixin between($min-width, $max-width) {
	@media only screen and (min-width: $min-width) and (max-width: $max-width) {
		@content; } }

/**
 * Миксин, который добавляет стандартные отступы
 * Первым передается отступ справа и слева, потому что он используется чаще
 * Аргументы передаются без 'px'
 *
 * $padding-left-right: отступ слева и справа, если отступ н нужен - указать '0'
 * $padding-top: отступ сверху
 * $padding-bottom: отступ снизу
 */
@mixin content-padding($padding-right-left: default, $padding-top: default, $padding-bottom: default) {

	$pt: $padding-top;
	$pt-medium: $pt;
	$pt-small: $pt;
	$pt-phone: $pt;

	$prl: $padding-right-left;
	$prl-medium: $prl;
	$prl-small: $prl;
	$prl-phone: $prl;

	$pb: $padding-bottom;
	$pb-medium: $pb;
	$pb-small: $pb;
	$pb-phone: $pb;

	//	padding-top
	@if ($pt == default) {
		$pt: $content__padding-top; }

	//	padding-right & padding-left
	@if ($prl == default) {
		$prl: $content__padding-right-left; }

	//	padding-bottom
	@if ($pb == default) {
		$pb: $content__padding-bottom; }

	//	Если отступ не равен 0 - пропорционально уменьшаем его на адаптиве
	//	Если после уменьшения отступ меньше 20px - сделать его 20px
	@if ($padding-top != 0) {
		$pt-medium: $pt * .8;
		@if ($pt-medium < 20) {
			$pt-medium: 20; }

		$pt-small: $pt * .6;
		@if ($pt-small < 20) {
			$pt-small: 20; }

		$pt-phone: $pt * .4;
		@if ($pt-phone < 20) {
			$pt-phone: 20; } }

	@if ($padding-right-left != 0) {
		$prl-medium: $prl * .8;
		@if ($prl-medium < 20) {
			$prl-medium: 20; }

		$prl-small: $prl * .6;
		@if ($prl-small < 20) {
			$prl-small: 20; }

		//	padding-right и padding-left на телефонах всегда по 20px
		$prl-phone: 0; }

	@if ($padding-bottom != 0) {
		$pb-medium: $pb * .8;
		@if ($pb-medium < 20) {
			$pb-medium: 20; }

		$pb-small: $pb * .6;
		@if ($pb-small < 20) {
			$pb-small: 20; }

		$pb-phone: $pb * .4;
		@if ($pb-phone < 20) {
			$pb-phone: 20; } }

	padding: #{$pt}px #{$prl}px #{$pb}px;
	@include medium {
		padding: #{$pt-medium}px #{$prl-medium}px #{$pb-medium}px; }
	@include small {
		padding: #{$pt-small}px #{$prl-small}px #{$pb-small}px; }
	@include phone {
		padding: #{$pt-phone}px #{$prl-phone}px #{$pb-phone}px; } }


@mixin content-padding-color($padding) {
	padding: #{$padding}px $percent;
	@include max(#{$firstbreckpoint}) {
		padding: #{$padding * 0.9}px 0; }
	@include max($secondbreckpoint) {
		padding: #{$padding * 0.9}px #{$percentsecondVector}vw; }
	@include small {
		padding: #{$padding * 0.8}px 0; }
	@include phone {
		padding: #{$padding * 0.7}px 0; } }


@mixin offset-color($color) {
	$padding: 80px;
	@if ($color == green) {
		$padding: $content__pading-green; }
	@if ($color == orange) {
		$padding: $content__pading-orange; }

	@if ($color == yellow) {
		$padding: $content__pading-yellow; }
	@if ($color == blue) {
		$padding: $content__pading-blue; }


	margin-bottom: #{$padding}px;
	@include max(#{$firstbreckpoint}) {
		margin-bottom: #{$padding * 0.9}px; }
	@include max($secondbreckpoint) {
		margin-bottom: #{$padding * 0.9}px; }
	@include small {
		margin-bottom: #{$padding * 0.8}px; }
	@include phone {
		margin-bottom: #{$padding * 0.7}px; } }





// =content-padding-color($padding)
// 	padding: #{$padding}px $percent
// 	+max(#{$firstbreckpoint})
// 		padding: #{$padding * 0.9}px $percent1360
// 	+max($secondbreckpoint)
// 		padding: #{$padding * 0.9}px #{$percentsecondVector}vw
// 	+small
// 		padding: #{$padding * 0.8}px 0
// 	+phone
// 		padding: #{$padding * 0.7}px 0

/**
 *	Clearfix
 *	Используется не в контейнере, если в блоке есть элементы с float
 */
@mixin clear {
	&::after {
		clear: both;
		content: '';
		display: table; } }

/**
 *	Задает элементу, в котором вызывается, ширину в $width колонок (1-12)
 *
 *	$width: кол-во колонок, которое займет блок
 *	Больше информации можно найти в документации
 */
@mixin col($width, $float: left) {
	@if $float == left {
		float: left; }
	width: 100 / 12 * $width + 0%;
	@if $float == center {
		margin-right: auto;
		margin-left: auto; } }

/**
 *	миксин placeholder
 *	находится на стадии тестирования
 *	будет удобно - оставим, не будет необходимости - уберем
 */
@mixin placeholder {
	&:-moz-placeholder {
		@content; }
	&::-moz-placeholder {
		@content; }
	&:-ms-input-placeholder {
		@content; }
	&::-webkit-input-placeholder {
		@content; } }



/**
 * Миксин заголовка <h1> с большим отступом ($h1__margin)
 */
@mixin h1 {
	margin-top: 0;
	margin-bottom: 70px;
	color: $h1__color;
	font-size: $font-size__1;
	font-weight: $h1__weight;
	text-align: $h1__align;
	line-height: 80px;
	letter-spacing: 3.54px;
	@if $h1__uppercase == true {
		text-transform: uppercase; }
	@if $h1__letter-spacing != default {
		letter-spacing: $h1__letter-spacing; }
	@include medium {
		font-size: $font-size__1-medium;
		line-height: 70px;
		letter-spacing: 2.83px; }
	@include small {
		margin-bottom: $h1__margin * 0.75;
		font-size: $font-size__1-small;
		line-height: 55px;
		letter-spacing: 2.12px; }
	@include phone {
		margin-bottom: $h1__margin * 0.5;
		font-size: $font-size__1-phone;
		letter-spacing: 1.42px;
		line-height: 42px; } }

/**
 * Миксин заголовка <h1> с малым отступом ($h1__margin-small)
 */
@mixin h1-small {
	margin-top: 0;
	margin-bottom: $h1__margin;
	color: $h1__color;
	font-size: $font-size__1;
	font-weight: $h1__weight;
	text-align: $h1__align;
	line-height: $h1__line-height;
	@if $h1__uppercase == true {
		text-transform: uppercase; }
	@if $h1__letter-spacing != default {
		letter-spacing: $h1__letter-spacing; }
	@include medium {
		font-size: $font-size__1-medium; }
	@include small {
		margin-bottom: $h1__margin * 0.75;
		font-size: $font-size__1-small; }
	@include phone {
		margin-bottom: $h1__margin * 0.5;
		font-size: $font-size__1-phone; } }



/**
 * Миксин заголовка <h2>
 */
@mixin h2 {
	margin-top: 1.8em;
	margin-bottom: 1.2em;
	color: $hd__color;
	font-size: $font-size__2;
	font-family: $hd__family;
	font-weight: $hd__weight;
	line-height: $h2__line-height;
	@if $hd__uppercase == true {
		text-transform: uppercase; }
	@include medium {
		font-size: $font-size__2-medium; }
	@include small {
		font-size: $font-size__2-small; }
	@include phone {
		font-size: $font-size__2-phone; } }
/**
 * Миксин заголовка <h3>
 */
@mixin h3 {
	margin-top: 1.8em;
	margin-bottom: 1.2em;
	color: $hd__color;
	font-size: $font-size__3;
	font-family: $hd__family;
	font-weight: $hd__weight;
	line-height: $h3__line-height;
	@if $hd__uppercase == true {
		text-transform: uppercase; }
	@include medium {
		font-size: $font-size__3-medium; }
	@include small {
		font-size: $font-size__3-small; }
	@include phone {
		font-size: $font-size__3-phone; } }



/**
 * Миксин заголовка <h4>
 */
@mixin h4 {
	margin-top: 1.8em;
	margin-bottom: 1.2em;
	color: $hd__color;
	font-size: $font-size__4;
	font-family: $hd__family;
	font-weight: $hd__weight;
	line-height: $h4__line-height;
	@if $hd__uppercase == true {
		text-transform: uppercase; }
	@include medium {
		font-size: $font-size__4-medium; }
	@include small {
		font-size: $font-size__4-small; }
	@include phone {
		font-size: $font-size__4-phone; } }



/**
 * Миксин обычной кнопки, применяется для тега <button>
 */
@mixin button-d {
	display: block;
	min-width: $button-d__min-width;
	max-width: 100%;
	@if $button-d__border-width != none {
		padding: (($button-d__height - ($button-d__font-size + 8px) - ($button-d__border-width * 2)) / 2) $button-d__padding-right-left; }
	@else {
		padding: (($button-d__height - ($button-d__font-size + 8px)) / 2) $button-d__padding-right-left; }

	color: $button-d__color !important;
	font-size: $button-d__font-size;
	font-family: $button-d__font-family;
	@if $button-d__font-weight != 400 {
		@if $button-d__font-weight != normal {
			font-weight: $button-d__font-weight; } }
	text-align: center;
	@if $button-d__text-decoration != underline {
		text-decoration: $button-d__text-decoration !important; }
	@if $button-d__text-transform != none {
		text-transform: $button-d__text-transform; }
	@if $button-d__border-width != none {
		line-height: $button-d__font-size + 8px;
		border: $button-d__border-width solid $button-d__border-color; }
	@else {
		line-height: $button-d__font-size + 8px;
		border: none; }
	@if $button-d__border-radius != none {
		border-radius: $button-d__border-radius; }
	background: $button-d__bg;
	@if $button-d__box-shadow != none {
		box-shadow: $button-d__box-shadow; }
	user-select: none;
	&:hover,
	&:focus {
		@if $button-d__color-hover != $button-d__color {
			color: $button-d__color-hover !important; }
		@if $button-d__text-decoration-hover != $button-d__text-decoration {
			text-decoration: $button-d__text-decoration-hover !important; }
		@if $button-d__border-width != none {
			@if $button-d__border-color-hover != $button-d__border-color {
				border-color: $button-d__border-color-hover; } }
		@if $button-d__border-radius-hover != $button-d__border-radius {
			border-radius: $button-d__border-radius-hover; }
		background: $button-d__bg-hover;
		@if $button-d__box-shadow-hover != $button-d__box-shadow {
			box-shadow: $button-d__box-shadow-hover; }
		@if $button-d__scale-hover != none {
			transform: scale($button-d__scale-hover); }
		cursor: pointer;
		outline: none; }
	@include phone {
		min-width: 0;
		font-size: $text__font-size-phone; }
	@include max(340px) {
		width: 100%; } }

/**
 * Миксин второго варианта кнопки, применяется для тега <button>
 */
@mixin button-i {
	display: block;
	min-width: $button-i__min-width;
	max-width: 100%;
	@if $button-i__border-width != none {
		padding: (($button-i__height - ($button-i__font-size + 8px) - ($button-i__border-width * 2)) / 2) $button-i__padding-right-left; }
	@else {
		padding: (($button-i__height - ($button-i__font-size + 8px)) / 2) $button-i__padding-right-left; }

	color: $button-i__color !important;
	font-size: $button-i__font-size;
	font-family: $button-i__font-family;
	@if $button-i__font-weight != 400 {
		@if $button-i__font-weight != normal {
			font-weight: $button-i__font-weight; } }
	text-align: center;
	@if $button-i__text-decoration != underline {
		text-decoration: $button-i__text-decoration !important; }
	@if $button-i__text-transform != none {
		text-transform: $button-i__text-transform; }
	@if $button-i__border-width != none {
		line-height: $button-i__font-size + 8px;
		border: $button-i__border-width solid $button-i__border-color; }
	@else {
		line-height: $button-i__font-size + 8px;
		border: none; }
	@if $button-i__border-radius != none {
		border-radius: $button-i__border-radius; }
	background: $button-i__bg;
	@if $button-i__box-shadow != none {
		box-shadow: $button-i__box-shadow; }
	user-select: none;
	&:hover,
	&:focus {
		@if $button-i__color-hover != $button-i__color {
			color: $button-i__color-hover !important; }
		@if $button-i__text-decoration-hover != $button-i__text-decoration {
			text-decoration: $button-i__text-decoration-hover !important; }
		@if $button-i__border-width != none {
			@if $button-i__border-color-hover != $button-i__border-color {
				border-color: $button-i__border-color-hover; } }
		@if $button-i__border-radius-hover != $button-i__border-radius {
			border-radius: $button-i__border-radius-hover; }
		background: $button-i__bg-hover;
		@if $button-i__box-shadow-hover != $button-i__box-shadow {
			box-shadow: $button-i__box-shadow-hover; }
		@if $button-i__scale-hover != none {
			transform: scale($button-i__scale-hover); }
		cursor: pointer;
		outline: none; }
	@include phone {
		min-width: 0; }
	@include max(340px) {
		width: 100%; } }

/**
 * Миксин обычной кнопки, применяется для тега <a>
 * $width: ширина кнопки, если не указывать - будет использована минимальная
 * ширина обычной кнопки
 */
@mixin link-button($width: default) {
	@if $width == default {
		width: $button-d__min-width; }
	@else {
		width: $width; }
	@include button-d;
	text-decoration: none; }

/**
 * Миксин второго варианта  кнопки, применяется для тега <a>
 * $width: ширина кнопки, если не указывать - будет использована минимальная
 * ширина обычной кнопки
 */
@mixin link-button-i($width: default) {
	@if $width == default {
		width: $button-d__min-width; }
	@else {
		width: $width; }
	@include button-i;
	text-decoration: none; }


/**
 * Миксин для стандартного input
 */
@mixin input-d {
	display: block;
	width: $input-d__width;
	max-width: 100%;
	letter-spacing: 2.4px;
	@if $input-d__border-width != none {
		padding: (($input-d__height - ($input-d__font-size + 8px) - ($input-d__border-width * 2)) / 2) $input-d__padding-right-left; }
	@else {
		padding: (($input-d__height - ($input-d__font-size + 8px)) / 2) $input-d__padding-right-left; }
	color: $input-d__color;
	font-size: $input-d__font-size;
	font-family: $input-d__font-family;
	@if $input-d__font-weight != 400 {
		@if $input-d__font-weight != normal {
			font-weight: $input-d__font-weight; } }
	@if $input-d__text-align != left {
		text-align: $input-d__text-align; }
	@if $input-d__border-width != none {
		line-height: $input-d__font-size + 8px;
		@if $input-d__type != float {
			border: $input-d__border-width solid $input-d__border-color; }
		@else {
			border: none;
			border-bottom: $input-d__border-width solid $input-d__border-color; } }
	@else {
		line-height: $input-d__font-size + 8px;
		border: none; }
	@if $input-d__border-radius != none {
		border-radius: $input-d__border-radius; }
	@else {
		border-radius: 0; }
	background-color: $input-d__background-color;
	@if $input-d__box-shadow != none {
		box-shadow: $input-d__box-shadow; }
	appearance: none;
	@include phone {
		width: 100%;
		font-size: $font-size__6; }
	&:focus {
		background-color: $input-d__background-color-focus;
		outline: none;
		@if $input-d__box-shadow-focus != $input-d__box-shadow {
			box-shadow: $input-d__box-shadow-focus; }
		@if $input-d__border-width != none {
			@if $input-d__border-color-focus != $input-d__border-color {
				border-color: $input-d__border-color-focus; } }
		@if $input-d__border-radius-focus != $input-d__border-radius {
			border-radius: $input-d__border-radius-focus; }
		@if $input-d__color-focus != $input-d__color {
			color: $input-d__color-focus; } } }

@mixin select-d {
	display: block;
	width: $input-d__width;
	max-width: 100%;
	@if $input-d__border-width != none {
		padding: (($input-d__height - ($input-d__font-size + 8px) - ($input-d__border-width * 2)) / 2) $input-d__padding-right-left; }
	@else {
		padding: (($input-d__height - ($input-d__font-size + 8px)) / 2) $input-d__padding-right-left; }
	color: $input-d__color;
	font-size: $input-d__font-size;
	font-family: $input-d__font-family;
	@if $input-d__font-weight != 400 {
		@if $input-d__font-weight != normal {
			font-weight: $input-d__font-weight; } }
	@if $input-d__text-align != left {
		text-align: $input-d__text-align; }
	@if $input-d__border-width != none {
		line-height: $input-d__font-size + 8px;
		border: $input-d__border-width solid $input-d__border-color; }
	@else {
		line-height: $input-d__font-size + 8px;
		border: none; }
	@if $input-d__border-radius != none {
		border-radius: $input-d__border-radius; }
	@else {
		border-radius: 0; }
	background-color: $input-d__background-color;
	@if $input-d__box-shadow != none {
		box-shadow: $input-d__box-shadow; }
	appearance: none;
	&:focus {
		background-color: $input-d__background-color-focus;
		outline: none;
		@if $input-d__box-shadow-focus != $input-d__box-shadow {
			box-shadow: $input-d__box-shadow-focus; }
		@if $input-d__border-width != none {
			@if $input-d__border-color-focus != $input-d__border-color {
				border-color: $input-d__border-color-focus; } }
		@if $input-d__border-radius-focus != $input-d__border-radius {
			border-radius: $input-d__border-radius-focus; }
		@if $input-d__color-focus != $input-d__color {
			color: $input-d__color-focus; }
		option {
			color: #000; } } }


/**
 * Миксины для фапки и футера
 */

/**
 * Контакты
 * (!)	$color: цвет ссылок
 * (!)	$space-between: вертикальное расстояние между ссылками
 * $color-hover: цвет ссылок при наведении, по умолчанию - цвет указанный выше
 * $text-decoration: подчеркивание ссылок
 * $text-decoration-hover: подчеркивание ссылок при наведении
 * $font-size: размер шрифта ссылок, по умолчанию берется размер обычного текста
 * $font-family: семейство шрифта, по умолчанию берется семейство обычного текста
 * $font-weight: жирность жрифта(начертание), по умолчанию берется жирность обычного текста
 *
 * Пример вызова с цветом '$color__1' и расстоянием между элементами в 10px:
 * +hf__contacts($color__1, 10px)
 *
 * Пример вызова с цветом '$color__1', расстоянием между элементами в 10px, цветом при наведении '$color__2' и размером шрифта '$font-size__3':
 * +hf__contacts($color__1, 10px, $color__2, $font-size: $font-size__3)
 *
 * Пример вызова с цветом '$color__1', расстоянием между элементами в 10px, всегда подчеркнутыми ссылками и цветом при наведении '$color__2':
 * +hf__contacts($color__1, 10px, $color__2, underline, underline)
 */
@mixin hf__contacts($color, $space-between, $color-hover: $color, $text-decoration: none, $text-decoration-hover: none, $font-size: default, $font-family: default, $font-weight: default) {
	&.contacts {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a {
			position: relative;
			display: block;
			margin-top: $space-between / 2;
			margin-bottom: $space-between / 2;
			color: $color;
			@if $font-size == default {
				font-size: $text__font-size; }
			@else {
				font-size: $font-size; }
			@if $font-family == default {
				font-family: $text__font-family; }
			@else {
				font-family: $font-family; }
			@if $font-weight == default {
				font-weight: $text__font-weight; }
			@else {
				font-weight: $font-weight; }
			text-decoration: $text-decoration;
			//	задаем line-height такой же, как и font-size чтобы правильно работали margin-top и -bottom
			@if $font-size == default {
				line-height: $text__font-size; }
			@else {
				line-height: $font-size; }
			&:hover {
				@if $color-hover != $color {
					color: $color-hover; }
				text-decoration: $text-decoration-hover; } }
		br.media-medium {
			display: none; }
		br.media-small {
			display: none; }
		br.media-phone {
			display: none; }
		br.media-xs {
			display: none; }
		@include medium {
			br.media-medium {
				display: block; } }
		@include small {
			align-items: center;
			br.media-small {
				display: block; } }
		@include phone {
			text-align: center;
			a {
				&:first-child:last-child {
					margin-top: 0;
					margin-bottom: 0; }
				&:not(:first-child):not(:last-child) {
					margin-top: 10px;
					margin-bottom: 10px; }
				&:first-child {
					margin-top: 0;
					margin-bottom: 10px; }
				&:last-child {
					margin-top: 10px;
					margin-bottom: 0; } }
			br.media-phone {
				display: block; } }
		@include xs {
			br.media-xs {
				display: block; } } } }
/**
 * Иконки для контактов
 * (!)	$class: класс, для которого будет добавлена иконка, указывать без точки
 * (!)	$width: ширина иконки
 * (!)	$height: высота иконки
 * (!)	$left: расстояние от левого края иконки до текста
 * (!)	$position: позиция иконки в спрайте
 *
 *	Пример вызова
 *	+hf__icon('.phone', 30px, 30px, 25px, -55px -60px)
 *
 */
@mixin hf__icon($class, $width, $height, $left, $position) {
	&.contacts a.#{$class} {
		position: relative;
		padding-left: $left;
		&::before {
			position: absolute;
			top: calc(50% - (#{$height / 2}));
			left: 0;
			width: $width;
			height: $height;
			background-image: url('../images/css_sprites.png');
			background-position: $position;
			content: ''; } } }

/**
 * Соц сети
 * (!)	$width: ширина иконок
 * (!)	$height: высота иконок
 * (!)	$space-between: горизонтальное расстояние между иконками
 * (!)	$hover (opacity | images | box-shadow):	тип ховер-эффекта, выбирается один из перечисленных:
 * 			opacity - добавляет +30% прозрачности иконки.
 * 			images - меняет картинки при ховере, нужно указать картинки в вызове миксина
 * 			box-shadow - добавляет тень иконкам (0 3px 5px rgba(0, 0, 0, .5))
 * 			move - поднимает картинку на 3px
 * 	$vk: позиция иконки vk
 * 	$in: позиция иконки in
 * 	$ok: позиция иконки ok
 * 	$fb: позиция иконки fb
 * 	$vk-hover: позиция иконки vk при наведении
 * 	$in-hover: позиция иконки in при наведении
 * 	$ok-hover: позиция иконки ok при наведении
 * 	$fb-hover: позиция иконки fb при наведении
 *
 * 	Пример вызова с изменением прозрачности при ховере:
 * 	+hf__social(30px, 30px, 10px, $hover: opacity, $vk: 0px 0px)
 *
 * 	Пример вызова с изменением картинок при ховере:
 * 	+hf__social(30px, 30px, 10px, $hover: images, $vk: 0px 0px, $vk-hover: 10px 10px)
 *
 * 	Пример вызова с добавлением тени при ховере:
 * 	+hf__social(30px, 30px, 10px, $hover: box-shadow, $vk: 0px 0px)
 */
@mixin hf__social($width, $height, $space-between, $hover, $vk: none, $in: none, $ok: none, $fb: none, $vk-hover: none, $in-hover: none, $ok-hover: none, $fb-hover: none) {
	&.social {
		display: flex;
		justify-content: center;
		a {
			position: relative;
			display: block;
			width: $width;
			height: $height;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../images/css_sprites.png');
			&:not(:first-child) {
				margin-left: $space-between / 2; }
			&:not(:last-child) {
				margin-right: $space-between / 2; }

			//	если тип ховера - images, добавляем псевдо-элемент
			@if $hover == images {
				&::after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-image: url('../images/css_sprites.png');
					opacity: 0;
					transition: all .2s;
					content: ''; } }
			@if $vk != none {
				&.vk {
					background-position: $vk;
					@if $vk-hover != none {
						&::after {
							background-position: $vk-hover; } } } }
			@if $in != none {
				&.in {
					background-position: $in;
					@if $in-hover != none {
						&::after {
							background-position: $in-hover; } } } }
			@if $ok != none {
				&.ok {
					background-position: $ok;
					@if $ok-hover != none {
						&::after {
							background-position: $ok-hover; } } } }
			@if $fb != none {
				&.fb {
					background-position: $fb;
					@if $fb-hover != none {
						&::after {
							background-position: $fb-hover; } } } }
			&:hover {
				@if $hover == opacity {
					opacity: .7; }
				@elseif $hover == images {
					&::after {
						opacity: 1;
						transition: all .2s; } }
				@elseif $hover == box-shadow {
					filter: drop-shadow(0 3px 5px rgba(0, 0, 0, .5)); }
				@elseif $hover == move {
					transform: translateY(-3px); }
				@else {
					@error $wrong; } } } } }

/**
 * Разделитель (вертикальная линия между элементами)
 * (!)	$width: ширина
 * (!)	$height: высота
 * (!)	$color: цвет заливки
 *
 * Пример вызова с шириной 1px, высотой 100px и цветом '$color__2'
 * +hf__separator(1px, 100px, $color__2)
 */
@mixin hf__separator($width, $height, $color) {
	&.separator {
		width: $width;
		height: $height;
		background-color: $color; } }

/**
 * (!)	parent: класс родителя, к которому добавляется элемнт, без точки
 * (!)	name: переменная, которая соответствует картинке в спрайте
 * (!)	align: выравнивание(привязка) (left | center | right)
 * также можно указать смещение
 * top: от верхнего края, если указать 0 - элемент будет привязан к верху
 * right: от правого края, работает только если выравнивание указано по правой стороне
 * bottom: от нижнего края, если указать 0 - элемент будет привязан к низу
 * left: от левого края, работает только если выравнивание указано по левой стороне
 * Пример вызова:
 * +decor((parent: 'main', name: $decor-1, align: right, top: 20px))
 */
@mixin decor($options) {
	$parent: map-get($options, "parent");
	$name: map-get($options, "name");
	$align: map-get($options, "align");

	$selector: nth($name, 10);

	.#{$parent} {
		position: relative;
		.container {
			position: relative; }
		.#{$selector} {
			position: absolute;
			//	если указан 'top' - добавляем
			@if map-has-key($options, "top") {
				top: map-get($options, "top"); }

			//	к выбранной стороне добавляем отступ, если указан, если нет ставим 0
			@if $align == left {
				@if map-has-key($options, "left") {
					left: map-get($options, "left"); }
				@else {
					left: 0; } }
			@else if $align == right {
				@if map-has-key($options, "right") {
					right: map-get($options, "right"); }
				@else {
					right: 0; } }
			@else if $align == center {
				left: calc(50% - #{(nth($name, 5) / 2)}); }

			//	если указан 'bottom' - добавляем
			@if map-has-key($options, "bottom") {
				bottom: map-get($options, "bottom"); }

			@include sprite-width($name);
			@include sprite-height($name);
			background-image: url("../images/css_sprites.png");
			@include sprite-position($name);

			@include phone {
				display: none; } } } }
