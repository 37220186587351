$form__header_margin-bottom: 40px;					//	отступ от заголовка формы до первого инпута
$form__inputs-space-between: 30px;					//	расстояние между инпутами, без учета подписей к ним
$form__button_margin-top: 30px;



.contacts-form {

	.container {}

	.content {
		padding-top: 0 !important; }
	.flex-row {
		justify-content: space-between;
		@include phone {
			flex-direction: column; } }
	.description {
		padding-top: 10px;
		>.caption {
			@include offset-color(blue); }
		>.text-default {
			@include offset-color(yellow); }
		.flex-row {
			justify-content: space-between;
			align-items: flex-start;
			@include small {
				flex-direction: column; } } }
	.form-item {
		margin-bottom: $form__inputs-space-between;
		@include max($secondbreckpoint) {
			width: 231px; }
		@include small {
			width: $input-d__width; }
		@include phone {
			width: 100%; } }

	.description {
		margin-top: $header_decor-margin;
		@include medium {
			margin-top: $header_decor-margin-medium; }
		@include small {
			margin-top: $header_decor-margin-small;
			margin-right: #{$percentsmallVector}vw; }
		@include phone {
			margin-top: $header_decor-margin-phone; } }

	.form-group {
		@include phone {
			width: 100%; } }
	.form-controll {
		@include small {
			display: flex;

			flex-direction: column;
			.checkbox {
				order: -1; }
			.button {
				margin-bottom:  0; } } }
	.button-wrapper {}

	.checkbox {
		width: #{$percentVector}vw;
		flex-shrink: 0;
		@include max(#{$firstbreckpoint}) {
			width: #{$percent1360Vector}vw; }
		@include max($secondbreckpoint) {
			width: #{$percentsecondVector}vw; }
		@include small {
			width: #{$percentsmallVector * 2}vw; }
		@include phone {
			width: 100%; } }
	.button {
		@include offset-color(green); }



	// .header_1
	// 	margin-bottom: 0


	// .flex-row
	// 	-webkit-justify-content: space-between
	// 	justify-content: space-between
	// 	+medium
	// 		-webkit-flex-direction: column
	// 		-ms-flex-direction: column
	// 		flex-direction: column

	// .form-item
	// 	margin-bottom: 45px
	// 	&last-child
	// 		margin-bottom: 0
	// 	&.checkbox
	// 		width: #{$percentVector}vw
	// 		margin-top: 40px

	// .button-wrapper
	// 	&:after
	// 		background-image: -webkit-linear-gradient(126deg, rgba(7, 26, 30, 0.09) 0%, rgba(100, 240, 254, 0.23) 50%, rgba(13, 35, 39, 0) 100%)

	// .right

	// 	margin-top: $header_decor-margin
	// 	+medium
	// 		margin-top: $header_decor-margin-medium
	// 		.flex-row
	// 			flex-direction: row
	// 			align-items: center

	// 		.button
	// 			width: #{$percentsecondVector * 2}vw
	// 		.form-item

	// 			&.checkbox
	// 				width: #{$percentsecondVector * 2}vw

	// 	+small
	// 		margin-top: $header_decor-margin-small
	// 		.form-controll
	// 			margin-right: #{$percentsmallVector}vw
	// 	+phone
	// 		.flex-row
	// 			-webkit-flex-direction: column
	// 			-ms-flex-direction: column
	// 			flex-direction: column
	// 			-webkit-align-items: flex-start
	// 			align-items: flex-start
	// 		.form-controll
	// 			margin-right: 0
	// 		.form-group
	// 			width: 100%
 }	// 		margin-top: $header_decor-margin-phone
// $percent1360Vector

// $percentsecondVector



// $percentsmallVector


// $percentphone
//
