.about-home {
	.text-default {}
	.image-wrapper {
		position: absolute;
		right: 0;
		bottom: 0;
		font-size: 0;
		//
		img {
			width: 100%; }
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 81%;

			box-shadow: 1px 4px 21px 8px rgba(0, 0, 0, 0.25);
			z-index: 6; }
		.svg-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 0.4s;
			z-index: 5;
			animation: opacity 4s linear infinite; } }


	.caption {
		position: absolute;
		left: 100%;
		margin-left: -#{$percentVector}vw;
		top: 0;
		width: #{$percentVector}vw;
		opacity: 0.8;
		color: #d4eaf7;
		font-size: 16px;
		font-weight: 300;
		line-height: 30px;
		text-transform: uppercase;
		letter-spacing: 4px;
		@include max(#{$firstbreckpoint}) {
			margin-left: -#{$percent1360Vector}vw;
			width: #{$percent1360Vector}vw; }
		@include max($secondbreckpoint) {
			width: #{$percentsecondVector * 1.5}vw;
			margin-left: -#{$percentsecondVector}vw; }

		@include small {
			position: relative;
			margin-left: #{$percentsmallVector * 1.5}vw;
			left: 0;
			width: auto;
			margin-top: 20px;
			margin-bottom: 20px;
			font-size: 12px;
			line-height: 24px;
			letter-spacing: 3px; }

		@include phone {
			font-size: 12px;
			line-height: 24px;
			letter-spacing: 3px;
			margin-left: #{$percentphoneVector * 1.5}vw; } }
	.flex-row {
		position: relative;
		padding-bottom: 240px;
		p {
			&:first-child {
				margin-top: 0; } }
		@include phone {
			flex-direction: column;
			padding-bottom: 0;
			.text-default, .image-wrapper {
				width: 100%; }
			.text-default {
				margin-bottom: 55px; }
			.image-wrapper {
				position: relative; } } } }

