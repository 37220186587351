@mixin responsive-menu_2 {

	$header__height: 53px;							//	высота шапки на моб. экране


	//	кнопка "показать/скрыть меню"
	$button__bg: transparent;							//	фон кнопки, которая показывает/скрывает меню
	$button__border-radius: none;					//	скругление кнопки, если не нужно - указать 'none'


	//	полоски на кнопке
	$stick__width: 100%;							//	ширина, лучше не менять
	$stick__height: 1px;							//	высота, лучше не менять
	$stick__color: rgba(#d4eaf7, 0.4);						//	цвет полосок
	$stick__color-active: $color__3;					//	цвет полосок, когда меню открыто


	//	навигация вверху меню
	$nav__color: $color__2;
	$nav__font-family: $font-family__1;
	$nav__uppercase: true;							//	(true | false)
	$nav__underline: false;							//	(true | false)
	$nav__bg: $color__3;


	$full__bg: $color__2;							//	фон выезжающего меню


	$item__color: rgba($color__4, 0.4);						//	цвет всех пунктов
	$item__color-focus: rgba($color__4, 0.4);				//	цвет пункта при фокусе(нажатии)
	$item__bg: transparent;							//	фон всех пунктов
	$item__bg-focus: $color__2;						//	фон пункта при фокусе(нажатии)


	$separator__availiable: false;					//	отображать разделитель (true | false)
	$separator__first: true;							//	разделитель перед первым пунктом (true | false)
	$separator__last: true;							//	разделитель после последнего пункта (true | false)
	$separator__height: 1px;
	$separator__color: $color__2;


	$arrow__size: 20px;								//	размер стрелочки для родительских элементов
	$arrow__click-size: 60px;						//	ширина области клика
	$arrow__color: $color__2;
	$arrow__bg: none;
	$arrow__border-left: none;						//	рамка с левой стороны области клика, если не надо - указать 'none'


	//	иконки с количеством товаров (избранное, сравнение, корзина)
	$count__size: 25px;
	$count__margin-right: 15px;						//	отступ от правого края
	$count__color: $color__5;
	$count__font-size: $font-size__5;
	$count__bg: $color__2;
	$count__border: none;
	$count__border-radius: none;						//	скругление


	//	скрываем элементы на больших экранах
	@media only screen and (min-width: 992px) {
		.menu {
			&__wrapper,
			&__toggle,
			&__back,
			&__nav,
			.hidden,
			.button {
				display: none; } } }



	//	все стили работают только на адаптиве (<991px)
	@include small {
		.menu {
			//	фон, который показывается при открытом меню
			&__bg {
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 20;
				visibility: hidden;
				display: none;
				// background-color: rgba(#000, .75)
				// opacity: 0
 }				// transition: all .2s
			.social-menu {
				display: block;
				margin-top: auto;
				.social-media {
					display: flex;
					>div {
						margin-right: 60px; } } }

			//	кнопка, которая показывает/скрывает меню
			&__toggle {
				display: block;
				position: fixed;
				top: 40px;
				right: 15px;
				z-index: 30;
				border: none;
				padding: 0;
				width: 25px;								//	ширина и высота кнопки
				height: 25px;							//	задается как размер всех кнопок на адаптиве
				background-color: $button__bg;


				@include phone {
					right: 15px;
					top: 20px; }

				//	полоски на кнопке
				&_item {
					width: 100%;
					height: 1px;
					position: absolute;
					transition: all .4s;
					background-color: $stick__color;
					&:nth-child(1) {
						top: 0;
						transform-origin: left bottom; }
					&:nth-child(2) {
						bottom: left top; } } }




			//	блок с меню
			&__list {
				position: fixed;
				top: 0;
				width: 100%;
				height: 100vh;
				left: 0;
				z-index: 21;
				transform: translateX(-100%);
				margin: 0;
				padding: 10vh 0 5vh 10vw;
				transition: all 1s;
				overflow-y: auto;
				overflow-x: hidden;
				background: #031c21;
				will-change: transform;
				background: url("/app/images/pattern.jpg");
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				>li {
					opacity: 0;
					transition: all 0.4s;
					transition-delay: 1s; }

				//	все пункты
				li {
					width: 137px;
					list-style-type: none;
					font-size: $font-size__4;
					background-color: $item__bg;
					text-align: left;
					&.active {
						a {
							opacity: 0.4; } }
					&.open {
						>ul {
							right: 0;
							width: 100%;
							transition: all .4s ease-out; } }

					//	все ссылки
					a {
						position: relative;
						display: inline-block;
						padding: 15px 0 15px 00px;
						line-height: 20px;
						color: $item__color;
						text-decoration: none;
						font-size: 14px;
						letter-spacing: 2.8px; } }


				//	все вложенные списки
				ul {
					position: absolute;
					top: 0;
					right: -100vw;
					bottom: 0;
					width: 100%;
					margin: 0;
					padding-left: 0;
					background: $full__bg;
					transition: all .4s ease-in; }

				//	пункты 1-го уровня
				>li {
					//	ссылки только первого уровня
					>a {
						text-transform: uppercase; }

					//	пункты 2-го уровня
					>ul {
						z-index: 22;
						>li {
							//	пункты 3-го уровня
							>ul {
								z-index: 23; } } } } }



			//	если меню открыто
			&.active {
				.menu {
					&__list {
						transform: translateX(0);
						>li {
							opacity: 1; } }
					//	показываем темный фон
					&__bg {
						visibility: visible;
						opacity: 1; }

					//	меняем кнопку
					&__toggle {
						// background: $nav__bg
						&_item {
							background-color: $stick__color-active;
							//	поварачиваем каждый элемент и меняем его положение
							&:nth-child(1) {
								top: 17%;
								left: 12%;
								transform: rotate(45deg); }
							&:nth-child(2) {

								transform: rotate(-45deg); } } } } } } }



	@include phone {
		.social-menu {
			width: 100% !important; }
		.social-media {
			width: 100% !important;
			margin-bottom: 35px;
			>div {
				margin-right: 45px !important; } }


		.menu {
			a {
 } } } }				// letter-spacing: 2px

@include responsive-menu_2;
