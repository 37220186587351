@mixin heroimage_1 {

	$global__bg-path: '../images/foto.jpg';		//	укажите путь до картинки
	$global__margin-top: none;					//	если не используется
	$global__margin-bottom: none;				//	установить значение 'none'
	$global__box-shadow: none;

	$container__bg: none;
	$container__box-shadow: none;


	$content-padding-top: 29vh;
	$content-padding-top-medium: 27vh;
	$content-padding-top-small: 25vh;
	$content-padding-top-phone: 18vh;

	$height: 650px;
	$height-mobile: 300px;

	$padding-top: 140;							//	отступ от верхнего края блока до заголовка (без 'px')
	$padding-right-left: 0;						//	отступ от левого и правого края контейнера до текста (без 'px')
	$padding-top-phone: 40px;					//	отступ от верхнего края на телефонах (без 'px')
	$padding-bottom-phone: 40px;					//	отступ от нижнего края на телефонах (без 'px')

	$color: $color__1;							//	цвет заголовков и текста
	$text-align: left;							//	выравнивание текста и кнопки (left, center, right)

	$header__font-size: 40px;					//	размер шрифта заголовка h1

	$button__margin-top: 35px;					//	верхний отступ до кнопки


	.heroimage {
		position: relative;
		z-index: 2;
		min-height: 100vh;
		@if $global__margin-top != none {
			margin-top: $global__margin-top; }
		@if $global__margin-bottom != none {
			margin-bottom: $global__margin-bottom; }
		@if $global__box-shadow != none {
			box-shadow: $global__box-shadow; }
		overflow: hidden;
		color: $color__4;

		.container {
			position: relative;
			z-index: 4; }

		&__content {
			min-height: 100vh;
			padding-top: $content-padding-top;

			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			align-items: flex-start;
			position: relative;

			@include medium {
				padding-top: $content-padding-top-medium; }
			@include small {
				padding-top: $content-padding-top-small; }
			@include phone {
				padding-top: $content-padding-top-phone;
				flex-direction: column; } }

		ul {
			list-style: none;
			padding-left: 0;
			margin: 0;
			color: $color__4; }

		.city-list {
			opacity: 0.8;
			font-size: 16px;
			font-weight: 300;
			line-height: 1.9;
			text-transform: uppercase;
			letter-spacing: 4px;
			position: relative;
			padding-left: 1vw;

			&:after {
				content: '';
				position: absolute;
				width: 1px;
				height: 90%;
				box-shadow: 2px 8px 20px 8px rgba(0,0,0,.2), 0 0 1px 1px rgba(0,189,197,.5);
				background-color: #039ea4;
				top: 5%;
				left: 0; }

			@include max(#{$firstbreckpoint}) {
				letter-spacing: 4px; }

			@include max($secondbreckpoint);
			@include small {
				font-size: 12px; }
			@include phone {
				padding-left: 15px; } }

		.types-light {
			opacity: 0.8;
			font-size: 25px;
			font-weight: 300;
			line-height: 60px;
			text-transform: uppercase;
			letter-spacing: 6.25px;
			position: relative;
			.types-light__item {
				overflow: hidden; }
			.active {
				p span {
					transform: translate3d(0,0,0); } }
			p {
				margin: 0;
				display: inline-block; }
			p span {
				display: inline-block;
				transform: translate3d(0,100%,0);
				transition: all 1000ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
				will-change: transform; }

			&:after {
				content: '';
				width: 20vw;
				height: 20vw;
				position: absolute;
				top: 50%;
				left: 200px;
				border-radius: 50%;
				transform: translate3d(-1vw, -5vw, 0);
				z-index: -1;
				background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, rgba(#091e23, 0.21) 54%, rgba(#071a1e, 0.21) 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%);
				opacity: 0; }
			@include max(#{$firstbreckpoint}) {
				color: #ffffff;
				font-size: 22px;
				line-height: 2.5;
				letter-spacing: 5.5px;
				&:after {
					width: 20vw;
					height: 20vw;
					position: absolute;
					top: 50%;
					left: 230px;
					border-radius: 50%;
					transform: translate3d(-10vw, -10vw, 0);
					opacity: 0.5; } }
			@include max($secondbreckpoint) {
				&:after {
					width: 26vw;
					height: 26vw;
					position: absolute;
					top: 50%;
					left: 250px;
					border-radius: 50%;
					transform: translate3d(-13vw, -13vw, 0);
					opacity: 0.5; } }
			@include small {
				font-size: 20px;
				letter-spacing: 5px; }
			@include phone {
				font-size: 16px;
				&:after {
					width: 240px;
					height: 240px;
					position: absolute;
					top: 50%;
					left: 10px;
					border-radius: 50%;
					transform: translate3d(0, -120px, 0);
					/* Style for "Эллипс 1" */ } } }



		.col-left {
			width: calc(#{$percent} * 6);
			@include max(#{$firstbreckpoint}) {
				width: calc(#{$percent1360} * 4); }

			@include max($secondbreckpoint) {
				width: calc(#{$percentsecond} * 5); }
			@include small {
				width: calc(#{$percentsmall} * 4); }
			@include phone {
				width: calc(#{$percentphone} * 2); } }


		.col-right {
			@include phone {
				margin-top: -31px;
				margin-bottom: 25px;
				margin-left: calc(#{$percentphone} * 2); } }
		.col-bottom {
			width: 100%;
			margin-top: auto;
			margin-bottom: 40px;
			@include phone {
				margin-top: 0;
				display: none; } }

		.social-media {
			display: flex;

			li {
				min-width: $percent;
				@include max(#{$firstbreckpoint}) {
					min-width: $percent1360; }
				@include max($secondbreckpoint) {
					min-width: $percentsecond; } }
			a {
				text-decoration: none;
				opacity: 0.5;
				color: #9fbdce;
				font-size: 16px;
				font-weight: 300;
				line-height: 30px;
				letter-spacing: 2.4px;
				@include max($secondbreckpoint) {
					font-size: 14px; }
				@include small {
					font-size: 12px; } } }

		.verticalline {
			position: absolute;
			right: 0;
			bottom: 40px;
			top: $content-padding-top;
			-webkit-writing-mode: vertical-lr;
			-ms-writing-mode: tb-lr;
			writing-mode: vertical-lr;
			text-transform: uppercase;
			opacity: 0.8;
			text-shadow: 1px 5px 3px rgba(0, 0, 0, 0.18);
			font-size: 16px;
			font-weight: 300;
			line-height: 1;
			text-transform: uppercase;
			letter-spacing: 4.8px;
			user-select: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			span {

				height: 49px;
				width: 9px;
				display: flex;
				// transform: rotate(90deg) translateX(-50%) translateY(150%)
				opacity: 0.9;
				svg {
					fill: #ffffff; } }
			@include max(#{$firstbreckpoint}) {
				font-size: 14px;
				letter-spacing: 4.2px; }
			@include small {
				font-size: 12px; }



			@include medium {
				top: $content-padding-top-medium; }
			@include small {
				top: $content-padding-top-small; }
			@include phone {
				display: none; } }


		.house {
			position: absolute;
			right: 0;
			bottom: 0;
			transform: translateY(10%);
			width: 73vw;
			z-index: 1;
			&:after {
				content: '';
				width: 23vw;
				height: 23vw;
				position: absolute;
				top: 50%;
				left: 200px;
				border-radius: 50%;
				opacity: .5;
				transform: translate3d(-20vw, -14vw, 0);
				z-index: -1;
				// background-image: linear-gradient(-216deg, rgba(7, 26, 30, 0.20) 0%, rgba(100, 240, 254, 0.1) 50%, rgba(13, 35, 39, 0.5) 75% rgba(13, 35, 39, 0) 100%)
				background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, rgba(#091e23, 0.21) 54%, rgba(#071a1e, 0.21) 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%); }
			@include max(#{$firstbreckpoint}) {
				&:after {
					display: none; } }
			&.active {
				.svg-wrapper {
					animation: light 6s linear infinite; }
				.image-wrapper {
					opacity: 1; }
				img {
					filter: grayscale(0%); } }
			@include small {
				left: 0;
				width: 110%;
				transform: translateX(-10vw); }
			@include phone {
				left: 0;
				width: 130vw;
				transform: translateX(-30vw); }


			.image-wrapper {
				opacity: 0;
				transition: all 2s;
				position: relative;
				z-index: 1; }
			img {
				filter: grayscale(100%);
				transition: all 2s;
				transition-delay: 0.5s;
				max-width: 100%; }

			.svg-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: all 1s;
				z-index: 5;
				opacity: 0;


				// +small
 } }				// 	animation: none




		.header_1,
		.header_1-small {
			opacity: 0.8;
			font-size: 90px;
			font-weight: 400;
			line-height: 1;
			letter-spacing: 12.75px;
			text-align: left;
			margin-bottom: 12vh;
			overflow: hidden;
			p,span {
				display: inline-block;
				margin: 0; }
			span {
				transform: translatey(100%);
				transition: all 0.4s;
				will-change: transform; }
			&.active {
				span {
					transform: translatey(0%); } }
			&.done {
				span {
					will-change: unset; } }
			@include max(#{$firstbreckpoint}) {

				font-size: 70px;
				letter-spacing: 9.92px;
				margin-bottom: 8vh; }
			@include small {
				font-size: $font-size__1; }
			@include phone {
				font-size: $font-size__2;
				margin-bottom: 7vh; } }

		.text-default {
			margin-bottom: 0;
			color: $color;
			text-align: $text-align; }

		// button
		// 	border: none
		// 	background: transparent
		// 	padding: 01
		// 	margin: 0
		// 	text-shadow: 0 0 35px rgba(0, 0, 0, 0.36)
		// 	color: rgba(#ffffff, 0.8)
		// 	font-family: $font-family__1
		// 	font-size: 18px
		// 	font-weight: 400
		// 	line-height: 1.6
		// 	text-transform: uppercase
		// 	letter-spacing: 5px
		// 	cursor: pointer
		// 	height: calc(10vh + 40px)
		// 	min-width: $percent
		// 	text-align: center
		// 	box-shadow: 0 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 1px 1px rgba(0, 189, 197, 0.35)
		// 	border: 2px solid #039ea4
		// 	position: relative
		// 	z-index: 5
		// 	overflow: hidden
		// 	&:hover
		// 		&:after
		// 			transform: translateX(0)
		// 	&:after
		// 		position: absolute
		// 		width: 100%
		// 		height: 100%
		// 		content: ''
		// 		background-color: #039ea4
		// 		left: 0
		// 		top: 0
		// 		z-index: -1
		// 		transform: translateX(-100%)
		// 		transition: all 0.4s
		// 	+max(#{$firstbreckpoint})
		// 		min-width: $percent1360
		// 	+max($secondbreckpoint)
		// 		font-size: calc(1vw + 2px)
		// 		min-width: $percentsecond
		// 		letter-spacing: 2px
		// 	+small
		// 		font-size: 14px
		// 		min-width: $percentsmall
		// 		padding-left: 20px
		// 		padding-right: 20px
		// 	+phone
		// 		min-width: calc(#{$percentphone} * 2)
		.button-wrapper {
			position: relative;
			margin-top: 140px;
			@include phone {
				margin-top: 10vh;
				&:after {
					width: 240px;
					height: 240px;
					transform: translate3d(-120px, -120px, 0); } }
			&:after {
				content: '';


				position: absolute;
				top: 50%;
				left: 50%;
				border-radius: 50%;
				// transform: translate3d(-9vw, -8vw, 0)
				// transform: translate3d(-150px, -140px, 0)
				z-index: -5;
				background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, #091e23 54%, #071a1e 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%);
				opacity: 0.5; } }


		@include small; } }





@include heroimage_1;
