//	Цвета
//
$color__1: #d4eaf7;
$color__2: #5f5;
$color__3: #aaa;					//	желательно указывать белый цвет сайта
$color__4: #d4eaf7;					//	цвет обычного текста
$color__5: #039ea4;


//	Шрифты
//	объявляются в файле /general/fonts.sass
//
$font-family__1: 'Roboto Condensed', sans-serif;
$font-family__2: 'Roboto Condensed', sans-serif;

$font-size__1: 50px; 			//	размер заголовка h1, заголовков модулей
$font-size__2: 25px; 			//	размер заголовка h2
$font-size__3: 22px;				//	размер заголовка h3
$font-size__4: 18px;				//	размер заголовка h4, обычного текста
$font-size__5: 16px;				//	Доп. размер
$font-size__6: 14px;				//	копирайт

// medium
$font-size__1-medium: 40px; 			//	размер заголовка h1, заголовков модулей
$font-size__2-medium: 24px; 			//	размер заголовка h2
$font-size__3-medium: 20px;			//	размер заголовка h3
$font-size__4-medium: 16px;			//	размер заголовка h4, обычного текста
$font-size__5-medium: 14px;			//	Доп. размер
$font-size__6-medium: 14px;			//	копирайт

// small
$font-size__1-small: 30px; 			//	размер заголовка h1, заголовков модулей
$font-size__2-small: 20px; 			//	размер заголовка h2
$font-size__3-small: 18px;			//	размер заголовка h3
$font-size__4-small: 14px;			//	размер заголовка h4, обычного текста
$font-size__5-small: 12px;			//	Доп. размер
$font-size__6-small: 12px;			//	копирайт

// phone
$font-size__1-phone: 20px; 			//	размер заголовка h1, заголовков модулей
$font-size__2-phone: 16px; 			//	размер заголовка h2
$font-size__3-phone: 14px;			//	размер заголовка h3
$font-size__4-phone: 14px;			//	размер заголовка h4, обычного текста
$font-size__5-phone: 12px;			//	Доп. размер
$font-size__6-phone: 12px;			//	копирайт



$font-size__heroimage: 90;
$font-size__heroimage-medium: 70;
$font-size__heroimage-small: 70;
$font-size__heroimage-phone: 35;




//	Настройки для обычного блока с текстом
//	и других блоков, которые считаются контентом сайта
//
//	указывать без 'px'
$content__padding-top: 50;													//	отступ от верхнего края контейнера до текста или другого контента
$content__padding-right-left: 120;											//	отступы с левого и правого края
$content__padding-bottom: 90;												//	отступ снизу



// Разбивка паддингов по цветам

$content__pading-red: 240;
$content__pading-orange: 140;
$content__pading-yellow: 70;
$content__pading-green: 55;
$content__pading-blue: 30;



/*   Lines    */
$deltabrowsersync: 0;
$pluspercent: 8vw;
$percent: 10.5vw;
$percentVector: 10.5;


$firstbreckpoint: 1680px;
$pluspercent1360: 5.5vw;
$percent1360: 14.8vw;
$percent1360Vector: 14.8;

$secondbreckpoint: 1300px;
$pluspercentsecond: 2vw;
$percentsecond: 12vw;
$percentsecondVector: 12;

// $percentsecond: 11.659vw
// $percentsecondVector: 11.659

// small
$pluspercentsmall: 2vw;
$percentsmall: 16vw;
$percentsmallVector: 16;

// phone
$pluspercentphone: 2vw;
$percentphone: 23.9vw;
$percentphoneVector: 23.9;



$header_decor-margin: 99px;
$header_decor-margin-medium: 80px;
$header_decor-margin-small: 70px;
$header_decor-margin-phone: 60px;

//	----------------------------------------------------------------------	//


//	Настройки для заголовка <h1>, миксина '=h1'
$h1__margin: 70px;									//	большой нижний отступ, используется в заголовках блоков
$h1__margin-small: 30px;								//	малый нижний отступ, используется в заголовках обычного текста, некоторых блоков
$h1__color: $color__1;								//	цвет
$h1__family: $font-family__1;						//	семейство шрифта
$h1__weight: 400;									//	толщина(жирность)
$h1__uppercase: true;								//	верхний регистр (true | false)
$h1__line-height: 36px;								//	высота линии заголовка
$h1__letter-spacing: default;						//	межбуквенный отступ, указывать в px, em, либо 'default' - браузер возьмет стандратный
$h1__align: left;									//	выравнивание текста в заголовке (left | center | right)

//	Общие настройки для заголовков <h2>, <h3>, <h4> и миксинов '=h2', '=h3', '=h4'
$hd__color: $color__1;								//	цвет
$hd__family: $font-family__1;						//	семейство шрифта
$hd__weight: 400;									//	толщина(жирность)
$hd__uppercase: true;								//	верхний регистр (true | false)

//	Настройки для заголовка <h2>, миксина '=h2'
$h2__margin-top: 24px;
$h2__margin-bottom: 24px;
$h2__line-height: 30px;

//	Настройки для заголовка <h3>, миксина '=h3'
$h3__margin-top: 24px;
$h3__margin-bottom: 24px;
$h3__line-height: 24px;

//	Настройки для заголовка <h4>, миксина '=h4'
$h4__margin-top: 24px;
$h4__margin-bottom: 24px;
$h4__line-height: 20px;


//	настройки обычного текста
$text__color: rgba($color__4, 0.4);
$text__font-size: $font-size__4;
$text__font-size-phone: $font-size__5;
$text__font-family: $font-family__1;
$text__font-weight: 300;

//	 Ссылки в тексте
$link__color: rgba($color__4, 0.4);
$link__color-hover: rgba($color__4, 0.2);
$link__font-weight: $text__font-weight;
$link__underline: false;
$link__underline-color: $color__1;
$link__underline-hover: false;
$link__underline-color-hover: red;



//	Полоса прокрутки
$scroll__width: 8px;														//	ширина полосы
$scroll__bg: rgba($color__5, 0.3);														//	фон полосы
$scroll__slider: rgba($color__5, 1);													//	цвет ползунка




//	Настройки для кнопки по умолчанию
$button-d__min-width: 220px;													//	минимальная ширина кнопки
$button-d__height: 55px;
$button-d__padding-right-left: 20px;											//	ширина полей, которые будут добавлены, если кнопка больше минимальной ширины

$button-d__color: $color__4;
$button-d__color-hover: $color__4;
$button-d__font-size: $text__font-size;
$button-d__font-family: $font-family__1;
$button-d__font-weight: 400;
$button-d__text-transform: uppercase;
$button-d__text-decoration: none;
$button-d__text-decoration-hover: none;

$button-d__border-width: none;												//	установить 'none', если не используется
$button-d__border-color: $color__1;
$button-d__border-color-hover: $color__1;
$button-d__border-radius: none;
$button-d__border-radius-hover: none;
$button-d__bg: $color__1;
$button-d__bg-hover: $color__2;
$button-d__box-shadow: none;
$button-d__box-shadow-hover: none;
$button-d__scale-hover: none;


//	Настройки для инвертированной кнопки
$button-i__min-width: 220px;													//	минимальная ширина кнопки
$button-i__height: 55px;
$button-i__padding-right-left: 20px;											//	ширина полей, которые будут добавлены, если кнопка больше минимальной ширины

$button-i__color: $color__1;
$button-i__color-hover: $color__4;
$button-i__font-size: $text__font-size;
$button-i__font-family: $font-family__1;
$button-i__font-weight: 400;
$button-i__text-transform: uppercase;
$button-i__text-decoration: none;
$button-i__text-decoration-hover: none;

$button-i__border-width: 2px;												//	установить 'none', если не используется
$button-i__border-color: $color__1;
$button-i__border-color-hover: $color__1;
$button-i__border-radius: none;
$button-i__border-radius-hover: none;
$button-i__bg: transparent;
$button-i__bg-hover: $color__1;
$button-i__box-shadow: none;
$button-i__box-shadow-hover: none;
$button-i__scale-hover: none;




//	Настройки для инпута, применяется ко всему сайту
$input-d__type: transparent;														//	тип инпута (static | transparent | float)
$input-d__width: 309px;
$input-d__height: 45px;
$input-d__padding-right-left: 10px;
$input-d__padding-top: 0px;




$input-d__color: rgba($color__1, 0.4);
$input-d__color-focus: rgba($color__1, 0.4);
$input-d__font-size: $font-size__5;
$input-d__font-family: $font-family__1;
$input-d__font-weight: 300;
$input-d__text-align: left;													//	выравнивание текста в инпуте (left | center | right)

$input-d__border-width: 1px;													//	установить 'none', если не используется
$input-d__border-color: rgba($color__4, 0.2);
$input-d__border-color-focus: rgba($color__4, 0.2);
$input-d__border-radius: none;
$input-d__border-radius-focus: none;
$input-d__background-color: transparent;
$input-d__background-color-focus: transparent;
$input-d__box-shadow: none;
$input-d__box-shadow-focus: none;


//	Настройки для подписи к инпуту
$label-d__color: rgba($color__1, 0.4);
$label-d__color-focus: rgba($color__1, 0.4);
$label-d__font-size: $font-size__5;
$label-d__font-size-focus: $font-size__5;
$label-d__font-family: $font-family__1;
$label-d__font-weight: 300;
$label-d__font-weight-focus: 300;


//	Настройки для чекбокса, применяется ко всему сайту
$checkbox__size: 16px;														//	высота будет такая же, как и ширина, это же чекбокс
$checkbox__border: 1px solid rgba($color__4, 0.4);										//	рамка
$checkbox__border-checked: 1px solid rgba($color__4, 0.4);								//	рамка отмеченного чекбокса
$checkbox__border-radius: none;
$checkbox__box-shadow: none;
$checkbox__box-shadow-checked: none;
$checkbox__bg: transparent;													//	фоновый цвет чекбокса
$checkbox__bg-checked: transparent;											//	фоновый цвет отмеченного чекбокса
$checkbox__stick-color: $color__2;											//	цвет галочки


//	Настройки для выпадающих списков(select)
$select__icon-width: 12px;													//	ширина
$select__icon-height: 10px;													//	и высота иконки
$select__icon-margin: 10px;													//	расстояние от края select до иконки
$select__icon-position: 0px 0px;												//	позиция иконки в спрайте




//	Настройки для стрелок слайдеров
$arrows__size: 82px;															//	ширина и высота стрелок
$arrows__bg-color: transparent;
$arrows__bg-color-hover: transparent;
$arrows__stick-color: rgba(#d4eaf7, 0.4);
$arrows__stick-color-hover: rgba(#d4eaf7, 0.3);
$arrows__border: none;
$arrows__border-hover: none;
$arrows__border-radius: none;
$arrows__border-radius-hover: none;
$arrows__scale-hover: none;





//	Настройки для кнопок пагинации
//	параметры -hover применяются также для текущей страницы
$pagination__space-between: 5px;												//	расстояние между кнопками
$pagination__size: 32px;
$pagination__color: $color__1;
$pagination__color-hover: $color__2;
$pagination__font-weight: 400;
$pagination__font-weight-hover: 400;
$pagination__border-width: none;
$pagination__border-color: $color__1;
$pagination__border-color-hover: $color__2;
$pagination__border-radius: none;
$pagination__background-color: none;											//	установить 'none', если не используется
$pagination__background-color-hover: none;									//	установить 'none', если не используется




$responsive-button: 82px;													//	размер всех кнопок, инпутов, стрелок на разрешениях меньше 992px




$wrong: 'Нельзя указывать такое значение';
