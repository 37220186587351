//	z-index:
//	0-10: базовые слои - текст, слайдер, обычные блоки типа преимуществ, карты, блоки с параллаксом
//	15: кнопка наверх
//	20-25: меню
//	25-30: всплывающие окна

html {
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased; }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }
html {
	transition: all 0s;
	margin: 0;
	padding: 0;
	&.overlay-active,
	&.menu-open {
		overflow-y: hidden !important; } }
body {
	position: relative;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	margin: 0;
	width: 100vw;
	font-family: $font-family__1;
	transition: all 0s, opacity .3s;
	opacity: 0;
	overflow-x: hidden;
	text-size-adjust: none;
	background: #031c21;

	// scroll-snap-type: y proximity
	// scroll-snap-points-x: repeat(100%)

	background-image: url("/app/images/pattern.jpg");
	-webkit-overflow-scrolling: touch;
	scrollbar-face-color: $scroll__slider;
	scrollbar-track-color: $scroll__bg;
	&::-webkit-scrollbar {
		width: $scroll__width; }
	&::-webkit-scrollbar-thumb {
		background: $scroll__slider; }
	&::-webkit-scrollbar-track {
		background: $scroll__bg; }

	// >.about-home
	// 	scroll-snap-align: start

	// .type-lights, .form-desc, .portfolio-home, .confidence
	// 	scroll-snap-align: start
	// 	scroll-margin: 100px
	// 	+max(#{$firstbreckpoint})
	// 		scroll-snap-align: start
	// 		scroll-margin: 90px
	// 	+max($secondbreckpoint)
	// 		scroll-snap-align: start
	// 		scroll-margin: 80px

	.telnayshka {
		width: 1px;
		background-color: #5f6d94;
		opacity: 0.15;
		position: absolute;
		height: 100%;
		z-index: -1;
		&:nth-child(#{$deltabrowsersync + 1}) {
			left: calc(#{$pluspercent} + #{$percent} * 0); }
		&:nth-child(#{$deltabrowsersync  + 2}) {
			left: calc(#{$pluspercent} + #{$percent} * 1); }
		&:nth-child(#{$deltabrowsersync + 3}) {
			left: calc(#{$pluspercent} + #{$percent} * 2); }
		&:nth-child(#{$deltabrowsersync + 4}) {
			left: calc(#{$pluspercent} + #{$percent} * 3); }
		&:nth-child(#{$deltabrowsersync + 5}) {
			left: calc(#{$pluspercent} + #{$percent} * 4); }
		&:nth-child(#{$deltabrowsersync + 6}) {
			left: calc(#{$pluspercent} + #{$percent} * 5); }
		&:nth-child(#{$deltabrowsersync + 7}) {
			left: calc(#{$pluspercent} + #{$percent} * 6); }
		&:nth-child(#{$deltabrowsersync + 8}) {
			left: calc(#{$pluspercent} + #{$percent} * 7); }
		&:nth-child(#{$deltabrowsersync + 9}) {
			left: calc(#{$pluspercent} + #{$percent} * 8); }
		@include max(#{$firstbreckpoint}) {
			&:nth-child(#{$deltabrowsersync + 1}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 0); }
			&:nth-child(#{$deltabrowsersync  + 2}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 1); }
			&:nth-child(#{$deltabrowsersync + 3}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 2); }
			&:nth-child(#{$deltabrowsersync + 4}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 3); }
			&:nth-child(#{$deltabrowsersync + 5}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 4); }
			&:nth-child(#{$deltabrowsersync + 6}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 5); }
			&:nth-child(#{$deltabrowsersync + 7}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 6); }
			&:nth-child(#{$deltabrowsersync + 8}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 7); }
			&:nth-child(#{$deltabrowsersync + 9}) {
				left: calc(#{$pluspercent1360} + #{$percent1360} * 8); } }
		@include max($secondbreckpoint) {
			&:nth-child(#{$deltabrowsersync + 1}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 0)); }
			&:nth-child(#{$deltabrowsersync  + 2}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 1)); }
			&:nth-child(#{$deltabrowsersync + 3}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 2)); }
			&:nth-child(#{$deltabrowsersync + 4}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 3)); }
			&:nth-child(#{$deltabrowsersync + 5}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 4)); }
			&:nth-child(#{$deltabrowsersync + 6}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 5)); }
			&:nth-child(#{$deltabrowsersync + 7}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 6)); }
			&:nth-child(#{$deltabrowsersync + 8}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 7)); }
			&:nth-child(#{$deltabrowsersync + 9}) {
				left: calc(#{$pluspercentsecond} + (#{$percentsecond} * 8)); } }
		@include small {
			&:nth-child(#{$deltabrowsersync + 1}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 0)); }
			&:nth-child(#{$deltabrowsersync  + 2}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 1)); }
			&:nth-child(#{$deltabrowsersync + 3}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 2)); }
			&:nth-child(#{$deltabrowsersync + 4}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 3)); }
			&:nth-child(#{$deltabrowsersync + 5}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 4)); }
			&:nth-child(#{$deltabrowsersync + 6}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 5)); }
			&:nth-child(#{$deltabrowsersync + 7}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 6)); }
			&:nth-child(#{$deltabrowsersync + 8}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 7)); }
			&:nth-child(#{$deltabrowsersync + 9}) {
				left: calc(#{$pluspercentsmall} + (#{$percentsmall} * 8)); } }

		@include phone {
			&:nth-child(#{$deltabrowsersync + 1}) {
				left: calc(#{$pluspercentphone} + #{$percentphone} * 0); }
			&:nth-child(#{$deltabrowsersync  + 2}) {
				left: calc(#{$pluspercentphone} + #{$percentphone} * 1); }
			&:nth-child(#{$deltabrowsersync + 3}) {
				left: calc(#{$pluspercentphone} + #{$percentphone} * 2); }
			&:nth-child(#{$deltabrowsersync + 4}) {
				left: calc(#{$pluspercentphone} + #{$percentphone} * 3); }
			&:nth-child(#{$deltabrowsersync + 5}) {
				left: calc(#{$pluspercentphone} + #{$percentphone} * 4); }
			&:nth-child(#{$deltabrowsersync + 6}) {
				display: none;
				left: calc(#{$pluspercentphone} + #{$percentphone} * 5); }
			&:nth-child(#{$deltabrowsersync + 7}) {
				display: none;
				left: calc(#{$pluspercentphone} + #{$percentphone} * 6); }
			&:nth-child(#{$deltabrowsersync + 8}) {
				display: none;
				left: calc(#{$pluspercentphone} + #{$percentphone} * 7); }
			&:nth-child(#{$deltabrowsersync + 9}) {
				left: calc(#{$pluspercentphone} + #{$percentphone} * 8);
				display: none; } }
		// &:nth-child(#{$deltabrowsersync + 9})
 }		// 	right: calc(#{$pluspercent})

	&.loaded,
	&#tinymce,
	&#mceTemplatePreview {
		opacity: 1; }

	&#tinymce,
	&#mceTemplatePreview {
		background: #FFF; }

	&.overlay-active,
	&.menu-open {
		overflow-y: hidden !important; }
	@media only screen and (min-width: 992px) {
		&.overlay-active {
 } }			// padding-right: $scroll__width
	>div {
		transition: all 0s; } }

.chunk-word {
	overflow: hidden;
	p,span {
		display: inline-block;
		margin: 0;
		span {
			transition: all 1.2s cubic-bezier(.19,1,.22,1); } }
	&:hover {
		span {
			will-change: transform;
			transform: rotateX(360deg); } } }

.container {
	margin-right: auto;
	margin-left: auto;
	width: 84%;
	@include max(#{$firstbreckpoint}) {
		width: 89%; }
	@include max(#{$secondbreckpoint}) {
		width: 96%; }

	@include small;

	@include phone; }




.home-page {
	.header_1 {
		.header-chunk {
			overflow: hidden;
			p,span {
				display: inline-block;
				margin: 0;
				transition: all 0.4s; }


			span {
				transform: translateY(100%);
				will-change: transform; } } }
	.scrolled {
		.header-chunk {
			overflow: hidden;
			span {
				transform: translateY(0%); } } }
	.done {
		.header-chunk {
			overflow: hidden;
			span {
				will-change: unset; } } } }




.header_1 {
	position: relative;
	@include h1; }
.header_1-small {
	position: relative;
	@include h1-small; }
.header_2 {
	position: relative;
	@include h2; }
.header_3 {
	position: relative;
	@include h3; }
.header_4 {
	position: relative;
	@include h4; }


.content.red {
	@include content-padding-color($content__pading-red); }

.content.orange {
	@include content-padding-color($content__pading-orange); }

.content.yellow {
	@include content-padding-color($content__pading-yellow); }

.content.green {
	@include content-padding-color($content__pading-green); }

.content.blue {
	@include content-padding-color($content__pading-blue); }

.flex-row {
	display: flex; }


.image-wrapper {
	font-size: 0;
	user-select: none;

	img {
		max-width: 100%; } }
.one-col {
	width: #{$percentVector}vw;
	flex-shrink: 0;
	@include max(#{$firstbreckpoint}) {
		width: #{$percent1360Vector}vw; }
	@include max($secondbreckpoint) {
		width: #{$percentsecondVector}vw; }
	@include small {
		width: #{$percentsmallVector}vw; }
	@include phone {
		width: #{$percentphoneVector}vw; } }

.two-col {
	width: #{$percentVector * 2}vw;
	flex-shrink: 0;
	@include max(#{$firstbreckpoint}) {
		width: #{$percent1360Vector * 2}vw; }
	@include max($secondbreckpoint) {
		width: #{$percentsecondVector * 2}vw; }
	@include small {
		width: #{$percentsmallVector * 2}vw; }
	@include phone {
		width: #{$percentphoneVector * 2}vw; } }


.three-col {
	width: #{$percentVector * 3}vw;
	flex-shrink: 0;
	@include max(#{$firstbreckpoint}) {
		width: #{$percent1360Vector * 3}vw; }
	@include max($secondbreckpoint) {
		width: #{$percentsecondVector * 3}vw; }
	@include small {
		width: #{$percentsmallVector * 3}vw; }
	@include phone {
		width: 100%; } }


.four-col {
	width: calc(#{$percentVector * 4}vw);
	flex-shrink: 0;
	@include max(#{$firstbreckpoint}) {
		width: calc(#{$percent1360Vector * 4}vw); }
	@include max($secondbreckpoint) {
		width: calc(#{$percentsecondVector * 4}vw); }
	@include small {
		width: calc(#{$percentsmallVector * 4}vw); }
	@include phone {
		width: 100%; } }


.arrow-wrapper {
	display: flex; }

.c-small-circle {
	display: flex;
	fill: none;
	stroke-miterlimit: 10;
	stroke-width: 0.4;
	transition: stroke-dashoffset .5s linear,stroke .5s cubic-bezier(.075,.82,.165,1);
	transition-delay: 0s;
	stroke-dasharray: 90;
	stroke-dashoffset: 90;
	stroke: $color__5;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 4; }


.c-small-outline {
	display: flex;
	fill: none;
	stroke-miterlimit: 5;
	stroke-width: 0.3;
	transition-delay: 0s;
	stroke-dasharray: 90;
	stroke-dashoffset: 0;
	stroke: $color__5;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 5;
	opacity: 0;
	left: 0; }

@keyframes arrow {
	0% {
		stroke-dashoffset: 90; }

	// 50%
	// 	stroke-dashoffset: -90

	100% {
		stroke-dashoffset: 0; } }
.arrow-next {
	.c-small-outline {
		opacity: 0.3; } }


.arrow-prev {
	.c-small-circle {
		stroke-dashoffset: -90;
		transition: all 0.4s; }
	&:hover {
		.c-small-circle {
			stroke-dashoffset: 0; } } }

.arrow-next:not(.slick-disabled) {
	.c-small-circle {
		animation: arrow 4.3s linear infinite; } }

.arrow-next.clear:not(.slick-disabled) {
	.c-small-circle {
		stroke-dashoffset: 90 !important;
		animation: none;
		transition: stroke-dashoffset .3s linear,stroke .3s cubic-bezier(.075,.82,.165,1) !important; } }


.caption-left-line {
	position: relative;
	padding-left: 25px;
	@include max($secondbreckpoint) {
		padding-left: 15px; }
	@include small {
		padding-left: 12px; }
	&:after {
		content: "";
		position: absolute;
		width: 1px;
		box-shadow: rgba(0, 0, 0, 0.21) 2px 8px 18px 6px, rgba(0, 189, 197, 0.35) 0px 0px 1px 1px;
		background-color: rgb(3, 158, 164);
		height: 80%;
		left: 0px;
		top: 10%; } }


.header_1.half-col-padding {
	margin-top: $header_decor-margin;
	@include medium {
		margin-top: $header_decor-margin-medium; }
	@include small {
		margin-top: $header_decor-margin-small; }
	@include phone {
		margin-top: $header_decor-margin-phone; } }

.header_decor {
	position: absolute;
	width: #{$percentVector}vw;
	// height: calc(#{$percentVector}vw * 1.4)
	display: block;
	left: 0;
	flex-shrink: 0;
	// background-color: red !important
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	top: 0;
	transform: translateY(-40%);
	z-index: -1;
	font-size: 0;



	img {
		width: 100%;
		opacity: 0; }
	@include max(#{$firstbreckpoint}) {
		width: #{$percent1360Vector}vw;
 }		// height: calc(#{$percent1360Vector}vw * 1.4)
	@include max($secondbreckpoint) {
		width: #{$percentsecondVector}vw;
 }		// height: calc(#{$percentsecondVector}vw * 1.4)
	@include small {
		width: #{$percentsmallVector}vw;
 }		// height: calc(#{$percentsmallVector}vw * 1.4)
	@include phone {
		width: #{$percentphoneVector}vw;
 } }		// height: calc(#{$percentphoneVector}vw * 1.4)

.half-col-padding {
	padding-left: #{$percentVector / 2}vw;
	flex-shrink: 0;
	@include max(#{$firstbreckpoint}) {
		padding-left: #{$percent1360Vector / 2}vw; }
	@include max($secondbreckpoint) {
		padding-left: #{$percentsecondVector / 2}vw; }
	@include small {
		padding-left: #{$percentsmallVector / 2}vw; }
	@include phone {
		padding-left: #{$percentphoneVector / 2}vw; } }



@keyframes opacity {
	0% {
		opacity: 0; }
	50% {
		opacity: 1; }
	100% {
		opacity: 0; } }

@keyframes light {

	0% {
		opacity: 0.2; }
	50% {
		opacity: 1; }
	100% {
		opacity: 0.2; }



	// 0%
	// 	filter: url(#f0)
	// 5%
	// 	filter: url(#f1)
	// 10%
	// 	filter: url(#f2)
	// 15%
	// 	filter: url(#f3)
	// 20%
	// 	filter: url(#f4)
	// 25%
	// 	filter: url(#f5)
	// 30%
	// 	filter: url(#f6)
	// 35%
	// 	filter: url(#f7)
	// 40%
	// 	filter: url(#f8)
	// 45%
	// 	filter: url(#f9)
	// 50%
	// 	filter: url(#f10)
	// 55%
	// 	filter: url(#f9)
	// 60%
	// 	filter: url(#f8)
	// 65%
	// 	filter: url(#f7)
	// 70%
	// 	filter: url(#f4)
	// 75%
	// 	filter: url(#f5)
	// 80%
	// 	filter: url(#f4)
	// 85%
	// 	filter: url(#f3)
	// 90%
	// 	filter: url(#f2)
	// 95%
	// 	filter: url(#f1)
	// 100%
 }	// 	filter: url(#f0)



.text-default {
	color: $text__color;
	font-size: $font-size__4;
	font-family: $text__font-family;
	font-weight: $text__font-weight;
	line-height: 1.8em;
	letter-spacing: 1.28px;
	@include medium {
		font-size: $font-size__4-medium;
		line-height: 30px; }
	@include small {
		font-size: $font-size__4-small; }
	@include phone {
		font-size: $font-size__4-phone;
		line-height: 25px; }
	a {
		color: $link__color;
		font-weight: $link__font-weight;
		text-decoration: none;
		@if $link__underline == true {
			box-shadow: inset 0 -1px $link__underline-color; }
		@if $link__underline-hover == true {
			&:hover {
				box-shadow: inset 0 -1px $link__underline-color-hover; } }
		@else {
			&:hover {
				box-shadow: none; } }
		&:hover {
			color: $link__color-hover; } } }




.caption {
	opacity: 0.8;
	color: $color__1;
	font-size: $font-size__2;
	font-weight: 300;
	line-height: 35px;
	text-transform: uppercase;
	letter-spacing: 6.25px;
	@include max($secondbreckpoint) {
		font-size:  $font-size__2-medium;
		line-height: 32px;
		letter-spacing: 5.25px; }
	@include small {
		font-size: $font-size__2-small;
		letter-spacing: 4.25px; }
	@include phone {
		font-size: $font-size__2-phone;
		letter-spacing: 3.25px; } }


.button {
	border: none;
	background: transparent;
	padding: 0;
	margin: 0;
	text-shadow: 0 0 35px rgba(0, 0, 0, 0.36);
	color: rgba(#ffffff, 0.8);
	font-family: $font-family__1;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.6;
	text-transform: uppercase;
	letter-spacing: 6px;
	position: relative;
	cursor: pointer;
	height: calc(#{$percent} * 0.7);
	min-width: $percent;
	text-align: center;
	box-shadow: 0 8px 18px 6px rgba(0, 0, 0, 0.21), 0 0 1px 1px rgba(0, 189, 197, 0.35);
	border: 1px solid #039ea4;
	position: relative;
	z-index: 5;
	overflow: hidden;
	&:hover {
		&:after {
			transform: translateX(0); } }
	&:after {
		position: absolute;
		width: 100%;
		height: 100%;
		content: '';
		background-color: #039ea4;
		left: 0;
		top: 0;
		z-index: -1;
		transform: translateX(-100%);
		transition: all 0.4s; }

	@include max(#{$firstbreckpoint}) {
		min-width: $percent1360;
		height: calc(#{$percent1360} * 0.7); }
	@include max($secondbreckpoint) {
		font-size: calc(1vw + 2px);
		height: calc(#{$percentsecond} * 0.7);
		min-width: $percentsecond;
		letter-spacing: 2px; }
	@include small {
		font-size: 14px;
		min-width: $percentsmall;
		height: calc(#{$percentsmall} * 0.7);
		padding-left: 20px;
		padding-right: 20px; }
	@include phone {
		min-width: calc(#{$percentphone} * 2);
		height: calc(#{$percentphone} * 1.4); } }


.button-wrapper {
	position: relative;
	&:after {
		content: '';
		width: 16vw;
		height: 16vw;
		width: #{$percentVector * 1.5}vw;
		height: #{$percentVector * 1.5}vw;

		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: 50%;
		// transform: translate3d(-9vw, -8vw, 0)
		transform: translate3d(-150px, -140px, 0);
		z-index: -1;
		background-image: linear-gradient(-54deg, rgba(100, 240, 254, 0.11) 1%, rgba(100, 240, 254, 0.21) 7%, rgba(#091e23, 0.21) 54%, rgba(#071a1e, 0.21) 55%, #071a1e 77%, rgba(7, 26, 30, 0) 100%);
		opacity: 0.5; }


	@include max($firstbreckpoint) {
		&:after {
			width: #{$percent1360Vector * 1.2}vw;
			height: #{$percent1360Vector * 1.2}vw;
			transform: translate3d(-#{$percent1360Vector * 0.6}vw, -#{$percent1360Vector * 0.7}vw, 0); } }

	@include max($secondbreckpoint) {
		&:after {
			width: #{$percentsecondVector * 1.2}vw;
			height: #{$percentsecondVector * 1.2}vw;
			transform: translate3d(-#{$percentsecondVector * 0.6}vw, -#{$percentsecondVector * 0.7}vw, 0); } }
	@include small {
		&:after {
			width: #{$percentsmallVector * 1.2}vw;
			height: #{$percentsmallVector * 1.2}vw;
			transform: translate3d(-#{$percentsmallVector * 0.6}vw, -#{$percentsmallVector * 0.7}vw, 0); } }
	@include phone {
		&:after {
			width: #{$percentphoneVector * 2.1}vw;
			height: #{$percentphoneVector * 2.1}vw;
			transform: translate3d(-#{$percentphoneVector * 1.2}vw, -#{$percentphoneVector * 1}vw, 0); } } }



.button-invert {
	@include button-i;
	&.left {
		margin-right: auto;
		margin-left: 0; }
	&.center {
		margin-right: auto;
		margin-left: auto; }
	&.right {
		margin-right: 0;
		margin-left: auto; } }

a.button {
	@include link-button; }

a.button-i {
	@include link-button-i; }


//	блок с input`ом и подписью к нему
.form-item {
	position: relative;
	width: 100%;
	overflow: hidden;
	&:not(.checkbox) {
		&:after {
			position: absolute;
			content: '';
			height: 100%;
			width: 2px;
			left: 0;
			top: 0;
			transition: all 0.4s;
			background: $color__5;
			transform: translateY(-100%);
 } }			// opacity: 0
	@if $input-d__type != transparent {
		padding-top: $input-d__padding-top;
		@if $input-d__type == float {
			@include phone {
				padding-top: 0; } } }
	input {
		@include input-d; }

	textarea {
		@include input-d;
		resize: vertical; }
	label {
		position: absolute;
		@if $input-d__type == static {
			top: 0;
			height: $input-d__padding-top;
			color: $label-d__color;
			font-size: $label-d__font-size;
			font-family: $label-d__font-family;
			font-weight: $label-d__font-weight;
			text-align: $input-d__text-align;
			line-height: $input-d__padding-top;
			cursor: pointer; }
		@else {
			@if $input-d__type == transparent {
				top: 0;
				right: 0;
				left: $input-d__padding-right-left + $input-d__border-width;
				height: $input-d__height; }
			@else if $input-d__type == float {
				top: $input-d__padding-top;
				right: 0;
				left: $input-d__padding-right-left;
				height: $input-d__height - $input-d__border-width; }
			color: $label-d__color;
			font-size: $label-d__font-size;
			font-family: $label-d__font-family;
			font-weight: $label-d__font-weight;
			line-height: $input-d__height;
			text-align: $input-d__text-align;
			cursor: text;
			@if $input-d__type == transparent {
				//	фикс для 'left', чтобы не было видно, как он приезжает
				transition: opacity .2s, left -1s 1s ease-out; } }
		user-select: none;
		@include phone {
			@if $input-d__type == float {
				top: 0; }
			font-size: $font-size__6; } }

	&.focus {
		&:not(.checkbox) {
			&:after {
				opacity: 1;
				transform: translateY(0); } }
		@if $input-d__type == float {
			@include phone {
 } }				//padding-top: $input-d__padding-top / 2
		label {
			@if $input-d__type == transparent {
				opacity: 0;
				//	делаем 'left' = 100% чтобы 'label' уезжал за край 'input' и можно было спокойно выделять текст в 'input'
				left: 100%;
				//	фикс для 'left', чтобы не было видно, как он уезжает
				transition: opacity .2s, left 1s 1s ease-out; }
			@else if $input-d__type == float {
				top: $input-d__padding-top / 2;
				height: 25px;
				line-height: 25px;
				@include phone {
					top: -10px; } }
			color: $label-d__color-focus;
			font-size: $label-d__font-size-focus;
			font-weight: $label-d__font-weight-focus; } }

	//	блок с чекбоксом
	&.checkbox {
		display: flex;
		padding-top: 0;
		width: unset;
		label {
			font-size: 14px;

			@include phone {
				font-size: 12px; } }
		.wrapper {
			position: relative;
			flex-shrink: 0;
			width: $checkbox__size;
			height: $checkbox__size;
			margin: 0;
			border: $checkbox__border;
			@if $checkbox__border-radius != none {
				border-radius: $checkbox__border-radius; }
			@else {
				border-radius: 0; }
			input {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 4;
				display: block;
				width: $checkbox__size;
				height: $checkbox__size;
				margin: 0;
				padding: 0;
				border: none;

				background-color: transparent;
				opacity: 0;
				appearance: none;
				@include phone {
					//	'margin-top' в 1px задается чтобы чекбокс был на уровне с текстом
					margin-top: 1px; }

				&:hover {
					cursor: pointer; }
				&:checked {
					& + span {
						&:after {
							opacity: 1; } } }
				&:hover {
					cursor: pointer; } }

			span {
				position: absolute;
				z-index: 3;
				width: 100%;
				height: 100%;
				left: 0%;
				top: 0%;
				border: none;
				padding: 2px;
				display: flex;

				&:after {
					display: block;
					content: '';
					width: 90%;
					height: 90%;
					transform: translate3d(5%, 5%,0);
					box-sizing: border-box;
					background-color: $color__5;
					opacity: 0;
					transition: all 0.2s; }


				background-color: $checkbox__bg;
				box-shadow: $checkbox__box-shadow; } }


		label {
			position: relative;
			top: 0;
			height: auto;
			line-height: 1.3em;
			cursor: pointer;
			user-select: none; } } }

.arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $arrows__size;
	height: $arrows__size;

	border-radius: 50%;
	position: relative;
	overflow: hidden;
	.arrow-button {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		padding: 20%; }
	@if $arrows__border != none {
		border: $arrows__border; }
	@else {
		border: none; }
	@if $arrows__border-radius != none {
		border-radius: $arrows__border-radius; }
	background: $arrows__bg-color;
	appearance: none;
	cursor: pointer;
	transition: all .2s;

	&:hover:not(.slick-disabled) {
		@if $arrows__border-hover !=  $arrows__border {
			border: $arrows__border-hover; }
		@if $arrows__border-radius-hover != $arrows__border-radius {
			border-radius: $arrows__border-radius-hover; }
		background: $arrows__bg-color-hover;
		@if $arrows__scale-hover != none {
			transform: scale($arrows__scale-hover); }
		.stick {
			fill: $arrows__stick-color-hover; } }

	@include phone {
		width: $responsive-button;
		height: $responsive-button; }

	.stick {
		transform-origin: 50% 50%;
		fill: $arrows__stick-color; }

	&-prev {
		.stick {
			transform: rotate(180deg); } }
	&-next {
		.stick {
			transform: rotate(0deg); } }

	&.slick-disabled,
	&.disabled {
		cursor: default;
		opacity: .3; } }


.pagination {
	display: flex;
	justify-content: center;
	max-width: 100%;
	overflow-x: auto;
	div {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@if $arrows__size > $pagination__size {
			width: $arrows__size;
			height: $arrows__size; }
		@else {
			width: $pagination__size;
			height: $pagination__size; }
		margin: 0 $pagination__space-between;

		//  общие стили для всех кнопок
		.number {
			display: block;
			width: $pagination__size;
			height: $pagination__size;
			color: $pagination__color;
			text-align: center;
			text-decoration: none;
			@if $pagination__font-weight != 400 {
				font-weight: $pagination__font-weight; }
			@if $pagination__border-width != none {
				line-height: $pagination__size - ($pagination__border-width * 2);
				border: $pagination__border-width solid $pagination__border-color; }
			@else {
				line-height: $pagination__size; }
			@if $pagination__border-radius != none {
				border-radius: $pagination__border-radius; }
			@if $pagination__background-color != none {
				background-color: $pagination__background-color; }
			user-select: none;
			&:hover {
				@if $pagination__color-hover != $pagination__color {
					color: $pagination__color-hover; }
				@if $pagination__font-weight-hover != $pagination__font-weight {
					font-weight: $pagination__font-weight-hover; }
				text-decoration: none;
				@if $pagination__border-color-hover != $pagination__border-color {
					border-color: $pagination__border-color-hover; }
				@if $pagination__background-color-hover != $pagination__background-color {
					background-color: $pagination__background-color-hover; } }

			//  стили для активной кнопки, такие же, как и ховеры у обычных кнопок
			&.active {
				color: $pagination__color-hover;
				@if $pagination__font-weight-hover != none {
					font-weight: $pagination__font-weight-hover; }
				@if $pagination__border-width != none {
					border: $pagination__border-width solid $pagination__border-color-hover; }
				@if $pagination__background-color-hover != none {
					background-color: $pagination__background-color-hover; }
				cursor: default; } } } }

//	svg-элементы по умолчанию занимают всё свободное место
svg {
	width: 100%;
	height: 100%; }

//	фикс для модуля инстграмм
.instashow-popup-media-picture {
	min-height: auto !important; }
@media screen and (max-width: 500px) {
	div.instashow-popup-container {
		position: absolute !important;
		width: 100% !important;
		height: auto !important;
		left: 50% !important;
		top: 50% !important;
		-moz-transform: translate(-50%,-50%) !important;
		-ms-transform: translate(-50%,-50%) !important;
		-webkit-transform: translate(-50%,-50%) !important;
		-o-transform: translate(-50%,-50%) !important;
		transform: translate(-50%,-50%) !important; } }

