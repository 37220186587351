@mixin footer_1 {
	$global__bg: none;
	$global__box-shadow: none;
	$global__margin-top: none;						//	если не используется
	$global__margin-bottom: none;					//	установить значение 'none'

	$container__bg: none;
	$container__box-shadow: none;

	$content__height: 104px;							//	высота шапки
	$content__vertical-align: middle;				//	вертикальное выравнивание шапки(top, middle)
	$content__padding-top: 25px;						//	верхнее внутреннее поле контента
	$content__padding-right: 0px;					//	правое
	$content__padding-bottom: 25px;					//	нижнее
	$content__padding-left: 0px;			 			//	левое

	.footer {
		@include phone {
			margin-bottom: 60px; }
		a {
			text-decoration: none;
			font-size: 16px;
			font-weight: 300;
			line-height: 30px;
			letter-spacing: 2.4px;
			color: rgba($color__4, 0.4);
			@include max($secondbreckpoint) {
				font-size: 14px;
				letter-spacing: 2.1px; }
			@include small {
				font-size: 14px;
				letter-spacing: 1px; }
			@include phone {
				font-size: 12px;
				letter-spacing: 1px;
				line-height: 24px; } }

		.content {
			padding-top: 0 !important;
			padding-bottom: 0 !important; }

		.footer__item-row {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			width: 100%;
			@include phone {
				align-items: flex-start;
				padding-bottom: 20px; }
			&:first-child {
				margin-top: 10px; }

			.logo {
				margin-bottom: 10px;
				span {
					display: none;
					@include phone {
						display: block;
						text-align: center; } }

				img {
					max-width: 100%; } }

			.left {
				width: 110px;
				text-align: center;
				flex-shrink: 0;
				margin-right: calc(#{$percent}  - 110px);
				@include max(#{$firstbreckpoint}) {
					margin-right: calc(#{$percent1360} - 110px); }

				@include max($secondbreckpoint) {
					margin-right: calc(#{$percentsecond}  - 110px); }
				@include small {
					margin-right: calc(#{$percentsmall} - 67px);
					width: 67px; }
				@include phone {
					width: #{$percentphoneVector}vw;
					margin-left: 0px;
					margin-right:  #{$percentphoneVector}vw;
					margin-bottom: 0;
					text-align: left; } } }

		.contacts {
			display: flex;
			justify-content: flex-start;
			align-content: flex-start;
			@include phone {
				display: block;
				.first {
					margin-top: 20px; } }
			.contacts__item-col {
				display: block;
				&.first {
					>div:first-child {
						min-width: calc(#{$percent} * 2); } }
				&.last {
					>div {
						min-width: $percent; } }

				@include max(#{$firstbreckpoint});

				@include max($secondbreckpoint) {
					&.first {
						>div:first-child {
							min-width: calc(#{$percentsecond} * 2); } }
					&.last {
						>div {
							min-width: $percentsecond; } } }
				@include phone {
					width: unset;
					&.first {
						>div:first-child {
							margin-bottom: 20px; } } } } }


		.footer__item-col.social {
			text-align: left;
			@include phone {
				position: absolute;
				bottom: 0; } }

		.social__item {
			display: inline-flex;
			height: 16px;
			width: 16px;
			margin-right: 35px;
			@include medium {
				margin-right: 30px; }
			@include small {
				width: 11px;
				height: 11px;
				margin-right: 25px; }

			&:hover {
				transition: all 0.2s;
				opacity: 0.4; }
			&:last-child {
				margin-right: 0; }
			svg {
				fill: rgba(#d4eaf7, 0.4); } } } }







@include footer_1;
