@mixin header_1 {
	$global__bg: none;
	$global__box-shadow: none;
	$global__margin-top: none;						//	если не используется
	$global__margin-bottom: none;					//	установить значение 'none'

	$container__bg: none;
	$container__box-shadow: none;

	$content__height: 104px;							//	высота шапки
	$content__vertical-align: middle;				//	вертикальное выравнивание шапки(top, middle)
	$content__padding-top: 25px;						//	верхнее внутреннее поле контента
	$content__padding-right: 0px;					//	правое
	$content__padding-bottom: 25px;					//	нижнее
	$content__padding-left: 0px;			 			//	левое

	.header {
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		z-index: 10;
		.container {
			padding-left: 3px;
			padding-right: 0; }

		&__content {
			display: flex;
			justify-content: space-between;

			flex-wrap: wrap;
			@include phone {
				justify-content: center;
				flex-direction: column; } }


		.header__item-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			&:first-child {
				margin-top: 10px; }

			.logo {
				margin-bottom: 10px;

				img {
					max-width: 100%; } }

			.left {
				width: 160px;
				text-align: center;
				-webkit-flex-shrink: 0;
				flex-shrink: 0;
				margin-right: calc(#{$percent} * 2 - 160px);
				@include max(#{$firstbreckpoint}) {
					margin-right: calc(#{$percent1360} * 2 - 160px); }

				@include max($secondbreckpoint) {
					margin-right: calc(#{$percentsecond} * 2 - 160px); }
				@include small {
					margin-right: calc(#{$percentsmall} * 2 - 120px);
					width: 120px; }
				@include phone {
					width: calc(#{$percentphone} - 5px);
					margin-left: 2px;
					margin-bottom: 0; }

				span {
					font-size: 16px;
					font-weight: 300;
					line-height: 30px;
					text-transform: uppercase;
					letter-spacing: 6px;
					opacity: 0.5;
					color: $color__4;
					display: inline-block;
					transform: translateX(3px);
					@include small {
						font-size: 14px;
						letter-spacing: 1px; }
					@include phone {
						font-size: 12px;
						transform: translateX(3px);
						letter-spacing: 1px; } } }

			.right {
				flex-grow: 1; } }


		.contacts {
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			@include phone {
				display: none; }
			.contacts__item {
				@include small {
					margin-bottom: 10px; } }
			.contacts__item-col {
				display: flex;
				&.first {
					>div:first-child {
						min-width: calc(#{$percent} * 2); } }
				&.last {
					>div {
						min-width: $percent; } }

				@include max(#{$firstbreckpoint}) {
					display: block; }
				@include max($secondbreckpoint) {
					display: flex;
					&.first {
						>div:first-child {
							min-width: calc(#{$percentsecond} * 2); } }
					&.last {
						>div {
							min-width: $percentsecond; } } }
				@include max(1200px) {
					display: block; }
				@include small {
					&.last {
						margin-right: #{$percentsmallVector}vw; } } }
			a {
				color: $color__4;
				text-decoration: none;
				opacity: 0.4;
				font-size: $font-size__5;
				font-weight: 300;
				line-height: 1.2;
				letter-spacing: 2.4px;
				@include max($secondbreckpoint) {
					opacity: 0.5;
					font-size: 14px;
					letter-spacing: 2.1px; } } }

		&__item {

			//	доступные для использования миксины можно посмотреть в файле '_mixins.sass', раздел 'Миксины для фапки и футера',
			//	там же есть описание к ним
			//	вставьте ваши блоки между этим комментарием

			//	и этим
			&:not(.logo) {
				flex-grow: 1;
				margin-left: 240px; }

			&.logo {
				img {
					display: block;
					margin-right: auto;
					max-width: 100%;
					@include phone {
						margin: 0px; } } } }

		@include medium {
			&__content {
				padding-right: 0;
				padding-left: 0; } }

		@include small {
			&__content {
				flex-wrap: wrap;
				align-items: center;
				align-content: center; }
			&__item {
				width: 50%;
				&:nth-child(1),
				&:nth-child(2) {
					margin-bottom: 20px; }
				&.separator {
					display: none; } } }
		@include phone {
			&__item {
				width: auto;
				text-align: center;
				&:not(:last-child) {
					margin-bottom: 20px; } } } }


	body:not(.home-page) .header {
		position: static;
		margin-bottom: 145px;
		@include small {
			margin-bottom: 60px; }
		@include phone {
			margin-bottom: 50px; } } }

@include header_1;
