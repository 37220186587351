@import '../general/variables';

.mceContentBody {
	background-color: #fff; }
.mce-border-dashed-bottom {
	border-bottom: 1px dashed; }


.mce-border-site {
	border: 2px solid $color__5;
	border-radius: 3px;
	padding: 10px;
	display: block;
	margin-top: 1em;
	margin-bottom: 1em; }

.mce-button-invert {
	display: inline;
	border: 0px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 5px 10px;
	background-color: #fd5e62;
	color: #fff; }

.mce-text-shadow-lite {
	text-shadow: 1px 1px #f3f3f3; }


.mce-text-shadow-dark {
	text-shadow: 1px 1px #3c3c3c; }


.mce-qoute {
	border-left: 1px solid $color__5;

	padding: 25px;
	font-style: italic;
	background: rgba(0, 0, 0, 0.08);
	display: block;
	text-align: left;
	margin-top: 1em;
	margin-bottom: 1em;
	position: relative;
	&:after {
		position: absolute;
		content: '';
		background: $color__5;
		border-radius: 50%;
		width: 1px;
		height: 100%;
		left: 0;
		top: 0px;
		box-shadow: 2px 8px 18px 6px rgba(0,0,0,.21), 0 0 1px 1px rgba(0,189,197,.35); }
	@include max($secondbreckpoint) {
		padding-left: 15px; }
	@include small {
		padding-left: 12px; } }

.mce-epick {
	display: block;
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
	&:before {
		content: '';
		width: 190px;
		position: absolute;
		left: 50%;
		margin-left: -95px;
		top: 0px;
		border: 1px solid $color__1; }
	&:after {
		content: '';
		width: 190px;
		position: absolute;
		left: 50%;
		margin-left: -95px;
		bottom: 0px;
		border: 1px solid $color__1; } }



.spoiler {
	* {
		transition: all 1s; }
	.text-wrapper {
		overflow: hidden; }
	.visible-text {
		padding-bottom: 10px;
		p {
			margin-bottom: 0; } }
	.hidden-text {
		opacity: 0;
		max-height: 0;
		p {
			margin: 0px; } }
	.toggler {
		margin-top: 0px;
		text-align: left;
		font-weight: 700;
		cursor: pointer;
		margin-top: 10px; }
	&.active {
		.hidden-text {
			max-height: 999px;
			opacity: 1; } } }

#tinymce {
	.hidden-text {
		opacity: .8;
		max-height: none; } }
