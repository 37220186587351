@mixin not-found {
	$background: $color__4;

	$margin-bottom-img: 20px; 				//	отступ от картинки до текста
	$margin-bottom-text: 20px;				//	отступ от текста до кнопки
	.page_error {
		background: #031c21;
		background-image: url("/app/images/pattern.jpg");
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		align-content: center;
		min-height: 100vh; }
	.not-found {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		width: #{$percentVector * 4}vw;
		@include max(#{$firstbreckpoint}) {
			width: #{$percent1360Vector * 4}vw; }
		@include max($secondbreckpoint) {
			width: #{$percentsecondVector * 4}vw; }
		@include small {
			width: #{$percentsmallVector * 4}vw; }
		@include phone {
			width: #{$percentphoneVector * 4}vw; }


		h1 {

			align-self: flex-start;
			@include offset-color(yellow);
			position: relative;
			margin-left: #{$percentVector / 2}vw;
			@include max(#{$firstbreckpoint / 2}) {
				margin-left: #{$percent1360Vector / 2}vw; }
			@include max($secondbreckpoint) {
				margin-left: #{$percentsecondVector / 2}vw; }
			@include small {
				margin-left: #{$percentsmallVector / 2}vw; }
			@include phone {
				margin-left: #{$percentphoneVector / 2}vw; } }
		img {
			width: 100%;
			position: absolute;
			top: 0;
			left: -#{$percentVector / 2}vw;
			width: #{$percentVector * 4}vw;
			transform: translateY(-50%) translateX(-5%);
			@include max(#{$firstbreckpoint}) {
				width: #{$percent1360Vector * 4}vw;
				left: -#{$percent1360Vector / 2}vw; }
			@include max($secondbreckpoint) {
				width: #{$percentsecondVector * 4}vw;
				left: -#{$percentsecondVector / 2}vw; }
			@include small {
				width: #{$percentsmallVector * 4}vw;
				left: -#{$percentsmallVector / 2}vw; }
			@include phone {
				width: #{$percentphoneVector * 4}vw;
				left: -#{$percentphoneVector / 2}vw; } }
		p {
			margin: 0;
			@include offset-color(yellow);
			margin-left: #{$percentVector / 2}vw;
			@include max(#{$firstbreckpoint / 2}) {
				margin-left: #{$percent1360Vector / 2}vw; }
			@include max($secondbreckpoint) {
				margin-left: #{$percentsecondVector / 2}vw; }
			@include small {
				margin-left: #{$percentsmallVector / 2}vw; }
			@include phone {
				margin-left: #{$percentphoneVector / 2}vw; } }
		.came-back {
			display: flex;

			align-items: center;
			@include max(#{$firstbreckpoint});

			@include max($secondbreckpoint);

			@include small;

			a {
				opacity: 0.8;
				color: #d4eaf7;
				font-size: 16px;
				font-weight: 300;
				line-height: 30px;
				text-transform: uppercase;
				letter-spacing: 4px;
				text-decoration: none;
				display: flex;
				align-items: center;
				margin-left: #{$percentVector}vw;
				flex-shrink: 0;
				@include max(#{$firstbreckpoint}) {
					margin-left: #{$percent1360Vector}vw; }
				@include max($secondbreckpoint) {
					margin-left: #{$percentsecondVector}vw; }
				@include small {
					margin-left: #{$percentsmallVector}vw; }
				@include phone {
					margin-left: #{$percentphoneVector}vw;
					span {
						width: 30px; } }

				&:hover {
					opacity: 0.4; }
				@include small {
					font-size: 15px;
					line-height: 1.3; }
				@include phone {
					font-size: 14px;
					line-height: 1.3; }
				span {
					display: flex;
					width: 100%;
					width: 49px;
					height: 9px;
					opacity: 0.4;
					margin-left: 10px;
					svg {
						fill: #d4eaf7; } } } }
		a {

			text-decoration: none; } } }

@include not-found;
