@mixin menu {

	$global__bg: transparent;
	$global__border-bottom: none;
	$global__border-radius: none;
	$global__box-shadow: none;

	$container__bg: $color__1;
	$container__border-bottom: none;
	$container__border-radius: none;
	$container__box-shadow: none;

	$list__padding-right-left: none;					//	левый и правый отступы блока '.menu__list'

	$item__align: justify;							//	выравнивание элементов (justify, left, center, right)
	$item__height: auto;								//	высота пункта меню 1-го уровня
	$item__min-padding: 0px;						//	минимальные правое и левое поля ссылки 1-го уровня
	$item__color: $color__4;							//	и его остальные настройки
	$item__color-hover: $color__4;
	$item__font-size: $font-size__5;
	$item__font-family: $font-family__1;
	$item__font-weight: 300;
	$item__font-weight-hover: 300;
	$item__text-decoration: none;
	$item__text-decoration-hover: none;
	$item__text-transform: uppercase;
	$item__bg: transparent;
	$item__bg-hover: transparent;
	$item__border: none;
	$item__border-hover: none;
	$item__box-shadow: none;
	$item__box-shadow-hover: none;
	$item__separator_is: false;						//	если нужен разделитель - установить true, если нет - false
	$item__separator_width: 1px;						//	ширина разделителя между пунктами меню 1-го уровня
	$item__separator_height: 30px;					//	его высота в px, если нужно на всю высоту - указать 100%
	$item__separator_color: $color__4;				//	цвет

	$space-parent-inner: none;						//	расстояние между пунктом меню и выпадающим меню, если не нужно - указать 'none'
	$list__width: 300px;								//	ширина выпадающего меню и его остальные настройки
	$list__border: none;
	$list__border-radius: none;						//	скругление всего выпадающего меню
	$list__box-shadow: none;

	$list-item__padding-top: 10px;					//	настройки для пункта выпадающего меню
	$list-item__padding-right: 20px;
	$list-item__padding-bottom: 10px;
	$list-item__padding-left: 10px;
	$list-item__color: $color__4;
	$list-item__color-hover: $color__4;
	$list-item__font-size: $font-size__4;
	$list-item__font-family: $font-family__1;
	$list-item__font-weight: 400;
	$list-item__font-weight-hover: 600;
	$list-item__text-decoration: none;
	$list-item__text-decoration-hover: none;
	$list-item__text-transform: none;
	$list-item__bg: $color__2;
	$list-item__bg-hover: $color__3;
	$list-item__separator_is: true;					//	если нужен разделитель - установить true, если нет - false
	$list-item__separator_width: 260px;				//	ширина разделителя(в px) между пунктами выпадающего меню, если нужно на всю ширину - указать 100%
	$list-item__separator_height: 2px;				//	его высота в px
	$list-item__separator_position: auto;			//	его позиция, если нужно по центру - указать auto, иначе - отступ от левого края в px
	$list-item__separator_color: $color__4;			//	цвет разделителя

	@media only screen and (min-width: 992px) {
		.menu {
			@if $global__bg != none {
				background: $global__bg; }
			@if $global__border-bottom != none {
				border-bottom: $global__border-bottom; }
			@if $global__border-radius != none {
				border-radius: $global__border-radius; }
			@if $global__box-shadow != none {
				box-shadow: $global__box-shadow; }

			.container {

				@if $container__bg != none {
					background: $container__bg; }
				@if $container__border-bottom != none {
					border-bottom: $container__border-bottom; }
				@if $container__border-radius != none {
					border-radius: $container__border-radius; }
				@if $container__box-shadow != none {
					box-shadow: $container__box-shadow; } }
			.social-menu {
				display: none; }
			&__list {
				display: flex;
				@if $item__align == left {
					justify-content: flex-start; }
				@else if $item__align == center {
					justify-content: center; }
				@else {
					justify-content: flex-end; }
				margin: 0;
				@if $list__padding-right-left != none {
					padding: 0 $list__padding-right-left; }
				@else {
					padding: 0; }
				list-style-type: none;
				//	элемент первого уровня
				p {
					margin: 0;
					display: inline-block; }
				>.menu__list_item {
					position: relative;
					z-index: 12;
					@if $item__align == justify {
						flex-grow: 1; }
					height: $item__height;
					text-align: left;
					min-width: $percent;
					span {
						will-change: transform;
						display: inline-block;
						transition: all 1200ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }

					&.first {
						a {
							padding-left: 0; } }
					@include max(#{$firstbreckpoint}) {
						min-width: 0; }
					@include max($secondbreckpoint) {
						min-width: $percentsecond; }


					&:hover {
						//	если есть расстояние между пунктом 1-го уровня и выпадающим меню
						//	создаём прозрачный псевдоэлемент, чтобы курсор не выходил за границу меню
						@if $space-parent-inner != none {
							&::after {
								position: absolute;
								top: $item__height;
								left: 0;
								display: block;
								width: $list__width;
								height: $space-parent-inner;
								content: ''; } }
						//	если элемент не является категорией
						//	a у ссылки нет атрибута 'hidechild'
						//	показываем первый вложенный список

						span {
							transform: rotateX(360deg); }
						a {
 }							// opacity: 0.2

						&.parent:not([data-type="msCategory"]) {
							>a:not([hidechild]) + .menu__list-inner {
								display: block; } } }

					//	стили для активного элемента, дублируют стили ссылки при наведении
					&.active {
						>a {
							@if $item__color-hover != $item__color {
								color: $item__color-hover; }
							@if $item__font-weight-hover != $item__font-weight {
								font-weight: $item__font-weight-hover; }
							@if $item__text-decoration-hover != $item__text-decoration {
								text-decoration: $item__text-decoration-hover; }
							@if $item__bg-hover != $item__bg {
								background-color: $item__bg-hover; }
							@if $item__border-hover != $item__border {
								border: $item__border-hover; }
							@if $item__box-shadow-hover != $item__box-shadow {
								box-shadow: $item__box-shadow-hover; }
							opacity: 0.2; } }
					//	ссылка первого уровня
					> a {
						height: $item__height;
						padding: 0 $item__min-padding;
						color: $item__color;
						font-size: $item__font-size;
						font-family: $item__font-family;
						@if $item__font-weight != 400 {
							font-weight: $item__font-weight; }
						@if $item__text-decoration != underline {
							text-decoration: $item__text-decoration; }
						@if $item__text-transform != none {
							text-transform: $item__text-transform; }
						line-height: $item__height;
						background: $item__bg;
						@if $item__border != none {
							border: $item__border; }
						@if $item__box-shadow != none {
							box-shadow: $item__box-shadow; }
						@include max($secondbreckpoint) {
							font-size: 14px;
							letter-spacing: 2.8px; }

						/* Style for "О нас" */


						&:hover {
							@if $item__color-hover != $item__color {
								color: $item__color-hover; }
							@if $item__font-weight-hover != $item__font-weight {
								font-weight: $item__font-weight-hover; }
							@if $item__text-decoration-hover != $item__text-decoration {
								text-decoration: $item__text-decoration-hover; }
							@if $item__bg-hover != $item__bg {
								background-color: $item__bg-hover; }
							@if $item__border-hover != $item__border {
								border: $item__border-hover; }
							@if $item__box-shadow-hover != $item__box-shadow {
								box-shadow: $item__box-shadow-hover; } } }
					//	любая ссылка
					a {
						display: block;
						opacity: 0.4;
						letter-spacing: 3.2px;



						&[noclick] {
							cursor: default; } }
					//	если у элемента первого уровня есть разделители
					//	непервому и непоследнему элементам добавляем псевдоэлементы
					@if $item__separator_is == true {
						&:not(:first-child) {
							> a {
								&::before {
									position: absolute;
									@if $item__separator_height == 100% {
										top: 0; }
									@else {
										top: ($item__height - $item__separator_height) / 2; }
									left: -1 * $item__separator_width / 2;
									width: $item__separator_width;
									height: $item__separator_height;
									background-color: $item__separator_color;
									content: ''; } } }
						&:not(:last-child) {
							> a {
								&::after {
									position: absolute;
									@if $item__separator_height == 100% {
										top: 0; }
									@else {
										top: ($item__height - $item__separator_height) / 2; }
									right: -1 * $item__separator_width / 2;
									width: $item__separator_width;
									height: $item__separator_height;
									background-color: $item__separator_color;
									content: ''; } } } } }
				//	выпадающие меню
				&-inner {
					position: absolute;
					@if $space-parent-inner != none {
						top: $item__height + $space-parent-inner; }
					@else {
						top: $item__height; }
					left: 0;
					z-index: 10;
					display: none;
					width: $list__width;
					margin: 0;
					padding-left: 0;
					list-style-type: none;
					@if $list__border != none {
						border: $list__border; }
					@if $list__border-radius != none {
						border-radius: $list__border-radius; }
					@if $list__box-shadow != none {
						box-shadow: $list__box-shadow; }
					//	пункты выпадающего меню
					.menu__list_item {
						//	если родительский
						&.parent {
							position: relative;
							//	скрываем дочерний список
							ul {
								position: absolute;
								top: 0;
								left: 0;
								z-index: 9;
								display: block;
								visibility: hidden;
								opacity: 0;
								transition: all .3s ease-in-out; }
							//	при наведении показываем
							&:hover {
								>ul {
									left: $list__width;
									visibility: visible;
									opacity: 1; } } }
						//	для активного элемента дублируем стили ссылки при наведении
						&.active {
							>a {
								@if $list-item__color-hover != $list-item__color {
									color: $list-item__color-hover; }
								@if $list-item__font-weight-hover != $list-item__font-weight {
									font-weight: $list-item__font-weight-hover; }
								@if $list-item__text-decoration-hover != $list-item__text-decoration {
									text-decoration: $list-item__text-decoration-hover; }
								@if $list-item__bg-hover != $list-item__bg {
									background: $list-item__bg-hover; } } }
						//	любая ссылка выпадающего меню
						a {
							position: relative;
							z-index: 11;
							text-align: left;
							width: 100%;
							padding: ($list-item__padding-top + $list-item__separator_height) $list-item__padding-right $list-item__padding-bottom $list-item__padding-left;
							color: $list-item__color;
							font-size: $list-item__font-size;
							font-family: $list-item__font-family;
							@if $list-item__font-weight != 400 {
								font-weight: $list-item__font-weight; }
							@if $list-item__text-decoration != underline {
								text-decoration: $list-item__text-decoration; }
							@if $list-item__text-transform != none {
								text-transform: $list-item__text-transform; }
							background: $list-item__bg;
							&:hover {
								@if $list-item__color-hover != $list-item__color {
									color: $list-item__color-hover; }
								@if $list-item__font-weight-hover != $list-item__font-weight {
									font-weight: $list-item__font-weight-hover; }
								@if $list-item__text-decoration-hover != $list-item__text-decoration {
									text-decoration: $list-item__text-decoration-hover; }
								@if $list-item__bg-hover != $list-item__bg {
									background: $list-item__bg-hover; } } }
						//	если задано скругление выпадающего меню
						//	скругляем первый и последний элемент
						@if $list__border-radius != none {
							&:first-child {
								a {
									border-top-right-radius: $list__border-radius;
									border-top-left-radius: $list__border-radius; } }
							&:last-child {
								a {
									border-bottom-right-radius: $list__border-radius;
									border-bottom-left-radius: $list__border-radius; } } }
						//	если задан разделитель выпадающего меню
						//	добавляем псевдоэлементы непервой и непоследней ссылке
						@if $list-item__separator_is == true {
							&:not(:first-child) {
								>a {
									&::before {
										position: absolute;
										top: 0;
										@if $list-item__separator_width == 100% {
											left: 0;
											right: 0; }
										@elseif $list-item__separator_position == auto {
											left: ($list__width - $list-item__separator_width) / 2;
											width: $list-item__separator_width; }
										@else {
											left: $list-item__separator_position;
											width: $list-item__separator_width; }
										height: $list-item__separator_height / 2;
										background: $list-item__separator_color;
										content: ''; } } }
							&:not(:last-child) {
								>a {
									&::after {
										position: absolute;
										bottom: 0;
										@if $list-item__separator_width == 100% {
											left: 0;
											right: 0; }
										@elseif $list-item__separator_position == auto {
											left: ($list__width - $list-item__separator_width) / 2;
											width: $list-item__separator_width; }
										@else {
											left: $list-item__separator_position;
											width: $list-item__separator_width; }
										height: $list-item__separator_height / 2;
										background: $list-item__separator_color;
										content: ''; } } } } } } } } } }
@include menu;
