@mixin overlay {
	$close_bg: rgba(#d4eaf7, 0.4);
	$close_bg-hover: rgba(#d4eaf7, 0.2);

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 19;
		display: none;
		padding-right: 0 !important;
		opacity: 0;
		overflow-y: auto;
		overflow-x: hidden;
		transition: all .4s !important;

		&.active {
			display: block; }
		&.open {
			.overlay__content {
				will-change: transform;
				transform: translateX(0);
				>* {
					opacity: 1; } } }

		&__bg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 20;
			background-color: rgba(#000, .6); }
		&__wrapper {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			width: 100%;
			min-height: 100%;
			@include small {
				align-items: center; } }

		&__close {
			position: absolute;
			top: 0;
			right: 0;
			width: 24px;
			height: 24px;
			div {
				position: absolute;
				top: 22px;
				left: -22px;
				width: 24px;
				height: 2px;
				background-color: $close_bg;
				&:first-child {
					transform: rotate(45deg); }
				&:last-child {
					transform: rotate(-45deg); } }
			&:hover {
				cursor: pointer;
				div {
					background-color: $close_bg-hover; } } }
		&__content {
			position: relative;
			z-index: 21;

			transition: transform .4s;
			width: 50vw;
			background: url("/app/images/pattern.jpg");
			height: 100vh;
			padding: 20vh 10vw 0 10vw;
			transform: translateX(-100vw);
			transition: all 1s;

			@include small {
				width: 100vw; }
			@include phone {
				padding-top: 10vh; }
			>* {
				max-width: 100%;
				opacity: 0;
				transition: all 0.4s;
				transition-delay: 1s; } }
		@include phone {
			&__content {
				max-width: 100%; } } } }

@include overlay;
