.confidence {
	.content {
		padding-top: 0 !important;
		@include phone {
			>.flex-row {
				flex-direction: column; }
			.image-wrapper {}

			.right {
				margin-left: #{$percentphoneVector}vw; } } }

	.right.flex-row {
		flex-wrap: wrap;
		margin-top: 130px;
		align-items: center; }

	.image-wrapper {
		text-align: center;
		margin-bottom: 90px;
		&:nth-child(n + 4) {
			margin-bottom: 0px !important; }
		@include phone {
			margin-bottom: 60px; }
		img {
			width: 70%; } } }
