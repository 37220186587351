@mixin slider {
	.slick-slider {
		position: relative;
		display: block;
		user-select: none;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent; }
	.slick-list,
	.slick-track {
		transform: translate3d(0, 0, 0);
		transition: all 0s; }
	.slick-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;
		&:focus {
			outline: none; }
		&.dragging {
			cursor: pointer;
			cursor: hand; } }
	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		// margin-left: auto
		// margin-right: auto
		&:before,
		&:after {
			content: '';
			display: table; }
		&:after {
			clear: both; }
		.slick-loading & {
			visibility: hidden; } }
	.slick-arrow {
		padding: 0;
		&:focus {
			outline: none; } }
	.slick__arrow.slick-hidden {
		display: none; }
	.slick-slide {
		float: left;
		min-height: 1px;
		[dir="rtl"] & {
			float: right; }
		&:focus {
			outline: none;
			cursor: move; }
		.slick-initialized & {
			display: block; }
		.slick-loading & {
			visibility: hidden; }
		.slick-vertical & {
			display: block;
			height: auto;
			border: 1px solid transparent; }
		>img {
			display: block; }
		&.dragging {
			>img {
        		pointer-events: none; } } }
	.slick-dots {
		button {
			&:focus {
				outline: none; } } } }
@include slider;
